import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import useUserDevice from "@/ui/hooks/useUserDevice";
import { Spinner } from "@/ui/components/Elements/Spinner";
import { XRLaunchButton } from "@/ui/components/Generics/XRLaunchButton";
import { QrCodeGenerator } from "@/ui/components/QrCodeGenerator";
import SplashBgMob from "@/projects/lincolncenter/assets/splash-bg-mob.webp";
import SplashBgDesktop from "@/projects/lincolncenter/assets/desktop-bg.webp";
import LCLogo from "@/projects/lincolncenter/assets/lc-logo.svg";
import useXRErrors from "@/ui/hooks/useXRErrors";
import emitter from "@/core/events/eventemitter";
import EJLogo from "@/projects/lincolncenter/assets/ej-logo-white.svg";
import LCLogoWhite from "@/projects/lincolncenter/assets/lc-logo-white.svg";
import useQueryParams from "@/ui/hooks/useQueryParams";
import { IGeolocation } from "@/ui/hooks/useGeolocation";

type SplashProps = {
  contentLoaded: boolean;
  geolocation: IGeolocation;
};

export function Splash({ contentLoaded, geolocation }: SplashProps) {
  const { device } = useUserDevice();
  const { xrPermissionStatus, xrSupportStatus, xrOtherStatus } = useXRErrors();
  const queries = useQueryParams();

  const [pageType, setPageType] = useState<
    "geogate" | "loading" | "default" | "initial"
  >(contentLoaded ? "default" : "initial");

  const [skipDesktop, setSkipDesktop] = useState<boolean>(false);
  const { geolocationSuccess } = geolocation;

  useEffect(() => {
    if (queries.app && queries.app === "sim3d") setSkipDesktop(true);

    if (
      queries.config &&
      (queries.config === "onsite-nogeogate" ||
        queries.config.startsWith("offsite") ||
        queries.app === "sim3d")
    ) {
      setPageType("loading");
    } else {
      if (!geolocationSuccess) setPageType("geogate");
    }
  }, [queries, geolocationSuccess]);

  useEffect(() => {
    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (contentLoaded && geolocationSuccess !== false) {
          setPageType("default");
        }
      }
    });
    return () => {
      window.removeEventListener("visibilitychange", () => {});
    };
  }, []);

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (xrOtherStatus === false) {
      setError("WebXR is not defined in this browser.");
    } else if (xrSupportStatus === false) {
      setError("WebXR is not supported on this device.");
    } else if (xrPermissionStatus === "denied") {
      setError("Allow camera access in the app settings.");
    }
  }, [xrOtherStatus, xrSupportStatus, xrPermissionStatus]);

  const uiType = useMemo(() => {
    if (pageType === "initial") {
      return "initial";
    } else if (pageType === "geogate") {
      return "geogate";
    } else if (pageType === "loading") {
      return "loading";
    } else return "default";
  }, [pageType]);

  useEffect(() => {
    if (pageType !== "loading") return;
    let loaded = false;
    let timedOut = false;
    const timer = window.setTimeout(() => {
      timedOut = true;
      if (loaded || contentLoaded) {
        setPageType("default");
      }
    }, 2000);

    const handleLoaded = () => {
      console.log("handleLoaded");
      loaded = true;
      if (timedOut) {
        setPageType("default");
      }
    };

    emitter.on("loaded", handleLoaded);
    return () => {
      emitter.off("loaded", handleLoaded);
      clearTimeout(timer);
    };
  }, [pageType]);

  const deviceType = useMemo(() => {
    if (device === undefined) {
      return "loading";
    } else if (
      device === "iOS" ||
      device === "Android" ||
      device === "AppClip"
    ) {
      return "mobile";
    } else return skipDesktop ? "mobile" : "desktop";
  }, [device, skipDesktop]);

  return (
    <div
      className={clsx(
        "bg-cover bg-bottom pt-[calc(var(--safe-area-top)+60px)] pb-[calc(var(--safe-area-top)+60px)] h-full w-full text-white pointer-events-auto items-center xpx-5 xpb-12 flex flex-col",
        {
          "gap-0": deviceType !== "desktop",
          "gap-12 overflow-scroll 2xl:pt-16 pt-12 pb-8 touch-manipulation":
            deviceType === "desktop",
        }
      )}
      style={{
        backgroundImage:
          deviceType === "desktop"
            ? `url(${SplashBgDesktop})`
            : `url(${SplashBgMob})`,
        backgroundColor: "#27213a",
      }}
    >
      <div
        className={clsx(
          "flex-grow items-center px-4 flex flex-col gap-6 text-center",
          {
            "basis-1/3 justify-start": deviceType === "desktop",
            "basis-2/3 justify-start ": deviceType === "mobile",
          }
        )}
      >
        <img
          src={LCLogo}
          alt="Lincoln Center Logo"
          className="max-w-[200px] w-full"
        />
        <h2
          className={clsx(
            "uppercase font-secondary-sans font-bold leading-[0.85] pb-3",
            {
              "text-[78px] sm:text-[90px] 3xl:text-[120px]":
                deviceType === "desktop",
              "text-[78px]": deviceType !== "desktop",
            }
          )}
        >
          Archive <br />
          of Dance
        </h2>
      </div>
      {deviceType === "desktop" && <DesktopSplash />}

      {deviceType === "loading" ||
        (uiType === "initial" && (
          <div className="flex flex-col justify-center items-center px-10 pb-10 basis-1/2 h-full">
            <Spinner size="lg" />
          </div>
        ))}

      {deviceType === "mobile" && (
        <>
          <div
            className={clsx(
              "flex flex-col items-center flex-grow basis-1/2 px-5 xpb-14 animate-fade-in justify-end"
            )}
          >
            {uiType === "default" && (
              <>
                {error ? (
                  <>
                    {device === "Android" && (
                      <ul className="px-10 font-secondary-sans flex flex-col gap-1 text-lg xs:text-xl list-decimal pb-10 pt-10">
                        <li className="">
                          Tap the <span className="font-bold">lock icon</span>{" "}
                          next to the website address
                        </li>
                        <li>
                          Select <span className="font-bold">Permissions</span>
                        </li>
                        <li>
                          Enable{" "}
                          <span className="font-bold">Augmented Reality</span>
                        </li>
                      </ul>
                    )}
                    <h2 className="text-xl xs:text-[22px] font-secondary-sans items-end text-center animate-fade-in pb-12 px-5 leading-[26px]">
                      {error}
                    </h2>
                  </>
                ) : (
                  <h2 className="text-xl xs:text-[22px] font-secondary-sans items-end text-center animate-fade-in pb-12 px-5 leading-[26px]">
                    This experience would like to use your camera for Augmented
                    Reality.
                  </h2>
                )}
              </>
            )}
            {uiType === "loading" && (
              <div className="animate-fade-in flex justify-center items-center flex-col text-center gap-8">
                <Spinner size="lg" />
                <p className="text-xl xs:text-[22px] font-secondary-sans">
                  Be aware of your <br />
                  surroundings during this experience.
                </p>
                <div className="flex items-center justify-center gap-x-[18px] pt-[80px]">
                  <a
                    href="https://eyejack.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={EJLogo}
                      alt="EJ Logo"
                      className="max-w-[98px] w-full"
                    />
                  </a>
                  <a
                    href="https://www.lincolncenter.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={LCLogoWhite}
                      alt="Lincoln Center Logo"
                      className="max-w-[98px] w-full"
                    />
                  </a>
                </div>
              </div>
            )}
            {uiType === "geogate" && geolocation && (
              <GeolocationCheck
                onGeolocationSuccess={() => setPageType("loading")}
                geolocation={geolocation}
              />
            )}

            {uiType === "default" && (
              <div className="">
                <XRLaunchButton />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

type GeolocationCheckProps = {
  onGeolocationSuccess: () => void;
  geolocation: IGeolocation;
};

function GeolocationCheck({
  onGeolocationSuccess,
  geolocation,
}: GeolocationCheckProps) {
  const {
    getPosition,
    isFetching,
    geolocationPermissionError,
    geolocationSuccess,
  } = geolocation;

  const [uiFetching, setUiFetching] = useState<boolean>(false);
  const [hasAttemptedGeolocation, setHasAttemptedGeolocation] =
    useState<boolean>(false);

  const handleGetPosition = () => {
    getPosition();
    setHasAttemptedGeolocation(true);
  };

  const canProceed = !geolocationPermissionError && geolocationSuccess;

  useEffect(() => {
    if (!hasAttemptedGeolocation) return;
    let loaded = false;
    let timedOut = false;

    if (isFetching) {
      setUiFetching(true);
    } else {
      loaded = true;
      if (timedOut) {
        setUiFetching(false);
        if (canProceed) onGeolocationSuccess();
      }
    }
    const timer = setTimeout(() => {
      timedOut = true;
      if (loaded) {
        setUiFetching(false);
        if (canProceed) onGeolocationSuccess();
      }
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [
    isFetching,
    hasAttemptedGeolocation,
    geolocationPermissionError,
    geolocationSuccess,
    canProceed,
    onGeolocationSuccess,
  ]);

  const { device } = useUserDevice();

  return (
    <>
      {!hasAttemptedGeolocation && (
        <>
          <h2 className="text-xl xs:text-[22px] font-secondary-sans items-end text-center animate-fade-in pb-12 px-5 leading-[26px]">
            Lincoln Center would like to use your location for this experience.
          </h2>
          <button
            className={clsx(
              "flex relative justify-center items-center font-sans text-xl sm:text-[18px] w-[266px] h-[68px] text-lc-purple bg-lc-neon-green ",
              "animate-fade-in",
              "disabled:opacity-50",
              "active:bg-opacity-75"
            )}
            onClick={handleGetPosition}
          >
            <span className="relative mb-2">ALLOW</span>
          </button>
        </>
      )}
      {uiFetching ? (
        <>
          <h2 className="text-xl xs:text-[22px] font-secondary-sans items-end text-center animate-fade-in pb-12 px-5 leading-[26px]">
            Checking if you are located
            <br /> at the Lincoln Center...{" "}
          </h2>
          <Spinner size="lg" />
        </>
      ) : (
        <>
          {geolocationPermissionError && (
            <>
              {device === "Android" && (
                <ul className="px-10 font-secondary-sans flex flex-col gap-1 text-lg xs:text-xl list-decimal pb-10 pt-10">
                  <li className="">
                    Tap the <span className="font-bold">lock icon</span> next to
                    the website address
                  </li>
                  <li>
                    Select <span className="font-bold">Permissions</span>
                  </li>
                  <li>
                    Enable <span className="font-bold">Location</span>
                  </li>
                </ul>
              )}
              <h2 className="text-xl xs:text-[22px] font-secondary-sans items-end text-center animate-fade-in pb-12 px-5 leading-[26px]">
                {geolocationPermissionError.message}
              </h2>
            </>
          )}
          {geolocationSuccess === false && (
            <>
              <h2 className="text-xl xs:text-[22px] font-secondary-sans items-end text-center animate-fade-in pb-12 px-5 leading-[26px]">
                This experience works best <br /> at the Lincoln Center.
                <br />
                Refresh to check your location.
              </h2>
              <button
                className={clsx(
                  "flex relative justify-center items-center font-sans text-xl w-[266px] h-[68px] text-lc-purple bg-lc-neon-green ",
                  "animate-fade-in",
                  "disabled:opacity-50",
                  "active:bg-opacity-75"
                )}
                onClick={handleGetPosition}
              >
                <span className="relative mb-2">REFRESH</span>
              </button>
            </>
          )}
        </>
      )}
    </>
  );
}

function DesktopSplash() {
  const url = window.location.href;

  return (
    <div className="basis-2/3 flex flex-col gap-8 xh-full w-full items-center justify-between">
      <div className="w-fit flex flex-col lg:gap-9 gap-6 items-center justify-between h-full">
        <div className="flex-grow flex flex-col gap-6 lg:gap-9">
          <QrCodeGenerator value={url} fgColor="#1C1130" size={160} />
          <h2 className="text-white text-center md:text-[22px] text-xl font-secondary-sans md:leading-[26px] leading-[22px] tracking-[-0.41px]">
            Scan the QR code to launch <br />
            Archive of Dance
          </h2>
        </div>

        <div className="flex items-center justify-center gap-x-[18px]">
          <a
            href="https://eyejack.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={EJLogo} alt="EJ Logo" className="max-w-[98px] w-full" />
          </a>
          <a
            href="https://www.lincolncenter.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LCLogoWhite}
              alt="Lincoln Center Logo"
              className="max-w-[98px] w-full"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
