import { ReactNode } from "preact/compat";

interface InfoBannerProps {
  children: ReactNode;
}

const InfoBanner = ({ children }: InfoBannerProps) => {
  return (
    <div className="relative min-h-[86px] w-full flex items-center justify-center">
      <div
        className="absolute inset-0 w-full h-full"
        style={{
          background:
            "linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 15%, rgba(0, 0, 0, 0.50) 85%, rgba(0, 0, 0, 0.00) 100%)",
        }}
      >
        <svg className="absolute top-1 w-full" xmlns="http://www.w3.org/2000/svg" width="400" height="1" viewBox="0 0 400 1" fill="none">
  <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M400 1H0V0H400V1Z" fill="url(#paint0_linear_914_24969)" fill-opacity="0.4"/>
  <defs>
    <linearGradient id="paint0_linear_914_24969" x1="0" y1="-0.5" x2="400" y2="-0.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="white" stop-opacity="0"/>
      <stop offset="0.15" stop-color="white"/>
      <stop offset="0.845" stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
  </defs>
</svg>
        <svg className="absolute bottom-1 w-full" xmlns="http://www.w3.org/2000/svg" width="400" height="1" viewBox="0 0 400 1" fill="none">
  <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M400 1H0V0H400V1Z" fill="url(#paint0_linear_914_24970)" fill-opacity="0.4"/>
  <defs>
    <linearGradient id="paint0_linear_914_24970" x1="0" y1="-0.5" x2="400" y2="-0.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="white" stop-opacity="0"/>
      <stop offset="0.15" stop-color="white"/>
      <stop offset="0.845" stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
  </defs>
</svg>
      </div>
      <div className="relative p-4 text-white">{children}</div>
    </div>
  );
};

export default InfoBanner;
