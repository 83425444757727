import { useEffect, useMemo, useState } from "preact/hooks";
import useXRCheck, { XRCheckStatus } from "@/ui/hooks/useXRCheck";
import { CopyToClipboardTrigger } from "@/ui/components/Generics/CopyToClipboardTrigger";
import { Spinner } from "@/ui/components/Elements/Spinner";
import useUserDevice from "@/ui/hooks/useUserDevice";
import LCLogo from "@/projects/lincolncenter/assets/lc-logo.svg";
import SplashBg from "@/projects/lincolncenter/assets/splash-bg-mob.webp";
export const XRCheckOverlay = () => {
  const [showLoader, setShowLoader] = useState(true);

  const targetUrl = window.location.href;
  
  const { isMobile, iOSVersion } = useUserDevice();
  const isLegacyIOS = iOSVersion && iOSVersion < 17;

  const xrStatus = useXRCheck({ targetUrl });

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  const encodedUrl = useMemo(() => {
    const encodedTarget = encodeURIComponent(targetUrl);
    const appClipUrl = encodeURIComponent(
      `https://play.eyejack.xyz/link/?url=${encodedTarget}`
    );
    return appClipUrl;
  }, [targetUrl]);

  const legacyEncodedUrl = useMemo(() => {
    const encodedTarget = encodeURIComponent(targetUrl);

    return encodedTarget;
  }, [targetUrl]);

  return (
    <>
      {xrStatus !== XRCheckStatus.OK && (
        <div
          style={{
            backgroundImage: `url(${SplashBg})`,
            backgroundColor: "#27213a",
          }}
          className="bg-cover bg-bottom h-full w-full flex items-center justify-center mx-auto text-white"
        >
          <div className="h-full flex flex-col py-[60px] justify-center items-center gap-y-8">
            <div className="basis-[60%] w-full px-4 flex flex-col xjustify-between items-center flex-grow h-full gap-y-6">
              <img
                src={LCLogo}
                alt="Lincoln Center Logo"
                className="max-w-[200px] w-full"
              />
              <h2 className="font-secondary-sans text-center uppercase font-bold text-[78px] leading-[0.85]">
                Archive <br />
                of Dance
              </h2>
              {/* {((isLegacyIOS &&
                (xrStatus === XRCheckStatus.IOS_NO_APP_CLIP ||
                  xrStatus === XRCheckStatus.IOS_NOT_SAFARI ||
                  xrStatus === XRCheckStatus.IOS_INCOGNITO)) ||
                xrStatus === XRCheckStatus.ANDROID_NOT_CHROME) &&
                !showLoader && (
                  <h3 className="text-[32px] text-center flex justify-end font-secondary-sans uppercase leading-[0.95] xflex-grow items-end">
                    Something
                    <br /> went wrong...
                  </h3>
                )} */}
            </div>
            <div className="basis-[40%] text-center flex flex-col justify-end w-full px-6 relative z-[1000] ">
              {showLoader ? (
                <>
                  <Spinner className="mx-auto mb-14" size="lg" />
                </>
              ) : (
                <>
                  {isLegacyIOS ? (
                    <>
                      {xrStatus === XRCheckStatus.IOS_NOT_SAFARI && (
                        <>
                          <p className="font-secondary-sans text-xl xs:text-[22px] leading-[26px] text-white mb-12">
                            Copy the link below
                            <br /> into your Safari mobile browser
                            <br /> and turn off private mode.
                          </p>
                          <div className="flex justify-center items-center">
                            <div className="border-2 min-w-[250px] border-white border-opacity-30 py-3 px-4 max-w-xs flex gap-x-2 relative cursor-pointer">
                              <span className="mb-1 flex-grow text-center max-w-[310px] whitespace-nowrap overflow-hidden text-ellipsis w-full">
                                <CopyToClipboardTrigger
                                  text={targetUrl}
                                  color="white"
                                >
                                  COPY LINK
                                </CopyToClipboardTrigger>
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {xrStatus === XRCheckStatus.IOS_NO_APP_CLIP && (
                        <p className="font-secondary-sans text-xl xs:text-[22px] leading-[26px] text-white mb-12">
                          Your device does not support App Clips.
                          <br />
                          Update to iOS 16 or later.
                        </p>
                      )}

                      {xrStatus === XRCheckStatus.IOS_INCOGNITO && (
                        <>
                          <p className="font-secondary-sans text-xl xs:text-[22px] leading-[26px] text-white mb-12">
                            Copy the link below
                            <br /> into your Safari mobile browser
                            <br /> and turn off private mode.
                          </p>
                          <div className="flex justify-center items-center">
                            <div className="border-2 min-w-[250px] border-white border-opacity-30 py-3 px-4 max-w-xs flex gap-x-2 relative cursor-pointer">
                              <span className="mb-1 flex-grow text-center max-w-[310px] whitespace-nowrap overflow-hidden text-ellipsis w-full">
                                <CopyToClipboardTrigger
                                  text={targetUrl}
                                  color="white"
                                >
                                  COPY LINK
                                </CopyToClipboardTrigger>
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {xrStatus === XRCheckStatus.SHOW_APP_CLIP && (
                        <>
                          <p className="font-secondary-sans text-xl xs:text-[22px]leading-[26px] text-white mb-12">
                            This experience uses App Clips <br />
                            on Apple devices.
                          </p>
                          <a
                            className="max-w-[266px] w-full text-[20px] h-[68px] pointer-events-auto px-5 py-4 pb-5 text-lc-purple bg-lc-neon-green active:bg-opacity-75"
                            href={`https://play.eyejack.xyz/link/?url=${legacyEncodedUrl}`}
                            target="_blank"
                          >
                            OPEN IN APP CLIP
                          </a>
                        </>
                      )}
                    </>
                  ) : (
                    (xrStatus === XRCheckStatus.SHOW_APP_CLIP ||
                      xrStatus === XRCheckStatus.IOS_NOT_SAFARI ||
                      xrStatus === XRCheckStatus.IOS_INCOGNITO) && (
                      <>
                        <p className="font-secondary-sans text-xl xs:text-[22px] leading-[26px] text-white mb-12">
                          This experience uses App Clips <br />
                          on Apple devices.
                        </p>
                        <a 
                        href={`https://appclip.apple.com/id?p=com.eyejackapp.EyeJack.Clip&link=${encodedUrl}`}
                          className="max-w-[266px] w-full text-[20px] h-[68px] pointer-events-auto px-5 flex items-center justify-center pb-1 text-lc-purple bg-lc-neon-green active:bg-opacity-75 focus:outline-none focus:border-none"
                        // onClick={() => window.open(`https://appclip.apple.com/id?p=com.eyejackapp.EyeJack.Clip&link=${encodedUrl}`) }
                        >
                          OPEN IN APP CLIP
                        </a>
                      </>
                    )
                  )}

                  {xrStatus === XRCheckStatus.ANDROID_NOT_CHROME && isMobile && (
                    <>
                      <p className="font-secondary-sans text-xl xs:text-[22px] leading-[26px] text-white mb-12">
                        To run this experience copy the link below and paste it
                        in your Chrome browser.
                      </p>
                      <div className="flex justify-center items-center">
                        <div className="border-2 min-w-[250px] border-white border-opacity-30 py-3 px-4 max-w-xs flex gap-x-2 relative cursor-pointer">
                          <span className="mb-1 flex-grow text-center max-w-[310px] whitespace-nowrap overflow-hidden text-ellipsis w-full">
                            <CopyToClipboardTrigger
                              text={targetUrl}
                              color="white"
                            >
                              COPY LINK
                            </CopyToClipboardTrigger>
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
