import { useOnClickOutside } from "@/ui/hooks/useOnClickOutside";
import { useRef, useState } from "preact/hooks";
import clsx from "clsx";
import { Button } from "@/ui/components/Elements";

const menuItems = [
  {
    id: "load-sim3d-onsite",
    label: "Load Sim3d - Onsite",
    onClick: () => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("app", "sim3d"); 
      url.searchParams.set("config", "onsite"); 
      window.location.href = url.toString();
    },
  },
  {
    id: "load-sim3d-offsite-sm",
    label: "Load Sim3d - Offsite Small",
    onClick: () => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("app", "sim3d"); 
      url.searchParams.set("config", "offsite-small"); 
      window.location.href = url.toString();
    },
  },
  {
    id: "load-sim3d-offsite-lg",
    label: "Load Sim3d - Offsite Large",
    onClick: () => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("app", "sim3d"); 
      url.searchParams.set("config", "offsite-large"); 
      window.location.href = url.toString();
    },
  },
  {
    id: "load-webxr-onsite",
    label: "Load webxr - Onsite",
    onClick: () => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("app", "webxr");
      url.searchParams.set("config", "onsite"); 
      window.location.href = url.toString();
    },
  },
  {
    id: "load-webxr-offsite-sm",
    label: "Load webxr - Offsite Small",
    onClick: () => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("app", "webxr"); 
      url.searchParams.set("config", "offsite-small"); 
      window.location.href = url.toString();
    },
  },
  {
    id: "load-webxr-offsite-lg",
    label: "Load webxr - Offsite Large",
    onClick: () => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("app", "webxr"); 
      url.searchParams.set("config", "offsite-large"); 
      window.location.href = url.toString();
    },
  },
  {
    id: "current",
    label: "Reload Current",
    onClick: () => {
      window.location.reload();
    },
  },
];

const RefreshDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className="relative z-10 inline-block text-left ">
      <Button
        onClick={toggleDropdown}
        className="w-full h-[35px] bg-black text-white text-xs !px-1 !pb-3"
      >
        Refresh &#x25BC;
      </Button>
      {isOpen && (
        <ul className="absolute left-0 w-full bg-white border border-gray-900 rounded-sm shadow-lg">
          {menuItems.map((item) => (
            <li
              key={item.id}
              className={clsx(
                "px-3 py-3 hover:bg-gray-200 cursor-pointer text-black text-[10px]",
                "flex items-center last:bg-gray-300"
              )}
              onClick={() => {
                item.onClick();
                setIsOpen(false);
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RefreshDropdown;
