import { IconButton } from "@/ui/components/Elements";
import { useArtwork } from "../../context/artwork-context";
import clsx from "clsx";
import { ReactNode, useState } from "preact/compat";
import { Artwork } from "../../data/artworks";
import { TermsAndConditions } from "../TermsAndConditions";

type ProgressOverlayProps = {
  onClose: () => void;
};

export const ProgressOverlay = ({ onClose }: ProgressOverlayProps) => {
  const { artworks, numArtworksFound } = useArtwork();
  const [selectedArtwork, setSelectedArtwork] = useState<Artwork | null>(null);
  const [infoIsOpen, setInfoIsOpen] = useState(false);

  const handleOnClose = () => {
    onClose();
  };

  const progress = (numArtworksFound / artworks.length) * 100;

  const handleClick = (artwork: Artwork) => {
    setSelectedArtwork(artwork);
    setInfoIsOpen(true);
  };

  const formatLink = (url: string) => {
    return url.replace(/^https?:\/\//, "");
  };

  const [termsIsOpen, setTermsIsOpen] = useState(false);

  return (
    <>
      <div className="h-full w-full bg-[#260752]">
        <div
          className={clsx("flex items-start justify-between pb-5 px-5 pt-[calc(var(--safe-area-top)+26px)]" )}
        >
          <div className="flex flex-col pt-[32px]">
            <p className="font-oswald text-[60px] uppercase leading-[57px]">
              Discover
            </p>
            <p className="font-oswald text-[45px] uppercase leading-[43px]">
              All 8 Dances
            </p>
          </div>
          <IconButton
            onClick={() => handleOnClose()}
            icon={
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="25"
                  cy="25"
                  r="25"
                  fill="black"
                  fill-opacity="0.2"
                />
                <circle
                  cx="25"
                  cy="25"
                  r="23.5"
                  stroke="#00FFFF"
                  stroke-width="3"
                />
                <path
                  d="M22.9287 25.0055L16.4062 18.4789L18.4886 16.4062L25 22.9218L31.5224 16.4062L33.5937 18.4789L27.0823 25.0055L33.5937 31.5211L31.5224 33.5937L25 27.0782L18.4886 33.5937L16.4173 31.5211L22.9287 25.0055Z"
                  fill="#00FFFF"
                />
              </svg>
            }
          />
        </div>
        <div className="px-5 pb-12">
          <div
            className={clsx(
              "relative w-full h-5 overflow-hidden pointer-events-auto",
              {
                "bg-[#9A8DAC]": progress === 0,
                "bg-white": progress > 0,
              }
            )}
          >
            <div
              className="absolute top-0 left-0 h-full bg-lc-neon-green transition-all duration-300"
              style={{ width: `${progress}%` }}
            ></div>
            <div className="absolute top-0 right-0 h-full flex items-center px-2 font-oswald text-xs text-lc-purple">
              {numArtworksFound}/{artworks.length}
            </div>
          </div>
        </div>
        <div className="w-full"></div>
        <div
          className="overflow-y-auto touch-manipulation w-full pointer-events-auto h-[calc(100%-(var(--safe-area-bottom)+344px))]"
        >
          {artworks.map((artwork: Artwork) => (
            <div
              key={artwork.id}
              className={clsx(
                "relative bg-[length:auto_100%] bg-bottom overflow-y-auto px-5 border-t border-[#C0C0C0] w-full font-bold text-xl flex justify-between gap-x-4 pointer-events-auto touch-manipulation items-center",
                {
                  "h-[180px] cursor-pointer": artwork.found,
                  "h-[120px]": !artwork.found,
                }
              )}
              style={{
                backgroundImage: artwork.found
                  ? `url(${artwork.thumbnailUnlocked})`
                  : `url(${artwork.thumbnailLocked})`,
              }}
              disabled={!artwork.found}
              onClick={() => artwork.found && handleClick(artwork)}
            >
              {artwork.found && (
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute top-6 left-5"
                >
                  <circle
                    cx="14"
                    cy="14"
                    r="13"
                    stroke="#00FFFF"
                    stroke-width="2"
                  />
                  <path
                    d="M8.5 14.5L11.8571 18L19.5 10.5"
                    stroke="#00FFFF"
                    stroke-width="2.2"
                  />
                </svg>
              )}
              <h2
                className={clsx(
                  "font-secondary-sans uppercase text-[36px] leading-8 -mt-[2px]",
                  {
                    "text-white": artwork.found,
                    "text-[#BDB7C7]": !artwork.found,
                  }
                )}
              >
                {artwork.title}
              </h2>
              {artwork.found ? (
                <svg
                  width="13"
                  height="19"
                  viewBox="0 0 13 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-[5px]"
                >
                  <path d="M1 2L10 9.5L1 17" stroke="white" stroke-width="3" />
                </svg>
              ) : (
                <svg
                  width="31"
                  height="21"
                  viewBox="0 0 31 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-[6px]"
                >
                  <path
                    d="M23.9982 0.78603L5.75511 19.1121L7.04531 20.3964L25.2884 2.07039L23.9982 0.78603Z"
                    fill="#BDB7C7"
                  />
                  <path
                    d="M10.7883 16.6357C10.7476 16.623 10.7075 16.6108 10.6663 16.5981C7.16486 15.487 4.31093 13.4159 2.17367 10.4369C2.65185 9.76212 3.67315 8.4635 5.23999 7.18127C8.21589 4.74484 11.6578 3.50933 15.4706 3.50872C15.4912 3.50872 17.5854 3.4268 20.3337 4.29882C21.0176 4.51606 21.6766 4.76972 22.3102 5.05978L23.667 3.69684C22.7786 3.249 21.849 2.86973 20.8841 2.56388C18.3621 1.76347 16.3244 1.68701 15.6362 1.68701C15.5677 1.68701 15.513 1.68762 15.4724 1.68883C13.1246 1.68883 10.8647 2.12393 8.75537 2.9826C7.0702 3.66832 5.47969 4.62347 4.02815 5.82196C1.55531 7.86213 0.340433 9.89987 0.290066 9.98604L0 10.4788L0.31798 10.9539C1.64027 12.9304 3.26536 14.5955 5.14836 15.902C6.43909 16.7977 7.8524 17.5265 9.35674 18.0739L10.7883 16.6357Z"
                    fill="#BDB7C7"
                  />
                  <path
                    d="M30.6826 9.94282C29.3603 7.96637 27.7352 6.30122 25.8522 4.99471C25.6726 4.87031 25.4906 4.74895 25.3061 4.63062L23.9935 5.94926C25.8735 7.0895 27.4901 8.59687 28.8263 10.4592C28.3481 11.134 27.3268 12.4327 25.76 13.7149C22.7841 16.1513 19.3422 17.3868 15.5294 17.3874C15.5149 17.3874 14.4529 17.4287 12.8545 17.1398L11.3277 18.6739C13.2775 19.1557 14.7903 19.2097 15.3638 19.2097C15.4323 19.2097 15.487 19.2091 15.5276 19.2079C17.8754 19.2079 20.1353 18.7728 22.2446 17.9142C23.9298 17.2284 25.5203 16.2733 26.9719 15.0748C29.4447 13.034 30.6596 10.9957 30.7099 10.9101L31 10.4174L30.682 9.94221L30.6826 9.94282Z"
                    fill="#BDB7C7"
                  />
                  <path
                    d="M12.3957 15.0212L13.7126 13.6983C12.5729 13.0617 11.8004 11.8438 11.8004 10.4481C11.8004 8.39638 13.4698 6.72699 15.5215 6.72699C16.9112 6.72699 18.1242 7.49281 18.7632 8.62395L20.0801 7.30105C19.0788 5.85558 17.4094 4.90649 15.5215 4.90649C12.4661 4.90649 9.97995 7.39268 9.97995 10.4481C9.97995 12.345 10.9381 14.0217 12.3957 15.0212Z"
                    fill="#BDB7C7"
                  />
                  <path
                    d="M19.2305 10.7351C19.0909 12.5525 17.6412 14.0077 15.8262 14.1552L14.1677 15.8215C14.601 15.9308 15.0549 15.989 15.5221 15.989C18.5775 15.989 21.0637 13.5028 21.0637 10.4474C21.0637 9.97169 21.0037 9.50928 20.8902 9.06812L19.2311 10.7345L19.2305 10.7351Z"
                    fill="#BDB7C7"
                  />
                </svg>
              )}
            </div>
          ))}
          <div className="fixed bottom-[var(--safe-area-bottom)] h-[50px] bg-lc-purple w-full flex items-start pt-2 gap-5 underline justify-end px-4">
            <button className="font-secondary-sans text-[10px] text=[#BDB7C7] active:opacity-75" onClick={() => setTermsIsOpen(true)}>
              Terms & Conditions
            </button>
            <a href="https://www.eyejack.io/privacy" target="_blank" className="font-secondary-sans text-[10px] text=[#BDB7C7] active:opacity-75">Privacy Policy</a>
          </div>
        </div>
      </div>
      <ArtworkInfo
        infoIsOpen={infoIsOpen}
        onClose={() => setInfoIsOpen(false)}
        bgImage={selectedArtwork?.thumbnail}
      >
        {selectedArtwork && (
          <>
            <div className="relative artwork-info-scrollbar overflow-y-auto h-[calc(100%-70px)] pointer-events-auto touch-manipulation">
              <h2 className="font-secondary-sans font-bold uppercase text-[40px] leading-9 pb-[88px] ">
                {selectedArtwork.title}
              </h2>
              <div className="flex flex-col gap-5 pb-20 px-0 ">
                <p className="font-inter-light leading-[22px]">
                  <span className="font-inter-bold">Location: </span>
                  {selectedArtwork.location}{" "}
                </p>
                <p className="font-inter-light leading-[22px]">
                  <span className="font-inter-bold">Sound: </span>
                  {selectedArtwork.sound}{" "}
                </p>
                <p className="font-inter-light leading-[22px]">
                  <span className="font-inter-bold">Artist Name: </span>
                  {selectedArtwork.artistName}{" "}
                </p>
                {selectedArtwork.artistNames && (
                  <p className="font-inter-light leading-[22px]">
                    <span className="font-inter-bold">Artist Names: </span>
                    {selectedArtwork.artistNames}{" "}
                  </p>
                )}
                <p className="font-inter-light leading-[22px]">
                  <span className="font-inter-bold">Dance Title: </span>
                  {selectedArtwork.danceTitle}{" "}
                </p>
                {selectedArtwork.choreo && (
                  <p className="font-inter-light leading-[22px]">
                    <span className="font-inter-bold">Choreography by: </span>
                    {selectedArtwork.choreo}{" "}
                  </p>
                )}
                {selectedArtwork.musicians && (
                  <p className="font-inter-light leading-[22px]">
                    <span className="font-inter-bold">Musicians: </span>
                    {selectedArtwork.musicians}{" "}
                  </p>
                )}
                {selectedArtwork.musicComposed && (
                  <p className="font-inter-light leading-[22px]">
                    <span className="font-inter-bold">
                      Music Composed & Performed by:{" "}
                    </span>
                    {selectedArtwork.musicComposed}{" "}
                  </p>
                )}
                {selectedArtwork.producer && (
                  <p className="font-inter-light leading-[22px]">
                    <span className="font-inter-bold">Produced by: </span>
                    {selectedArtwork.producer}{" "}
                  </p>
                )}
                {selectedArtwork.music && (
                  <p className="font-inter-light leading-[22px]">
                    <span className="font-inter-bold">Music by: </span>
                    {selectedArtwork.music}{" "}
                  </p>
                )}
                {selectedArtwork.date && (
                  <p className="font-inter-light leading-[22px]">
                    <span className="font-inter-bold">Date: </span>
                    {selectedArtwork.date}{" "}
                  </p>
                )}
                <p className="font-inter-light leading-[22px]">
                  <span className="font-inter-bold">Summary: </span>
                  {selectedArtwork.summary}{" "}
                </p>
                <a
                  href={selectedArtwork.link}
                  target="_blank"
                  className="text-lc-neon-green underline font-inter-light leading-[22px]"
                >
                  {formatLink(selectedArtwork.link)}{" "}
                </a>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-[#3e2f70] via-[#3e2f70]/80 to-transparent pointer-events-none"></div>
          </>
        )}
      </ArtworkInfo>
        {termsIsOpen &&
          <Policies onClose={() => setTermsIsOpen(false)} isOpen={termsIsOpen}>
            <div className="relative artwork-info-scrollbar overflow-y-auto h-[calc(100%-70px)] pointer-events-auto touch-manipulation">

            <div className="flex flex-col gap-5 pb-20 pr-5">
             <TermsAndConditions />
            </div>
            </div>
            <div className="absolute bottom-0 left-0 w-[calc(100%-12px)] h-32 bg-gradient-to-t from-[#260752] via-[#260752]/80 to-transparent pointer-events-none"></div>
          </Policies>
        }
    </>
  );
};

type ArtworkInfoProps = {
  infoIsOpen: boolean;
  onClose: () => void;
  bgImage: string | undefined;
  children: ReactNode;
};

const ArtworkInfo = ({
  infoIsOpen,
  onClose,
  bgImage,
  children,
}: ArtworkInfoProps) => {
  return (
    <div
      style={{ backgroundImage: `url(${bgImage})` }}
      className={clsx(
        `absolute bg-cover bg-bottom pt-[calc(var(--safe-area-top)+26px)] p-5 top-0 left-0 w-full h-full z-50 transform 
        ${
          infoIsOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-500 ease-in-out`
      )}
    >
      <button className="" onClick={onClose}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25"
            cy="25"
            r="25"
            transform="rotate(-180 25 25)"
            fill="black"
            fill-opacity="0.2"
          />
          <circle
            cx="25"
            cy="25"
            r="23.5"
            transform="rotate(-180 25 25)"
            stroke="#00FFFF"
            stroke-width="3"
          />
          <path d="M29 33L20 25.5L29 18" stroke="#00FFFF" stroke-width="3" />
        </svg>
      </button>

      <div className="pt-12 h-full">{children}</div>
    </div>
  );
};

type PoliciesProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};
const Policies = ({ isOpen, onClose, children }: PoliciesProps) => {
  return (
    <div
      className={clsx(
        `absolute bg-lc-purple p-5 pt-[calc(var(--safe-area-top)+26px)]  top-0 left-0 w-full h-full z-50 transform 
        ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-500 ease-in-out`,
      )}
    >
      <button className="" onClick={onClose}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25"
            cy="25"
            r="25"
            transform="rotate(-180 25 25)"
            fill="black"
            fill-opacity="0.2"
          />
          <circle
            cx="25"
            cy="25"
            r="23.5"
            transform="rotate(-180 25 25)"
            stroke="#00FFFF"
            stroke-width="3"
          />
          <path d="M29 33L20 25.5L29 18" stroke="#00FFFF" stroke-width="3" />
        </svg>
      </button>

      <div className="pt-12 h-full">{children}</div>
    </div>
  );
};
