import { useMemo } from 'preact/hooks';

function useQueryParams() {
    const search = window.location.search;
  return useMemo(() => {
    const params = new URLSearchParams(search);
    const queryObject: Record<string, string> = {};
    params.forEach((value, key) => {
      queryObject[key] = value;
    });
    return queryObject;
  }, [search]);
}

export default useQueryParams;