
import mitt, { Emitter } from 'mitt';

type LincolnCenterEvents = {
  'calibration-completed': void;
};

class LincolnCenterEventEmitter {
  private static instance: LincolnCenterEventEmitter;
  private emitter: Emitter<LincolnCenterEvents>;

  private constructor() {
    this.emitter = mitt<LincolnCenterEvents>();
  }

  public static getInstance(): LincolnCenterEventEmitter {
    if (!LincolnCenterEventEmitter.instance) {
      LincolnCenterEventEmitter.instance = new LincolnCenterEventEmitter();
    }
    return LincolnCenterEventEmitter.instance;
  }

  public on<Key extends keyof LincolnCenterEvents>(type: Key, handler: (event: LincolnCenterEvents[Key]) => void): void {
    this.emitter.on(type, handler);
  }

  public off<Key extends keyof LincolnCenterEvents>(type: Key, handler: (event: LincolnCenterEvents[Key]) => void): void {
    this.emitter.off(type, handler);
  }

  public emit<Key extends keyof LincolnCenterEvents>(type: Key, event?: LincolnCenterEvents[Key]): void {
    this.emitter.emit(type, event);
  }
}
export default LincolnCenterEventEmitter.getInstance();