/* eslint-disable @typescript-eslint/no-unused-vars */

import Utils from "../utils/utils";
import Bridge, { BridgeMessage } from "./bridge";
import { BridgeMessageTypeGeolocationStart, BridgeMessageTypeGeolocationStop } from "./bridge.types";

interface BridgeGeolocationResponse {
  subId: string,
  success: boolean,
  error?: string,
  errorCode?: number,
  latitude?: number;
  longitude?: number;
  altitude?: number;
  horizontalAccuracy?: number;
  verticalAccuracy?: number;
  course?: number;
  courseAccuracy?: number;
  speed?: number;
  speedAccuracy?: number;
  timestamp?: number;
}

interface BridgeGeolocationWatch {
  subId: string,
  watchId: number,
  successCallback: PositionCallback,
  errorCallback?: PositionErrorCallback | null,
}

export default class BridgeGeolocation extends Bridge {
  
  private watchList: BridgeGeolocationWatch[] = [];
  private watchOneShots: number[] = [];
  private watchIdCounter = -1;
  
  constructor() {
    super();
  }

  public async getCurrentPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<void> {
    this.watchPosition(
      successCallback,
      errorCallback,
      options
    ).then((watchId:number) => {
      this.watchOneShots.push( watchId );
    }).catch((error) => {
      throw error;
    });
  }

  public async watchPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<number> {
    const subId = Utils.UUID();
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeGeolocationStart,
        data: { 
          subId: subId,
          enableHighAccuracy: options?.enableHighAccuracy || false,
          maximumAge: options?.maximumAge || 0,
          timeout: options?.timeout || 0,
        },
      };
      this.send(message, resolve, reject);
    }).then(() => {
      const watchId = (this.watchIdCounter += 1);
      const watchObj = {
        watchId: watchId,
        subId: subId,
        successCallback: successCallback,
        errorCallback: errorCallback,
      };
      this.watchList.push( watchObj );
      this.subscribe(subId, this.subFunc.bind(this));
      return watchId;
    }).catch((error) => {
      throw error;
    });
  }

  public async clearWatch(watchId: number): Promise<void> {
    const watchObj = this.watchList.find( watchObj => watchObj.watchId === watchId );
    if( !watchObj ) {
      throw new Error( `bridge.geolocation.clearWatch, no matching watchId found ${watchId}` );
    }
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeGeolocationStop,
        data: { subId: watchObj.subId },
      };
      this.send(message, resolve, reject);
    }).then(() => {
      this.watchList = this.watchList.filter( watchObj => watchObj.watchId !== watchId );
      this.unsubscribe(watchObj.subId);
    }).catch((error) => {
      throw error;
    });
  }

  private subFunc(response: BridgeGeolocationResponse): void {
    console.log("BridgeGeolocation.subFunc", response);
    const watchObj = this.watchList.find( watchObj => watchObj.subId === response.subId );
    if( !watchObj ) {
      return;
    }
    const watchOneShotIndex = this.watchOneShots.findIndex( watchId => watchId === watchObj.watchId );
    if( watchOneShotIndex !== -1 ) {
      this.watchOneShots.splice( watchOneShotIndex, 1 );
      this.clearWatch( watchObj.watchId );
    }
    if( response.success ) {
      const coords: GeolocationCoordinates = {
        accuracy: response.horizontalAccuracy || 0,
        altitude: response.altitude || null,
        altitudeAccuracy: response.verticalAccuracy || null,
        heading: response.course || null,
        latitude: response.latitude!,
        longitude: response.longitude!,
        speed: response.speed || 0,
        toJSON: () => {
          // TODO: needs implementing.
        }
      }
      const position: GeolocationPosition = {
        coords: coords,
        timestamp: response.timestamp || Date.now(),
        toJSON: () => {
          // TODO: needs implementing.
        }
      };
      watchObj.successCallback( position );
    } else {
      if( watchObj.errorCallback ) {
        const error: GeolocationPositionError = {
          code: response.errorCode || 0,
          message: response.error || "",
          PERMISSION_DENIED: 1,
          POSITION_UNAVAILABLE: 2,
          TIMEOUT: 3,
        }
        watchObj.errorCallback( error );
      }
    }
  }
}