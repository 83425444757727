/* eslint-disable @typescript-eslint/no-unused-vars */

export default class GeolocationBase {
  constructor() {
    //
  }

  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Geolocation/watchPosition) */
  public async watchPosition(_successCallback: PositionCallback, _errorCallback?: PositionErrorCallback | null, _options?: PositionOptions): Promise<number> {
    return Promise.reject(new Error("geolocation.base.watchPosition must be overridden in the subclass"));
  }

  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Geolocation/clearWatch) */
  public async clearWatch(_watchId: number): Promise<void> {
    return Promise.reject(new Error("geolocation.base.clearWatch must be overridden in the subclass"));
  }

  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Geolocation/getCurrentPosition) */
  public async getCurrentPosition(_successCallback: PositionCallback, _errorCallback?: PositionErrorCallback | null, _options?: PositionOptions): Promise<void> {
    return Promise.reject(new Error("geolocation.base.getCurrentPosition must be overridden in the subclass"));
  }
}