import {
  AbstractMesh,
  Animation,
  Color3,
  Constants,
  EasingFunction,
  Mesh,
  MeshBuilder,
  Scene,
  SineEase,
  StandardMaterial,
  TransformNode,
  Vector3,
} from "babylonjs";
import { ContentSpot, ContentSpotConfig } from "../core/content.spot";
import { IAssetTexture } from "@/apps/app.content";

export default class ContentLincolnCenterSpot extends ContentSpot {
  protected scene?: Scene;
  protected rootNode?: TransformNode
  protected debugSphere?: Mesh;
  protected debugSphereMat?: StandardMaterial;
  protected debugCircle?: Mesh;
  protected debugCircleMat?: StandardMaterial;
  protected debugStandHere?: Mesh;
  protected debugStandHereMat?: StandardMaterial;
  protected debugLoader?: Mesh;
  protected debugLoaderMat?: StandardMaterial;
  protected debugPlayMat?: StandardMaterial;
  protected debugPlayButton?: Mesh;
  protected standHereOn: boolean = true;
  public standHereTooCloseToCamera: boolean = true;
  public standHereForceOn: boolean = true;
  protected animProxStandHere: boolean = false;
  protected animShowStandHereAlpha: number = 1;
  protected colorWhite = new Color3(1, 1, 1);
  protected colorGreen = new Color3(0, 1, 0);


  constructor(config: ContentSpotConfig) {
    super(config);
  }

  public init(scene: Scene, rootNode?: TransformNode) {
    this.scene = scene;
    this.rootNode = rootNode;
    // this.initDebugSphere();
    // this.initDebugSphereAnim();
    // this.initDebugCircle();
    // this.initDebugLine();
    // this.initDebugStandHere();
    // this.initDebugStandHereAnimBop();
    this.initDebugLoader();
    this.initDebugLoaderAnimSpin();

    this.initDebugPlayButton();
  }

  protected initDebugPlayButton() {
    // create same as loader
    const material = new StandardMaterial("DebugPlayButtonMat", this.scene);
    material.emissiveColor = Color3.Green();
    material.disableLighting = true;
    material.backFaceCulling = false;
    material.forceDepthWrite  = true;
    material.needAlphaBlending = () => true;

    const mesh = MeshBuilder.CreatePlane(
      "plane",
      { width: 10.0, height: 10.0 },
      this.scene
    );
    mesh.parent = this.rootNode ?? null;
    mesh.position.copyFrom(this.config.loaderPosition ?? this.config.target);
    mesh.scaling.copyFrom(this.config.loaderScale ?? new Vector3(1, 1, 1));
    mesh.rotation.copyFrom(this.config.loaderRotation?.add(new Vector3(0, -Math.PI/2, Math.PI/2)) ?? new Vector3(0, 0, 0));
    mesh.material = material;

    mesh.name = "play-" + this.config.name;
    this.debugPlayButton = mesh;
    this.debugPlayMat = material;
  }

  protected initDebugSphere() {
    const material = new StandardMaterial("whiteMat", this.scene);
    material.emissiveColor = Color3.White();
    material.disableLighting = true;
    const mesh = MeshBuilder.CreateSphere(
      "sphere",
      { diameter: 1 },
      this.scene
    );
    mesh.parent = this.rootNode ?? null;
    mesh.position.copyFrom(this.config.target);
    mesh.material = material;
    this.debugSphere = mesh;
    this.debugSphereMat = material;
  }

  protected initDebugSphereAnim() {
    if (!this.debugSphere) {
      return;
    }
    const animation = new Animation(
      "bopAnimation",
      "position.y",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );
    animation.setKeys([
      { frame: 0, value: this.debugSphere.position.y },
      { frame: 30, value: this.debugSphere.position.y + 1 },
      { frame: 60, value: this.debugSphere.position.y },
    ]);
    this.debugSphere.animations = [];
    this.debugSphere.animations.push(animation);
  }

  protected initDebugCircle() {
    const material = new StandardMaterial("whiteMat", this.scene);
    material.emissiveColor = Color3.White();
    material.disableLighting = true;
    const mesh = MeshBuilder.CreateDisc(
      "circle",
      { radius: this.config.radius },
      this.scene
    );
    mesh.parent = this.rootNode ?? null;
    mesh.position.copyFrom(this.config.position);
    mesh.position.y = 0;
    mesh.rotation.x = Math.PI / 2;
    mesh.material = material;
    this.debugCircle = mesh;
    this.debugCircleMat = material;
  }

  protected initDebugLine() {
    const material = new StandardMaterial("redMat", this.scene);
    material.emissiveColor = Color3.Red();
    material.disableLighting = true;
    const p1 = new Vector3().copyFrom(this.config.position);
    const p2 = new Vector3().copyFrom(this.config.target);
    p1.y = p2.y = 0;
    const midpoint = p1.add(p2).scale(0.5);
    const distance = Vector3.Distance(p1, p2);
    const mesh = MeshBuilder.CreatePlane(
      "plane",
      { width: distance, height: 0.2 },
      this.scene
    );
    mesh.parent = this.rootNode ?? null;
    mesh.position = midpoint;
    mesh.position.y = 0.1; // little bit off the ground.
    const direction = p2.subtract(p1).normalize();
    const angle = Math.atan2(direction.z, direction.x);
    mesh.rotation.y = -angle;
    mesh.rotation.x = Math.PI * 0.5;
    mesh.material = material;
  }

  protected initDebugStandHere() {
    const material = new StandardMaterial("blueMat", this.scene);
    material.emissiveColor = new Color3(1, 1, 1);
    material.disableLighting = true;
    material.backFaceCulling = false;
    material.forceDepthWrite  = true;
    material.needAlphaBlending = () => true;
    
    material.alphaMode = Constants.ALPHA_COMBINE;
    material.needAlphaBlending = () => true;
    const mesh = MeshBuilder.CreatePlane(
      "plane",
      { width: 1, height: 1 },
      this.scene
    );
    mesh.parent = this.rootNode ?? null;
    mesh.position.copyFrom(this.config.position);
    mesh.position.y = 2.0;
    mesh.billboardMode = AbstractMesh.BILLBOARDMODE_Y;
    mesh.material = material;
    this.debugStandHere = mesh;
    this.debugStandHereMat = material;
  }

  public setStandHereTexture(texture: IAssetTexture) {
    const tex = texture.texture;
    if( !tex) {
      return;
    }
    if( !this.debugStandHere) {
      return;
    }
    if( !this.debugStandHereMat) {
      return;
    }
    const aspectRatio = tex.getSize().width / tex.getSize().height;
    const width = 1.0;
    const height = width / aspectRatio;
    this.debugStandHere.scaling.x = -width;
    this.debugStandHere.scaling.y = -height;
    this.debugStandHereMat.diffuseTexture = tex;
    this.debugStandHereMat.diffuseTexture = tex;
    this.debugStandHereMat.emissiveTexture = tex;
    this.debugStandHereMat.diffuseTexture.hasAlpha = true;
    this.debugStandHereMat.useAlphaFromDiffuseTexture = true;
  }

  public setLoaderTexture(texture: IAssetTexture) {
    const tex = texture.texture;
    if( !tex) {
      return;
    }
    if( !this.debugLoaderMat) {
      return;
    }
    this.debugLoaderMat.diffuseTexture = tex;
    this.debugLoaderMat.diffuseTexture = tex;
    this.debugLoaderMat.emissiveTexture = tex;
    this.debugLoaderMat.diffuseTexture.hasAlpha = true;
    this.debugLoaderMat.useAlphaFromDiffuseTexture = true;
  }

  public setPlayButtonTexture(texture: IAssetTexture) {
    const tex = texture.texture;
    if( !tex) {
      return;
    }
    if( !this.debugPlayMat) {
      return;
    }
    this.debugPlayMat.diffuseTexture = tex;
    this.debugPlayMat.diffuseTexture = tex;
    this.debugPlayMat.emissiveTexture = tex;
    this.debugPlayMat.diffuseTexture.hasAlpha = true;
    this.debugPlayMat.useAlphaFromDiffuseTexture = true;
  }

  protected initDebugStandHereAnimBop() {
    if (!this.debugStandHere) {
      return;
    }
    const animBop = new Animation(
      "animBop",
      "position.y",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );
    const dist = 1.0;
    const distHalf = dist * 0.5;
    animBop.setKeys([
      { frame: 0, value: this.debugStandHere.position.y - distHalf },
      { frame: 30, value: this.debugStandHere.position.y + distHalf },
      { frame: 60, value: this.debugStandHere.position.y - distHalf },
    ]);
    const animBopEase = new SineEase();
    animBopEase.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    animBop.setEasingFunction(animBopEase);
    const frameStart = 0;
    const frameEnd = 60;
    this.debugStandHere.animations = [animBop];
    const animatable = this.scene!.beginAnimation(
      this.debugStandHere,
      frameStart,
      frameEnd,
      true
    );
    animatable.goToFrame(Math.floor(Math.random() * frameEnd)); // random start frame.
  }

  protected initDebugLoader() {
    const material = new StandardMaterial("DebugLoaderMat", this.scene);
    material.emissiveColor = Color3.Green();
    material.disableLighting = true;
    material.backFaceCulling = false;
    const mesh = MeshBuilder.CreatePlane(
      "loader-"+this.config.name,
      { width: 10.0, height: 10.0 },
      this.scene
    );
    mesh.isPickable = false;
    mesh.parent = this.rootNode ?? null;
    mesh.position.copyFrom(this.config.loaderPosition ?? this.config.target);
    mesh.scaling.copyFrom(this.config.loaderScale ?? new Vector3(1, 1, 1));
    mesh.rotation.copyFrom(this.config.loaderRotation?.add(new Vector3(0, -Math.PI/2, Math.PI/2)) ?? new Vector3(0, 0, 0));
    // mesh.position.y += 2.0;
    mesh.material = material;
    mesh.isVisible = false;
    this.debugLoader = mesh;
    this.debugLoaderMat = material;
  }

  protected initDebugLoaderAnimSpin() {
    if (!this.debugLoader) {
      return;
    }
    const animSpin = new Animation(
      "animSpin",
      "rotation.z",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );
    animSpin.setKeys([
      { frame: 0, value: this.debugLoader.rotation.z },
      { frame: 60, value: this.debugLoader.rotation.z + Math.PI * 2 },
    ]);
    const frameStart = 0;
    const frameEnd = 60;
    this.debugLoader.animations = [animSpin];
    this.scene!.beginAnimation(this.debugLoader, frameStart, frameEnd, true);
  }

  public hideAll() {
    if (this.debugStandHere)
      this.debugStandHere!.isVisible = false;
    
    this.debugLoader!.isVisible = false;
    this.debugPlayButton!.isVisible = false;
  }

  public showAll() {
    if (this.debugStandHere)
      this.debugStandHere!.isVisible = true;
    // this.debugLoader!.isVisible = true;
    this.debugPlayButton!.isVisible = true;
  }

  public override contentOn() {
    super.contentOn();
    this.animateOn();
    this.debugColor(this.colorGreen);
    this.standHereOn = false;

    if (this.debugPlayButton) {
      this.debugPlayButton.isVisible = false;
    }
  }

  public contentOff() {
    super.contentOff();
    this.animateOff();
    this.debugColor(this.colorWhite);
    this.standHereOn = true;

    // show play button
    if (this.debugPlayButton) {
      this.debugPlayButton.isVisible = true;
    }

  }

  public set showLoader(value: boolean) {
    if (this.debugLoader) {
      this.debugLoader.isVisible = value;
    }

    if (this.debugPlayButton) {
      this.debugPlayButton.isVisible = !value;
    }
  }

  protected animateOn() {
    if (this.debugSphere) {
      this.scene!.beginAnimation(this.debugSphere, 0, 60, true);
    }
  }

  protected animateOff() {
    if (this.debugSphere) {
      this.scene!.stopAnimation(this.debugSphere);
    }
  }

  protected debugColor(color: Color3) {
    if (this.debugSphereMat) {
      this.debugSphereMat.emissiveColor = color;
    }
    if (this.debugCircleMat) {
      this.debugCircleMat.emissiveColor = color;
    }
  }

  public override render() {
    const standHereShow = (this.standHereOn || this.standHereForceOn) && !this.standHereTooCloseToCamera;
    if( standHereShow ) {
      this.animShowStandHereAlpha += (1.0 - this.animShowStandHereAlpha) * 0.2;
    } else {
      this.animShowStandHereAlpha += (0.0 - this.animShowStandHereAlpha) * 0.2;
    }
    if (this.debugStandHereMat) {
      this.debugStandHereMat.alpha = this.animShowStandHereAlpha;
    }
  }
}