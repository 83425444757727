import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "preact/hooks";
import { createContext, ReactNode } from "preact/compat";
import { Artwork, artworks as initialArtworks } from "../data/artworks";
import { useContext } from "preact/hooks";
import useLocalStorage from "@/ui/hooks/useLocalStorage";

interface ArtworkContextProps {
  artworks: Artwork[];
  artworksFound: string[] | null;
  currentArtwork: Artwork | null;
  setCurrentArtwork: (artwork: string) => void;
  clearCurrentArtwork: () => void;
  numArtworksFound: number;
  clearArtworks: () => Promise<void>;
}

type ArtworkActions =
  | { type: "ARTWORK_FOUND"; payload: string }
  | { type: "SET_CURRENT_ARTWORK"; payload: string }
  | { type: "CLEAR_CURRENT_ARTWORK" }
  | { type: "INIT_ARTWORKS"; payload: Artwork[] };

interface ArtworkState {
  artworks: Artwork[];
  current: string | null;
}

const initialArtworkState: ArtworkState = {
  artworks: [],
  current: null,
};

const artworkReducer = (
  state: ArtworkState,
  action: ArtworkActions
): ArtworkState => {
  switch (action.type) {
    case "INIT_ARTWORKS":
      return {
        ...state,
        artworks: action.payload,
      };
    case "ARTWORK_FOUND":
      return {
        ...state,
        artworks: state.artworks.map((artwork) =>
          artwork.id === action.payload ? { ...artwork, found: true } : artwork
        ),
      };
    case "SET_CURRENT_ARTWORK":
      return {
        ...state,
        current: action.payload,
        artworks: state.artworks.map((artwork) =>
          artwork.id === action.payload ? { ...artwork, found: true } : artwork
        ),
      };
    case "CLEAR_CURRENT_ARTWORK":
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};

export const useArtwork = () => {
  const context = useContext(ArtworkContext);
  if (!context) {
    throw new Error("useArwork must be used within an ArtworkProvider");
  }
  return context;
};

export const ArtworkContext = createContext<ArtworkContextProps | null>(null);

export type ArtworkProviderProps = {
  children: ReactNode;
};

export const ArtworkProvider = ({ children }: ArtworkProviderProps) => {
  const [artworkFoundIds, setArtworkFoundIds, clearArtworkFoundIds] = useLocalStorage<string[]>(
    "artworkFoundIds",
    []
  );

  const [artworksState, dispatch] = useReducer(
    artworkReducer,
    initialArtworkState
  );
  const currentArtwork = useRef<Artwork | null>(null);
  const [artworksFound, setArtworksFound] = useState(0);

  useEffect(() => {
    const initializeArtworks = async () => {
      const newArtworks = initialArtworks.map((artwork) =>
        artworkFoundIds && artworkFoundIds.includes(artwork.id)
          ? { ...artwork, found: true }
          : { ...artwork, found: false }
      );

      dispatch({ type: "INIT_ARTWORKS", payload: newArtworks });
      if (artworkFoundIds) setArtworksFound(artworkFoundIds.length);
    };

    initializeArtworks();
  }, [artworkFoundIds]);

  const setCurrentArtwork = useCallback(
    async (id: string) => {
      const newCurrentArtwork = artworksState.artworks.find(
        (artwork: Artwork) => artwork.id === id
      );
      if (newCurrentArtwork) {
        currentArtwork.current = newCurrentArtwork;
        if (artworkFoundIds && !artworkFoundIds.includes(id)) {
          artworkFoundIds.push(id);
          setArtworkFoundIds(artworkFoundIds);
          setArtworksFound(artworkFoundIds.length);
        }
        dispatch({ type: "SET_CURRENT_ARTWORK", payload: id });
      } else {
        if (id === "") {
          currentArtwork.current = null;
          dispatch({ type: "SET_CURRENT_ARTWORK", payload: id });
        } else {
          console.warn(`Artwork with id ${id} not found.`);
        }
      }
    },
    [artworksState.artworks, artworkFoundIds, setArtworkFoundIds]
  );

  const clearCurrentArtwork = useCallback(() => {
    dispatch({ type: "CLEAR_CURRENT_ARTWORK" });
  }, []);

  const clearArtworks = useCallback(async () => {
    try {
      await clearArtworkFoundIds();
      setArtworkFoundIds([]); 
      setArtworksFound(0); 
      const resetArtworks = artworksState.artworks.map((artwork) => ({
        ...artwork,
        found: false,
      }));
      dispatch({ type: "INIT_ARTWORKS", payload: resetArtworks }); 
      currentArtwork.current = null; 
    } catch (error) {
      console.error("Error clearing artworks:", error);
    }
  }, [artworksState.artworks, clearArtworkFoundIds, setArtworkFoundIds]);

  const contextValue = useMemo(
    () => ({
      artworks: artworksState.artworks,
      artworksFound: artworkFoundIds,
      currentArtwork: currentArtwork.current,
      setCurrentArtwork,
      clearCurrentArtwork,
      clearArtworks,
      numArtworksFound: artworksFound,
    }),
    [
      artworksState.artworks,
      artworksFound,
      setCurrentArtwork,
      clearCurrentArtwork,
      clearArtworks,
      artworkFoundIds
    ]
  );

  return (
    <ArtworkContext.Provider value={contextValue}>
      {children}
    </ArtworkContext.Provider>
  );
};
