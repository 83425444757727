import { useState, useCallback } from "preact/hooks";
import Geolocation from "@/core/geolocation/geolocation";

export interface IGeolocation {
  geolocationSuccess: boolean | null,
  geolocationPermissionError: GeolocationPositionError | null,
  isFetching: boolean,
  getPosition: () => void,
}

const geolocation = new Geolocation();

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const toRadians = (degrees) => (degrees * Math.PI) / 180;
  const R = 6371; // Earth's radius in kilometers

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

export const useGeolocation = ([targetLat, targetLon]: string[], radiusInKm = 5) => {
  const [isWithinRadius, setIsWithinRadius] = useState<boolean | null>(null);
  const [error, setError] = useState<GeolocationPositionError | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getPosition = useCallback(async () => {
    setIsFetching(true);
    setError(null);
    try {
      await geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          const distance = calculateDistance(latitude, longitude, targetLat, targetLon);
          setIsWithinRadius(distance <= radiusInKm);
          setIsFetching(false);
        },
        (err) => {
          setError(err);
          setIsFetching(false);
        }
      );
    } catch (err) {
      setError(err as GeolocationPositionError);
      setIsFetching(false);
    }
  }, [targetLat, targetLon, radiusInKm]);

  return {
    geolocationSuccess: isWithinRadius,
    geolocationPermissionError: error,
    isFetching,
    getPosition,
  };
};
