/* eslint-disable @typescript-eslint/no-unused-vars */

import GeolocationBase from "./geolocation.base";
import BridgeGeolocation from "../bridge/bridge.geolocation";

export default class GeolocationApp extends GeolocationBase {
  
  private bridge: BridgeGeolocation;

  constructor() {
    super();
    this.bridge = new BridgeGeolocation();
  }

  public override async getCurrentPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<void> {
    return this.bridge.getCurrentPosition( successCallback, errorCallback, options );
  }

  public override async watchPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<number> {
    return this.bridge.watchPosition( successCallback, errorCallback, options );
  }

  public override async clearWatch(watchId: number): Promise<void> {
    return this.bridge.clearWatch( watchId );
  }
}