import { useEffect, useState } from 'react';
import emitter from '@/core/events/eventemitter';
import { AppPermissionStatus } from '@/core/permissions/permissions.status';

function useXRErrors() {
  const [xrPermissionStatus, setXRPermissionStatus] = useState<AppPermissionStatus>(AppPermissionStatus.PROMPT);
  const [xrSupportStatus, setXRSupportStatus] = useState<boolean | null>(null);
  const [xrOtherStatus, setXROtherStatus] = useState<boolean | null>(null);

  useEffect(() => {
    const handlePermissionDenied = () => {
      setXRPermissionStatus(AppPermissionStatus.DENIED);
    };

    const handleXRUndefined = () => {
        setXROtherStatus(false);
    };

    const handleXRNotSupported = () => {
      setXRSupportStatus(false);
    };

    const handleXRStarted = () => {
      setXRPermissionStatus(AppPermissionStatus.GRANTED);
      setXRSupportStatus(true);
      setXROtherStatus(true);
    };

    emitter.on('xr-error-permission-denied', handlePermissionDenied);
    emitter.on('xr-error-undefined', handleXRUndefined);
    emitter.on('xr-error-not-supported', handleXRNotSupported);
    emitter.on('xr-started', handleXRStarted);

    return () => {
      emitter.off('xr-error-permission-denied', handlePermissionDenied);
      emitter.off('xr-error-undefined', handleXRUndefined);
      emitter.off('xr-error-not-supported', handleXRNotSupported);
      emitter.off('xr-started', handleXRStarted);
    };
  }, []);

  return {
    xrPermissionStatus,
    xrSupportStatus,
    xrOtherStatus,
  };
}

export default useXRErrors;
