import ArrorwTexturePath from '@lincolncenter/ui/arrow.png';
import LookThisWayTexturePath from '@lincolncenter/ui/look_this_way.png';
import StandHereTexturePath from '@lincolncenter/ui/stand_here.png';
import LoaderTexturePath from '@lincolncenter/ui/spinner.png';
import PlayTexturePath from '@lincolncenter/ui/play.png';
//
import { Annabelle, Ballet, Breakers, CatDance, GovIsland, Idrani, Calibration, Occlusion, Plaza, RoofTop, Spots, TapHappenings, Wayfinding } from "./content.lincolncenter.types";
import { BoundingSphere, Camera, Color3, Engine, Frustum, Mesh, MeshBuilder, Node, PointerEventTypes, Scene, TransformNode, Vector2, Vector3 } from "babylonjs";
import { IAssetBase, IAssetModel, IAssetTexture } from "../../apps/app.content";
import { IContentLincolnCenterConfig } from './content.lincolncenter.config';
import { ContentSpotConfig } from "./../core/content.spot";
import ContentBase from "./../core/content.base";
import ContentLincolnCenterSpot from "./content.lincolncenter.spot";
import ContentLincolnCenterBase from "./content.lincolncenter.base";
import ContentLincolnCenterPlaza from "./content.lincolncenter.plaza";
import ContentLincolnCenterGovIsland from "./content.lincolncenter.govisland";
import ContentLincolnCenterRoofTop from "./content.lincolncenter.rooftop";
import ContentLincolnCenterAnnabelle from "./content.lincolncenter.annabelle";
import ContentLincolnCenterIdrani from "./content.lincolncenter.idrani";
import ContentLincolnCenterCatDance from './content.lincolncenter.catdance';
import ContentWayfindingPath from './content.lincolncenter.path';
import ContentLincolnCenterArrow from './content.lincolncenter.arrow';
import ContentLincolnCenterBreakers from './content.lincolncenter.breakers';
import ContentLincolnCenterSpotMesh from './content.lincolncenter.spotmesh';
import { ContentLincolnCenterTapHappenings } from './content.lincolncenter.taphappenings';
import { ContentLincolnCenterBallet } from './content.lincolncenter.ballet';
import { GridMaterial } from 'babylonjs-materials';
import { getCameraTransformationMatrix, screenToRay } from '../core/content.common';
import ContentLincolnCenterOcclusion from './content.lincolncenter.occlusion';
import ContentLincolnCenterCalibration from './content.lincolncenter.calibration';
import { ContentLincolnCenterState, ContentLincolnCenterStates } from './content.lincolncenter.states';
import { EJApp } from "@/core/utils/utils";
import { i } from 'vite/dist/node/types.d-aGj9QkWt';
import ContentLincolnCenterModelVideo from './content.lincolncenter.modelvideo';

type ContentLoadFunc = ( assets:IAssetBase[] ) => void;

interface IContentGrid {
  width?: number;
  height?: number;
}

//----------------------------------------------------------------
export default class ContentLincolnCenter extends ContentBase {
  
  protected currentState: ContentLincolnCenterState | undefined;
  protected config: IContentLincolnCenterConfig;
  //
  protected contentLoadFunc: ContentLoadFunc;
  protected contentUnloadFunc: ContentLoadFunc;
  //
  protected contentPlaza : ContentLincolnCenterPlaza;
  protected contentWayfindingPath = new ContentWayfindingPath();
  protected contentGovIsland = new ContentLincolnCenterGovIsland();
  protected contentRoofTop = new ContentLincolnCenterRoofTop();
  protected contentAnnabelle = new ContentLincolnCenterAnnabelle();
  protected contentIdrani = new ContentLincolnCenterIdrani();
  protected contentCatDance = new ContentLincolnCenterCatDance();
  protected contentBreakers = new ContentLincolnCenterBreakers();
  protected contentSpots = new ContentLincolnCenterSpotMesh();
  protected contentTapHappenings = new ContentLincolnCenterTapHappenings();
  protected contentBallet = new ContentLincolnCenterBallet();
  protected contentOcclusion = new ContentLincolnCenterOcclusion();
  protected contentCalibration = new ContentLincolnCenterCalibration();
  //
  protected contentActive?: ContentLincolnCenterBase;
  protected contents: { [key:string]: ContentLincolnCenterBase };
  //
  protected contentUI = new ContentLincolnCenterArrow();
  //
  protected spots: ContentLincolnCenterSpot[] = [];
  private oldActivatedSpot: string | undefined;
  private manuallyActivatedSpot: ContentLincolnCenterSpot | null = null;

  private lookThisWayTexture: IAssetTexture | undefined;
  private interactableContentIds: string[] = [
    Annabelle,
    Ballet,
    Breakers,
    CatDance,
    GovIsland,
    Idrani,
    RoofTop,
    TapHappenings,
  ];

  public isSim3D: boolean = false;

  constructor( config: IContentLincolnCenterConfig, contentLoadFunc: ContentLoadFunc, contentUnloadFunc: ContentLoadFunc ) {
    super();
    this.config = config;
    this.contentLoadFunc = contentLoadFunc;
    this.contentUnloadFunc = contentUnloadFunc;
    this.contentPlaza = new ContentLincolnCenterPlaza( config );
    this.contentOcclusion.config = config;

    this.contents = {
      [Annabelle]: this.contentAnnabelle,
      [GovIsland]: this.contentGovIsland,
      [Idrani]: this.contentIdrani,
      [Plaza]: this.contentPlaza,
      [RoofTop]: this.contentRoofTop,
      [CatDance]: this.contentCatDance,
      [Breakers]: this.contentBreakers,
      [Wayfinding]: this.contentWayfindingPath,
      [TapHappenings]: this.contentTapHappenings,
      [Ballet]: this.contentBallet,
      [Spots]: this.contentSpots,
      [Occlusion]: this.contentOcclusion,
      [Calibration]: this.contentCalibration,
    };

    document.addEventListener('keydown', this.keydown.bind(this) );

  }

  private onSelectedAsset (name: string) {
    // // check if any content is loading
    if (this.contentActive && this.contentActive.isStateLoading) {
      return;
    }

    // check if currently active content is the same as the one clicked
    if (this.contentActive && this.contentActive.id === name) {
      return;
    }

    if (this.isLoadingContent) return;

    this.contentOn(name);
    if (navigator.vibrate) {
      navigator.vibrate([200, 200]);
    }
  }

  public beginCalibrationVisuals(): void {
    if (!this.lookThisWayTexture) {
      console.error("Calibration visuals not loaded");
      return;
    }

    this.contentCalibration.setArrowUI(this.contentUI, this.lookThisWayTexture);
    this.contentCalibration.startCalibration();
  }

  public endCalibrationVisuals(): void {
    this.contentCalibration.stopCalibration();
  }

  public switchState (state: ContentLincolnCenterState) {
    try {
      if (this.currentState === state) {
        return;
      }

      if (this.currentState !== undefined)
        this.stopState(this.currentState);
      
      this.startState(state);
      this.currentState = state;
    }
    catch (e) {
      console.error("State swap error", e);
    }
  }
  

  protected startState (newState: ContentLincolnCenterState) {
    console.log("Starting state", newState);
    console.trace();
    if (newState === ContentLincolnCenterStates.CALIBRATION) {
      this.hideAll();
      if (this.config.showCalibrationVisuals)
        this.beginCalibrationVisuals();
    }
    else if (newState === ContentLincolnCenterStates.NONE) {
      this.hideAll();
      this.contentUnload();

      // loop through all content and make sure its set off
      for (const spot of this.spots) {
        spot.contentOff();
      }

    }
    else if (newState === ContentLincolnCenterStates.CONTENT) {
      this.showAll();
    }
  }

  protected showAll() {
    this.contentPlaza.showAllPlaceholders();
      for (const spot of this.spots) {
        spot.showAll();
      }

      this.contentWayfindingPath.show();
  }

  protected hideAll() {
    this.contentPlaza.hideAllPlaceholders();
      for (const spot of this.spots) {
        spot.hideAll();
      }

      this.contentWayfindingPath.hide();

      this.endCalibrationVisuals();
  }

  // To toggle off the previous state
  protected stopState (oldState: ContentLincolnCenterState) {
    console.log("Stopping state", oldState);
    console.trace();

    if (oldState === ContentLincolnCenterStates.CALIBRATION) {
      if (this.config.showCalibrationVisuals)
        this.endCalibrationVisuals();
    }
    else if (oldState === ContentLincolnCenterStates.NONE) {
      //
    }
    else if (oldState === ContentLincolnCenterStates.CONTENT) {
      //
    }
  }

  public override get assets(): IAssetBase[] {
    return [
      ...(this.config.loadPlaza ? this.contentPlaza.assets : []),
      ...(this.config.loadWayfinding ? this.contentWayfindingPath.assets : []),
      ...(this.config.hotspots ? this.contentSpots.assets : []),
      // ...this.contentSpots.assets,
      ...this.contentOcclusion.assets,
      ...this.contentCalibration.assets,
      {
        id: "arrow-texture",
        type: "texture",
        path: ArrorwTexturePath
      },
      {
        id: "look-this-way-texture",
        type: "texture",
        path: LookThisWayTexturePath
      },
      {
        id: "loader",
        type: "texture",
        path: LoaderTexturePath
      },
      {
        id: "play-button",
        type: "texture",
        path: PlayTexturePath
      }
    ];
  }
 
  public override init( engine: Engine, scene: Scene, camera: Camera, rootNode?: TransformNode): void {
    super.init( engine, scene, camera, rootNode );
    Object.values(this.contents).forEach(content => {
      content.init( engine, scene, camera, rootNode );

      if (content instanceof ContentLincolnCenterModelVideo) {
        content.setOnVideoEndedCallback(() => {
          if (this.contentActive === content) {
            this.contentUnload();
            this.contentActive = undefined;
          }
        });
      }

    });
    this.initSpots();
    if( this.config.loadGrid ) {
      const gridConfig = { width: 200, height: 200 };
      this.initGrid( gridConfig );
    }
    if (this.config.wayfinding)
      this.contentWayfindingPath.setPath(this.config.wayfinding);

    this.contentUI.init( engine, scene, camera );

  }

  //--------------------------------------------------------------
  protected initGrid( gridConfig?: IContentGrid ): void {
    if( !gridConfig ) {
      return;
    }
    const gridW = gridConfig.width || 10;
    const gridH = gridConfig.height || 10;
    const ground = MeshBuilder.CreateGround("ground", { width: gridW, height: gridH }, this.scene);
    ground.isPickable = false;
    ground.parent = this.rootNode ?? null;
    const gridMaterial = new GridMaterial("grid", this.scene);
    gridMaterial.majorUnitFrequency = 10; // Set the spacing for major lines
    gridMaterial.minorUnitVisibility = 1; // Visibility of minor lines (0.0 to 1.0)
    gridMaterial.gridRatio = 1; // Size of grid cells
    gridMaterial.backFaceCulling = false; // Render grid on both sides
    gridMaterial.mainColor = new Color3(0, 0, 0); // Main color of the grid
    gridMaterial.lineColor = new Color3(0.8, 0.8, 0.8); // Line color
    gridMaterial.opacity = 0.8; // Grid opacity
    ground.material = gridMaterial;
  }

  //--------------------------------------------------------------
  protected initSpots() {
    for( const configItem of this.config.items ) {
      const spot = new ContentLincolnCenterSpot(new ContentSpotConfig({
        name: configItem.name,
        position: configItem.position,
        target: configItem.target,
        radius: configItem.radius,
        loaderPosition: configItem.loaderTransform?.position,
        loaderRotation: configItem.loaderTransform?.rotation,
        loaderScale: configItem.loaderTransform?.scale
      }));
      spot.init( this.scene!, this.rootNode );
      if (this.config.hotspots) {
        this.contentSpots.initSpot( spot.config );
      }
      this.spots.push( spot );
    }
  }

  protected getSpot( name: string ): ContentLincolnCenterSpot | undefined {
    return this.spots.find( spot => spot.config.name === name );
  }

  public start() : void {
    this.switchState(ContentLincolnCenterStates.NONE);

    // Run this only after the experience is launched, guaratees button press for vid loading
    if (this.config.hotspots) {
      this.contentSpots.runContent(); 
    }

    window.addEventListener('pointerdown', (event) => {
      // const pointerX = event.clientX * window.devicePixelRatio;
      // const pointerY = event.clientY * window.devicePixelRatio;

      const pointerX =
        this.scene!.pointerX * (EJApp ? window.devicePixelRatio : 1);
      const pointerY =
        this.scene!.pointerY * (EJApp ? window.devicePixelRatio : 1);

      // const pickResult = this.scene!.multiPick(-pointerX, -pointerY, undefined,  this.camera?.rigCameras[0]);
      console.log("pointer down", pointerX, pointerY);

      const invertY = EJApp ||  this.isSim3D;

      const pickRay = screenToRay(
        this.scene!.activeCamera!,
        new Vector2(pointerX, pointerY),
        invertY
      );
      const pickResult = this.scene!.multiPickWithRay(pickRay);
      const pickedAssetsByDistance = pickResult?.sort((a, b) => a.distance - b.distance);
      pickedAssetsByDistance?.forEach((r) => {
        const mesh = r.pickedMesh;
        console.log('picked mesh', mesh.name);
        // place sphere
        // if (r.pickedPoint)
        //   drawSphereAt(r.pickedPoint, new Color3(0.0, 1.0, 0.0), 0.2, this.scene!);

        if (mesh) {
          for (const placeholderName of this.interactableContentIds) {
            if (mesh.name.includes(placeholderName)) {
              this.onSelectedAsset(placeholderName);
              // console.log("FOUND INTERACTABLE CONTENT", placeholderName);
              return;
            }
          }

          // traverse up parent
          const checkParent = (mesh: Node | null): boolean => {
            if (mesh === null) return false;
            if (mesh.name === "root") return false;
            for (const placeholderName of this.interactableContentIds) {
              if (mesh.name.includes(placeholderName)) {
                this.onSelectedAsset(placeholderName);
                return true;
              }
            }
            if (mesh.parent) return checkParent(mesh.parent);
            return false;
          }

          checkParent(mesh);
        }
      });
    });
  }

  //--------------------------------------------------------------
  public override loadAssetComplete( asset: IAssetBase ): void {
    if( asset.id === "arrow-texture" ) {
      const assetTexture = asset as IAssetTexture;
      this.contentUI.setArrowTexture( assetTexture.texture! );
    }
    else if (asset.id === "look-this-way-texture") {
      const assetTexture = asset as IAssetTexture;
      this.contentUI.setArrowTextTexture( assetTexture.texture! );
      this.lookThisWayTexture = assetTexture;
    }
    else if (asset.id === 'stand-here-texture') {
      this.spots.forEach(spot => {
        spot.setStandHereTexture((asset as IAssetTexture));
      });
    }
    else if (asset.id === 'loader') {
      this.spots.forEach(spot => {
        spot.setLoaderTexture((asset as IAssetTexture));
      });
    }
    else if (asset.id === 'play-button') {
      this.spots.forEach(spot => {
        spot.setPlayButtonTexture((asset as IAssetTexture));
      });
    }
    Object.values(this.contents).forEach(content => {
      content.loadAssetComplete( asset );
    });
    const contentId = asset.id!.split('-model')[0];
    if( !contentId ) {
      return;
    }
    const spot = this.getSpot( contentId );
    if( !spot ) {
      return;
    }
    const configItem = this.config.items.find( item => item.name === contentId );
    if( configItem ) {
      this.initModel( asset, spot!.config!.target, configItem.rotation, configItem.scale );
    }
  }

  protected initModel( asset: IAssetBase, position:Vector3, rotation:Vector3, scale?: Vector3 ): void {
    const assetModel = asset as IAssetModel;
    const meshRoot = assetModel.meshRoot;
    if( meshRoot ) {
      meshRoot.position.copyFrom( position );
      meshRoot.rotation.copyFrom( rotation );
      if( scale ) {
        meshRoot.scaling.copyFrom( scale );
      }
    }
  }

  //--------------------------------------------------------------
  public override render(): void {
    const camera = this.scene!.activeCamera;

    // // log framerate
    // if (this.engine)
    // console.log(this.engine!.getFps().toFixed(2));

    if (this.config.hotspots) {
      for ( const spot of this.spots ) {
        let isInsideSpot = false;
        if( camera ) {
          const dx = camera.position.x - spot.config.position.x;
          const dz = camera.position.z - spot.config.position.z;
          const distanceToSpot = Math.sqrt(dx * dx + dz * dz);
          spot.standHereTooCloseToCamera = (distanceToSpot <= 3.0);
          isInsideSpot = (distanceToSpot <= spot.config.radius);
        }
        const manuallyActivated = this.manuallyActivatedSpot === spot;
        const on = isInsideSpot || manuallyActivated;
        const onChanged = on !== spot.on;
        if ( onChanged ) {
          if ( on ) {
            this.contentOn( spot.config.name );

            this.contentSpots.showHideSpot( spot.config.name, false);
            spot.standHereForceOn = false;

            if (this.oldActivatedSpot && this.oldActivatedSpot !== spot.config.name) {
              const oldSpot = this.getSpot(this.oldActivatedSpot);
              if (oldSpot) {
                this.contentSpots.showHideSpot(oldSpot.config.name, true);
                oldSpot.standHereForceOn = true;
              }
            }

            this.oldActivatedSpot =  spot.config.name;

        } else {
            this.contentOff( spot );
          }
        }
      }
    }

    if( this.contentActive ) {
      const spotActive = this.getSpot( this.contentActive.id );
      if( spotActive ) {
        if( this.contentActive.isStateReady ) {
          let inFrustum = false;
          if( camera ) {
            const transformedPosition = Vector3.TransformCoordinates( spotActive.config.target, this.rootNode!.getWorldMatrix());
            inFrustum = this.isSphereInFrustum(transformedPosition, spotActive.config.radius, camera );
          }
          if (inFrustum) {
            this.contentActive.setStateView();
            this.contentUI.arrowShowOverride = false;
            this.contentPlay( this.contentActive );
          }
        }

        const transformedPosition = Vector3.TransformCoordinates(spotActive.config.loaderPosition ?? spotActive.config.target, this.rootNode!.getWorldMatrix());
        this.contentUI.pointTo( transformedPosition );
      }
    }
    Object.values(this.contents).forEach(content => {
      content.render();
    });
    //
    const spotsInFrustum: ContentLincolnCenterSpot[] = [];
    for( const spot of this.spots ) {
      spot.render();
      //
      const position = spot.config.position;
      let inFrustum = false;
      if( camera ) {
        inFrustum = this.isSphereInFrustum( position, spot.config.radius, camera );
      }
      if( inFrustum ) {
        spotsInFrustum.push( spot );
      }
      //const distance = this.camera!.position.subtract(position).length();
    }
    //console.log( "spotsInFrustum: ", spotsInFrustum.length );
    this.contentUI.render();
  }

  public override resize(): void {
    super.resize();
    Object.values(this.contents).forEach(content => { 
      content.resize() 
    });
    this.contentUI.resize();
  }

  //--------------------------------------------------------------
  protected isSphereInFrustum( position:Vector3, radius:number, camera:Camera ):boolean {
    const sphere = BoundingSphere.CreateFromCenterAndRadius(position, radius);
    const frustumPlanes = Frustum.GetPlanes(getCameraTransformationMatrix(camera));
    const inFrustum = sphere.isInFrustum(frustumPlanes);
    return inFrustum;
  }

  //--------------------------------------------------------------
  protected contentOn(contentId: string): void {

    if (this.currentState !== ContentLincolnCenterStates.CONTENT || this.isLoadingContent) return;

    this.spots.forEach(spot => {
      if (spot.config.name === contentId) {
        spot.contentOn();
      }
    });

    const contentNew = this.contents[contentId];
    if( !contentNew ) {
      return;
    }
    if( this.contentActive === contentNew ) {
      return; // already active.
    }
    this.contentUnload();
    this.contentLoad( contentNew );
  }



  protected contentOff(spot: ContentLincolnCenterSpot): void {
    if (this.currentState !== ContentLincolnCenterStates.CONTENT) return;
    spot.contentOff();
  }

  //--------------------------------------------------------------
  protected contentUnload() {
    if( !this.contentActive ) {
      return; // nothing to unload.
    }
    this.contentPlazaAssetVisible( this.contentActive.id, true );
    this.contentUnloadFunc( this.contentActive.assets );
    this.contentActive.setStateUnload();
  }

  private isLoadingContent : boolean = false;

  protected contentLoad( content: ContentLincolnCenterBase ) {

    if (this.isLoadingContent) return;

    this.contentActive = content;
    this.contentActive!.setStateLoading();
    this.contentLoadFunc( this.contentActive!.assets );
    this.contentLoadingAnim( this.contentActive! );
    this.contentUI.arrowShowOverride = true;
  }

  protected async contentLoadingAnim(content: ContentLincolnCenterBase) {
    const spot = this.getSpot(content.id)!;
    spot.showLoader = true;
    this.isLoadingContent = true;
    await this.contentHasLoaded(content);
    this.isLoadingContent = false;
    spot.showLoader = false;

    // check if this is still the active content
    if (this.contentActive === content) {
      content.setStateReady();
    }
  }

  protected async contentHasLoaded(content: ContentLincolnCenterBase): Promise<void> {
    const minLoadingTime = 2000;
    return new Promise<void>((resolve, reject) => {
      const startTime = Date.now();
      const interval = 500;
      const timer = setInterval(() => {
        if (content.isStateLoaded) {
         
          const elapsedTime = Date.now() - startTime;
          if (elapsedTime >= minLoadingTime) {
            clearInterval(timer);
            resolve();
          }
        }
      }, interval);
    });
  }

  protected contentPlay(content: ContentLincolnCenterBase) {
    // TODO: transition between static placeholder and animated content.
    this.contentPlazaAssetVisible( content.id, false );
    content.setStatePlay();
  }

  protected contentPlazaAssetVisible( contentId:string, value:boolean ):void {
    this.contentPlaza.assetVisible(contentId, value );
  }

  public toggleContentVisibility( contentId:string, value:boolean ):void {
    const interactiveContentIds = [ Annabelle, Ballet, Breakers, CatDance, GovIsland, Idrani, RoofTop, TapHappenings ];
    const isInteractiveContent = interactiveContentIds.includes(contentId);
    if( isInteractiveContent ) {
      const spot = this.getSpot( contentId );
      if( spot ) {
        if (value) {
          this.manuallyActivatedSpot = spot;
          this.contentOn(spot.config.name);
        } else if (this.manuallyActivatedSpot === spot) {
          this.manuallyActivatedSpot = null;
          this.contentOff(spot);
        }
      }
      return;
    }
    const plazaMeshIds = ['building_mesh', 'immersal_sparse_114026', 'immersal_sparse_114028', 'immersal_sparse_114031'];
    const isPlazaMesh = plazaMeshIds.includes(contentId);
    if( isPlazaMesh ) {
      this.contentPlaza.assetVisible( contentId, value );
    }
  }

  //--------------------------------------------------------------
  private keydown(event: KeyboardEvent) {
    if (event.key === 'd' || event.key === 'D') {
      if( event.shiftKey ) {
        if( this.scene ) {
          const debugLayer = this.scene.debugLayer;
          if( debugLayer.isVisible() ) {
            debugLayer.hide();
          } else {
            this.scene?.debugLayer.show({
              overlay: false,
              embedMode: true,
            });
          }
        }
      }
    }
  }
}