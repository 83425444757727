import clsx from "clsx";
import { useApp } from "@/ui/hooks/useApp";
import { AppState } from "@/ui/types/AppState";
import { useArtwork } from "@/ui/hooks/useArtwork";
import { useState } from "preact/hooks";
import { ModalOverlay } from "@/ui/components/Elements/Modal/ModalOverlay";
import { Modal } from "@/ui/components/Elements";
import emitter from "@/core/events/eventemitter";

export type MediaPreviewProps = {
  videoResult: string;
};

export function MediaPreview({ videoResult }: MediaPreviewProps) {
  const { recordingState, setAppState } = useApp();
  const { currentArtwork } = useArtwork();
  // false for testing only, change when we have video url
  const [videoLoaded, setVideoLoaded] = useState(true);
  const onVideoDataLoaded = () => {
    setVideoLoaded(true);
  };

  const onVideoCleared = () => {
    if (currentArtwork) {
      setAppState(AppState.ARTWORK_VIEWING);
    } else {
      setAppState(AppState.CALIBRATED);
    }
  };

  const shareVideo = () => {
    const shareData: ShareData = {
      title: "Lincoln Center",
      text: "Lincoln Center",
      url: videoResult,
      files: undefined,
    };
    emitter.emit("share", shareData);
  };

  if (recordingState !== "NONE") return null;
  return (
    <ModalOverlay className="z-[200]">
      <Modal
        className={clsx(
          "flex flex-col max-h-full items-center justify-center p-4 h-full centered"
        )}
      >
        <div className="relative w-auto border-white z-[100] border-[3px] rounded-[22px] w-full max-w-[70vw]">
          <>
            <button
              className="absolute top-5 right-5 z-[100]"
              onClick={onVideoCleared}
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="25"
                  cy="25"
                  r="25"
                  fill="black"
                  fill-opacity="0.2"
                />
                <circle
                  cx="25"
                  cy="25"
                  r="23.5"
                  stroke="#00FFFF"
                  stroke-width="3"
                />
                <path
                  d="M22.9287 25.0055L16.4062 18.4789L18.4886 16.4062L25 22.9218L31.5224 16.4062L33.5937 18.4789L27.0823 25.0055L33.5937 31.5211L31.5224 33.5937L25 27.0782L18.4886 33.5937L16.4173 31.5211L22.9287 25.0055Z"
                  fill="#00FFFF"
                />
              </svg>
            </button>
            <video
              src={videoResult}
              className={clsx("object-cover rounded-[20px] min-h-[50vh]", {
                "animate-skeleton-load bg-gray-300 ": !videoLoaded,
              })}
              playsInline
              autoPlay
              muted
              loop
              onLoadedData={onVideoDataLoaded}
            />
          </>
        </div>
        <button
          className="mt-16 max-w-[266px] w-full h-[68px] font-sans font-light rounded-sm bg-lc-neon-green px-5 py-4 text-lc-purple active:opacity-70"
          onClick={shareVideo}
        >
          SHARE
        </button>
      </Modal>
    </ModalOverlay>
  );
}
