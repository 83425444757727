import { useCopyToClipboard } from "@/ui/hooks/useCopyToClipboard";
import clsx from "clsx";
import { HTMLAttributes } from "preact/compat";

type CopyToClipboardTriggerProps = HTMLAttributes<HTMLButtonElement> & {
  text: string;
  color?: string;
};

export const CopyToClipboardTrigger = ({
  text,
  color = 'black',
  children = "COPY LINK",
}: CopyToClipboardTriggerProps) => {
  const [copyToClipboard, copyResult] = useCopyToClipboard();

  const handleClickCopy = () => {
    copyToClipboard(text);
  };

  return (
    <>
      <div className=" relative z-10 cursor-pointer " onClick={handleClickCopy}>
        <button className="w-full flex justify-center items-center gap-x-2 disabled:opacity-50" disabled={copyResult !== null}>{children}
          <div className="relative right-0">
            <svg className={clsx("absolute top-1/2 -translate-y-1/2 stroke-green-500 [stroke-dasharray:100] [stroke-dashoffset:100]", {
              ' animate-draw-svg': copyResult,
              'animate-reverse-draw-svg': !copyResult
            })} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 6.4L4.63025 10L12.6975 1" stroke={color} strokeWidth="2" />
            </svg>
            <svg className={clsx("absolute top-1/2 -translate-y-1/2 cursor-pointer stroke-white", {
              'animate-reverse-draw-svg opacity-0': copyResult,
              'animate-draw-svg delay-500 opacity-1': !copyResult
            })} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.15385 1.0625H2C1.44772 1.0625 1 1.51021 1 2.0625V15.9999C1 16.5522 1.44772 16.9999 2 16.9999H14C14.5523 16.9999 15 16.5522 15 15.9999V2.0625C15 1.51022 14.5523 1.0625 14 1.0625H12.8462" stroke={color} stroke-width="1.5" />
              <mask id="path-2-inside-1_200_1487" fill="white">
                <rect x="4.23047" width="7.53846" height="5.31248" rx="1" />
              </mask>
              <rect x="4.23047" width="7.53846" height="5.31248" rx="1" stroke={color} stroke-width="3" mask="url(#path-2-inside-1_200_1487)" />
            </svg>
          </div>
        </button>

      </div>
    </>
  );
};
