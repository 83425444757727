import clsx from "clsx"
import emitter from "@/core/events/eventemitter";
import { useApp } from "@/ui/hooks/useApp";
import { useRef } from "preact/hooks";

interface XRLaunchButtonProps {
    onClick?: () => void;
}

export const XRLaunchButton = ({ onClick }: XRLaunchButtonProps) => {
    const qrCodeBoxRef = useRef(null);

    const handleClick = () => {
        if (onClick) onClick()
        emitter.emit('xr-start')
    }

    return (
        <div ref={qrCodeBoxRef} className="relative w-full h-full">
            <button
                className={clsx(
                    'flex relative justify-center items-center font-sans text-lg w-[266px] h-[68px] text-lc-purple bg-lc-neon-green ',
                    'animate-fade-in',
                    'disabled:opacity-50',
                    'active:bg-opacity-75',
                )}
                onClick={handleClick}
            >
                <span className="relative mb-2">
                    BEGIN
                </span>
            </button>
        </div>
    )
}