import clsx from 'clsx';
import { Spinner } from '../Spinner';
import { ButtonHTMLAttributes, forwardRef, ReactElement } from 'preact/compat';

const sizes = {
  sm: 'w-[16px] h-[16px]',
  md: 'w-[58px] h-[58px',
  lg: 'w-16 h-16',
};

export type IconButtonProps = ButtonHTMLAttributes<HTMLElement> & {
  size?: keyof typeof sizes;
  isLoading?: boolean;
  icon: ReactElement;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      type = 'button',
      className = '',
      size = 'md',
      isLoading = false,
      icon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'flex justify-center items-center disabled:opacity-70 disabled:cursor-not-allowed shadow-sm focus:outline-none hover:opacity-80',
          sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && icon}
      </button>
    );
  }
);