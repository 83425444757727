import "./style.scss";
import { render } from "preact";
import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import clsx from "clsx";
import emitter from "@/core/events/eventemitter";

// global ui
import { FadeTransition } from "@/ui/components/Transitions/index.ts";

import { useApp } from "@/ui/hooks/useApp.ts";
import useLocalization from "@/ui/hooks/useLocalization";
import useUserId from "@/ui/hooks/useUserId";
import useAnalytics from "@/ui/hooks/useAnalytics";
import useUserDevice from "@/ui/hooks/useUserDevice.ts";
import * as Sentry from "@sentry/react";

import { AppBaseProvider } from "@/ui/context/app-context";

// custom ui
import { XRCheckOverlay } from "./components/Layout/XRCheckOverlay";
import { Splash } from "./components/Layout/index.ts";
import InfoBanner from "./components/Generics/InfoBanner.tsx";
import MenuButton from "./components/Element/MenuButton.tsx";
import RecordingButton from "./components/Generics/RecordingButton.tsx";

import { useArtwork, ArtworkProvider } from "./context/artwork-context.tsx";
import { ProgressOverlay } from "./components/Layout/ProgressOverlay.tsx";
import { MediaPreview } from "./components/Layout/MediaPreview.tsx";
import RefreshDropdown from "./components/Generics/RefreshDropdown.tsx";
import DebugMenu from "./components/Generics/DebugMenu.tsx";
import { RecordingState } from "@/ui/types/RecordingState.ts";
import { MediaRecorderResponse } from "@/core/mediarecorder/mediarecorder.types.ts";
import { useEventListener } from "@/ui/hooks/useEventListener.ts";
import { useCallback } from "react";
import { artworks } from "./data/artworks.ts";
import { useGeolocation } from "@/ui/hooks/useGeolocation.ts";
import { Modal } from "@/ui/components/Elements/index.ts";

import LocationOnboardingImg from "@/projects/lincolncenter/assets/location-onboarding.webp";
import LocationOnboardingBg from "@/projects/lincolncenter/assets/location-onboarding-bg.webp";
import useQueryParams from "@/ui/hooks/useQueryParams.ts";
import { AppState } from "@/ui/types/AppState.ts";
import ContentLincolnCenter from "@/content/lincolncenter/content.lincolncenter.ts";
import { ContentLincolnCenterStates } from "@/content/lincolncenter/content.lincolncenter.states.ts";
import { LandscapeOverlay } from "./components/Layout/LandscapeOverlay.tsx";
import useScreenOrientation from "@/ui/hooks/useScreenOrientation.ts";

import packageJson from "../../../package.json";
import useLocalStorage from "@/ui/hooks/useLocalStorage.ts";
import { ComingSoon } from "./components/Layout/ComingSoon.tsx";
import LincolnCenterEventEmitter from "./events.ts";

const LC_LOC = ["40.77248654998878", "-73.9834781112356"];
const CUST_LOC = ["-33.890417987396724", "151.19056049333173"];

const CALIBRATION_MAX_MS = 50000;
const CALIBRATION_MIN_MS = 10000;

emitter.on("initialized", () => {
  console.log("UI: app initialized");
  emitter.emit("load");
});

export function App() {
  const [localizedSuccess, setLocalizedSuccess] = useState(0);
  const [totalLocalizedCount, setTotalLocalizedCount] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showProgressMenu, setShowProgressMenu] = useState(false);
  const [isMenuClosed, setIsMenuClosed] = useState(false);
  const [videoResult, setVideoResult] = useState<string | null>(null);
  const [appVersion, setAppVersion] = useLocalStorage<any>("appVersion", null);
  const [showArtworkInfo, setShowArtworkInfo] = useState(false);
  const artworkInfoTimerRef = useRef<number | null>(null);

  const { appState, setAppState, recordingState, setRecordingState } = useApp();
  const {
    currentArtwork,
    setCurrentArtwork,
    numArtworksFound,
    artworksFound,
    clearArtworks,
  } = useArtwork();
  const { localizeSuccess, localize, isLocalizing, localizeDuration } =
    useLocalization();

  const { device, isMobile } = useUserDevice();
  const screenOrientation = useScreenOrientation();
  const userId = useUserId();

  const { initAnalytics, sendEvent } = useAnalytics(userId!);
  const queryParams = useQueryParams();

  const geolocation = useGeolocation(
    queryParams.mode === "dev" ? CUST_LOC : LC_LOC
  );

  const handleClearDataOnStart = useCallback(() => {
    const previousAppVersion = appVersion;
    console.log(
      "checking versions...",
      previousAppVersion,
      packageJson.version
    );
    if (previousAppVersion !== packageJson.version) {
      setAppVersion(packageJson.version);
      console.log("clearing artworks");
      clearArtworks();
    }
  }, [appVersion, clearArtworks, setAppVersion]);

  const startCalibrationTime = useRef<number | null>(null);
  const endCalibrationTime = useRef<number | null>(null);
  const calibrationTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleLocalizeSuccess = useCallback(() => {
    if (calibrationTimeout.current) {
      clearTimeout(calibrationTimeout.current);
    }

    endCalibrationTime.current = Date.now();
    const duration = endCalibrationTime.current - startCalibrationTime.current!;

    let calibratedTimeout: NodeJS.Timeout | null = null;

    if (duration < CALIBRATION_MIN_MS) {
      calibratedTimeout = setTimeout(() => {
        setAppState(AppState.CALIBRATED);
        emitter.emit("content-state", ContentLincolnCenterStates.CONTENT);
      }, CALIBRATION_MIN_MS - duration);
    } else {
      setAppState(AppState.CALIBRATED);
      emitter.emit("content-state", ContentLincolnCenterStates.CONTENT);
    }

    return () => {
      if (calibratedTimeout) clearTimeout(calibratedTimeout);
    };
  }, [setAppState]);

  useEffect(() => {
    if (appState === AppState.CALIBRATING) {
      if (calibrationTimeout.current) {
        clearTimeout(calibrationTimeout.current);
      }

      calibrationTimeout.current = setTimeout(() => {
        console.log("UI: Calibration timed out");
        setAppState(AppState.LOCATION_MODAL);
        emitter.emit("content-state", ContentLincolnCenterStates.NONE);
      }, CALIBRATION_MAX_MS);

      const handleCalibrationCompleted = () => {
        console.log("UI: Calibration completed, no localizations");
        setAppState(AppState.LOCATION_MODAL);
        emitter.emit("content-state", ContentLincolnCenterStates.NONE);
      };

      LincolnCenterEventEmitter.on(
        "calibration-completed",
        handleCalibrationCompleted
      );

      return () => {
        LincolnCenterEventEmitter.off(
          "calibration-completed",
          handleCalibrationCompleted
        );
        if (calibrationTimeout.current)
          clearTimeout(calibrationTimeout.current);
      };
    }
  }, [appState, setAppState]);

  const handleLocationOnboardingClose = () => {
    setAppState(AppState.CALIBRATING);
    emitter.emit("content-state", ContentLincolnCenterStates.CALIBRATION);
    if (!isLocalizing) {
      localize();
      startCalibrationTime.current = Date.now();
    }
  };

  useEffect(() => {
    if (appState === AppState.CALIBRATING && localizeSuccess) {
      console.log("UI: localized", localizeSuccess);
      handleLocalizeSuccess();
    }
  }, [localizeSuccess, appState, handleLocalizeSuccess]);

  useEffect(() => {
    const handleLoaded = () => {
      console.log("UI: loading complete");
      setContentLoaded(true);
    };

    const handleContentOn = (id: string) => {
      console.log("id", id);
      if (navigator.vibrate) {
        navigator.vibrate([200, 200]);
      }
      if (artworksFound && !artworksFound.includes(id)) {
        triggerArtworkBanner();
      }
      setCurrentArtwork(id);
      setAppState(AppState.ARTWORK_VIEWING);
      handleMenuPulse();
      sendEvent("artwork_viewed", {
        artwork_id: id,
      });
    };

    const handleContentOff = () => {
      setCurrentArtwork("");
      // setAppState(AppState.CALIBRATED);
    };

    const handleStarted = () => {
      handleClearDataOnStart();
      setAppState(AppState.TRANSITIONING_XR);

      setTimeout(() => {
        if (
          queryParams &&
          ((queryParams.config && queryParams.config.startsWith("offsite")) ||
            (queryParams.app && queryParams.app === "sim3d"))
        ) {
          setAppState(AppState.CALIBRATED);
          emitter.emit("content-state", ContentLincolnCenterStates.CONTENT);
        } else {
          setAppState(AppState.LOCATION_MODAL);
          emitter.emit("content-state", ContentLincolnCenterStates.NONE);
        }
      }, 1000);
    };

    const handleStopped = () => {
      setAppState(AppState.SPLASH);
      emitter.emit("content-state", ContentLincolnCenterStates.NONE);
    };

    const handleLocalized = (e) => {
      if (e.success) {
        sendEvent("localized_success", {
          success: e.success,
          matrix: JSON.stringify(e.matrix),
        });
        setLocalizedSuccess((prev) => prev + 1);
      }
      setTotalLocalizedCount((prev) => prev + 1);
    };

    const startRecording = () => {
      setRecordingState(RecordingState.RECORDING);
    };

    const stopRecording = (result: MediaRecorderResponse | Error) => {
      setRecordingState(RecordingState.NONE);
      if (result instanceof Error) {
        console.warn("An error occurred while recording: ", result.message);
        return;
      }
      if (result && result.url) {
        setVideoResult(result.url);
        setAppState(AppState.MEDIA_SHARE);
      }
    };

    emitter.on("loaded", handleLoaded);
    emitter.on("localized", handleLocalized);
    emitter.on("xr-started", handleStarted);
    emitter.on("xr-exited", handleStopped);
    emitter.on("content-loading", handleContentOn);
    emitter.on("content-unload", handleContentOff);
    emitter.on("record-started", startRecording);
    emitter.on("record-stopped", stopRecording);
    return () => {
      emitter.off("loaded", handleLoaded);
      emitter.off("content-loading", handleContentOn);
      emitter.off("content-unload", handleContentOff);
      emitter.off("xr-started", handleStarted);
      emitter.off("xr-exited", handleStopped);
      emitter.off("localized", handleLocalized);
      emitter.off("record-started", startRecording);
      emitter.off("record-stopped", stopRecording);
    };
  }, [
    setAppState,
    setCurrentArtwork,
    appState,
    setRecordingState,
    artworksFound,
    handleClearDataOnStart,
    queryParams,
  ]);

  const showUI =
    (appState === "CALIBRATED" || appState === "ARTWORK_VIEWING") &&
    recordingState === "NONE";

  const triggerArtworkBanner = () => {
    setShowArtworkInfo(true);
    if (artworkInfoTimerRef.current) {
      clearTimeout(artworkInfoTimerRef.current);
    }
    artworkInfoTimerRef.current = window.setTimeout(() => {
      setShowArtworkInfo(false);
    }, 5000);
  };

  const [triggerPulse, setTriggerPulse] = useState(false);

  const handleMenuPulse = () => {
    setTriggerPulse(true);
    setTimeout(() => setTriggerPulse(false), 100);
  };

  const handleMenuClose = () => {
    setShowProgressMenu(false);
    setIsMenuClosed(true);
  };

  useEffect(() => {
    if (import.meta.env.DEV) return;
    if (!userId || !device) return;
    const loadGA = async () => {
      await initAnalytics("G-Z7P4MQLWZR");
      if (device === "iOS" || device === "Android" || !isMobile) {
        sendEvent("page_view", {
          page_title: document.title,
          page_path: window.location.pathname,
        });
      }
    };

    loadGA();
  }, [userId, device, initAnalytics, sendEvent, isMobile]);

  const documentRef = useRef<Document>(document);
  useEventListener(
    "visibilitychange",
    () => {
      if (document.hidden) {
        // android only
        if (isMobile && device !== "AppClip") {
          setAppState(AppState.SPLASH);
          if (calibrationTimeout.current)
            clearTimeout(calibrationTimeout.current);

          emitter.emit("content-state", ContentLincolnCenterStates.NONE);
        }
      }
    },
    documentRef
  );
  return (
    <div
      className="h-[100dvh] h-full w-full relative z-10 xbg-white
        "
    >
      <ComingSoon />
      {(import.meta.env.DEV ||
        (import.meta.env.PROD &&
          (queryParams.debug === "true" || queryParams.mode === "dev"))) && (
        <div
          className={clsx("", {
            "opacity-0 pointer-events-none ":
              appState === "SPLASH" || appState === "LOADING",
            "opacity-1 pointer-events-auto":
              appState !== "LOADING" && appState !== "SPLASH",
          })}
        >
          {queryParams && queryParams.debug === "true" && (
            <div className="absolute top-[calc(var(--safe-area-top)+12px)] left-4">
              <RefreshDropdown />
            </div>
          )}
          <div className="bg-black absolute w-full h-[60px] top-[var(--safe-area-top)] bg-opacity-50"></div>
          <div className="absolute top-[calc(var(--safe-area-top)+15px)] left-1/2 -translate-x-1/2 text-[9px] sm:text-xs text-shadow">
            {" "}
            Localizations: {localizedSuccess}/{totalLocalizedCount}
            <br></br>Duration: {localizeDuration}s
          </div>
          {queryParams && queryParams.debug === "true" && (
            <div className="absolute top-[calc(var(--safe-area-top)+12px)] right-4 text-md">
              <DebugMenu />
            </div>
          )}
        </div>
      )}

      {screenOrientation?.includes("landscape") && isMobile && (
        <LandscapeOverlay />
      )}

      <XRCheckOverlay />

      <FadeTransition show={appState === "SPLASH"}>
        <div className="h-full w-full">
          {<Splash contentLoaded={contentLoaded} geolocation={geolocation} />}
        </div>
      </FadeTransition>
      <FadeTransition show={appState === AppState.TRANSITIONING_XR}>
        <div
          className="bg-[#170f2c]"
          style={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
          }}
        ></div>
      </FadeTransition>

      <FadeTransition show={appState === "LOCATION_MODAL"}>
        <div className="h-full w-full">
          <Modal className="h-auto rounded-none">
            <div
              className="pt-[60px] pb-[50px] px-[58px] flex flex-col items-center justify-center h-full bg-cover bg-center"
              style={{ backgroundImage: `url(${LocationOnboardingBg})` }}
            >
              <img
                src={LocationOnboardingImg}
                className="max-w-[285px] w-full"
                alt="Location Onboarding"
              />
              <p className="text-xl xs:text-2xl leading-[31px] font-secondary-sans text-center pb-[50px] pt-[45px]">
                Move to the middle of
                <br /> the Lincoln Center Plaza
                <br /> to calibrate the experience.
              </p>
              <button
                className="flex relative pointer-events-auto justify-center items-center font-sans text-xl w-[266px] h-[68px] text-lc-purple bg-lc-neon-green active:bg-opacity-75"
                onClick={handleLocationOnboardingClose}
              >
                <span className="-mt-2">CALIBRATE</span>
              </button>
            </div>
          </Modal>
        </div>
      </FadeTransition>

      <FadeTransition show={appState === "CALIBRATING"}>
        <div className="absolute top-[calc(var(--safe-area-top)+62px)] w-full px-4">
          <InfoBanner>
            <h2 className="text-[38px] font-secondary-sans font-bold uppercase -mt-[6px]">
              Let's Locate You
            </h2>
          </InfoBanner>
        </div>
      </FadeTransition>

      <FadeTransition show={appState === "CALIBRATED"}>
        <div className="absolute top-[calc(var(--safe-area-top)+62px)] w-full px-4">
          <InfoBanner>
            <h2 className="text-[38px] font-secondary-sans font-bold uppercase -mt-[6px]">
              Tap to Play
            </h2>
          </InfoBanner>
        </div>
      </FadeTransition>

      <FadeTransition show={showUI}>
        <div className="w-full h-full">
          <div className="absolute bottom-12 w-full flex justify-between items-center px-5">
            <div className="w-full flex justify-center">
              <div className="absolute left-1/2 -translate-x-1/2 bottom-0">
                <MenuButton
                  onPulse={triggerPulse}
                  onClick={() => {
                    setShowProgressMenu(true);
                    setIsMenuClosed(false);
                  }}
                  isMenuClosed={isMenuClosed}
                />
              </div>
              <FadeTransition show={showProgressMenu}>
                <div className="h-full w-full fixed inset-0 z-20">
                  <ProgressOverlay onClose={handleMenuClose} />
                </div>
              </FadeTransition>
            </div>
          </div>
        </div>
      </FadeTransition>

      <FadeTransition
        show={showArtworkInfo && appState === AppState.ARTWORK_VIEWING}
      >
        <div className="absolute top-[calc(var(--safe-area-top)+62px)] w-full px-4">
          <InfoBanner>
            <div className="flex flex-col items-center justify-center -mt-[6px]">
              <p className="text-[20px] font-oswald font-bold uppercase">
                {numArtworksFound}/{artworks.length} Discovered
              </p>
              <h2 className="text-center text-[38px] font-secondary-sans font-bold uppercase leading-[1] -mt-[6px]">
                {currentArtwork?.title}
              </h2>
            </div>
          </InfoBanner>
        </div>
      </FadeTransition>

      {device === "AppClip" && (
        <>
          <FadeTransition show={showUI || appState === AppState.RECORDING}>
            <div className="w-full h-full">
              <div className="absolute bottom-14 w-full flex justify-between items-center px-5">
                <div className="flex h-full w-full justify-end">
                  <RecordingButton />
                </div>
              </div>
            </div>
          </FadeTransition>

          <FadeTransition show={appState === "MEDIA_SHARE"}>
            <div className="w-full h-full">
              <MediaPreview videoResult={videoResult} />
            </div>
          </FadeTransition>
        </>
      )}
    </div>
  );
}

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: "https://d0ff7537c39458598cea322b95420505@o1388406.ingest.us.sentry.io/4508457647079424",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  });
}

render(
  <AppBaseProvider>
    <ArtworkProvider>
      <App />
    </ArtworkProvider>
  </AppBaseProvider>,
  document.getElementById("app")!
);
