import { Vector3 } from "babylonjs";
import { Annabelle, CatDance, GovIsland, Idrani, RoofTop } from "./content.lincolncenter.types";
import { AppSim3dConfig } from "@/apps/app.sim3d";
import { AppContentConfig } from "@/apps/app.content";

import OnSiteConfigFile from "@lincolncenter/scene/onsite-graph.json";
import { IAppImmersalMapConfig } from "@/apps/app.immersal";
export interface ILincolnCenterConfig {
  configSim3d: AppSim3dConfig;
  configContent: AppContentConfig;
  configContentLincolnCenter: IContentLincolnCenterConfig;
}
export interface IContentItem {
  name: string;
  position: Vector3;
  target: Vector3;
  rotation: Vector3;
  scale: Vector3;
  radius: number;
  placeholderTransform?: {
    position: Vector3;
    rotation: Vector3;
    scale: Vector3;
  };
  loaderTransform?: {
    position: Vector3;
    rotation: Vector3;
    scale: Vector3;
  };
}

export interface IWayfindingPoint {
  position: IVec3;
}

export interface IWayfindingPath {
  waypoints: IWayfindingPoint[];
}

export interface IContentLincolnCenterConfig {
  geogate: boolean;
  loadPlaza: boolean;
  loadPointClouds: boolean;
  loadWayfinding: boolean;
  showCalibrationVisuals?: boolean;
  hotspots?: boolean;
  items: IContentItem[];
  wayfinding?: IWayfindingPath;
  loadGrid: boolean;
  occlusion?: IOcclusionLincolnCenterConfig;
  maps: IAppImmersalMapConfig[];
}

export interface IOcclusionLincolnCenterConfig {
  position: Vector3;
  rotation: Vector3;
  scale: Vector3;
}

interface IVec3 {
  x: number;
  y: number;
  z: number;
}

interface IContentLincolnCenterPlacement {
  position?: IVec3;
  rotation?: IVec3;
  scale?: IVec3;
}

interface IContentLincolnCenterModelDefinition {
  name: string;
  model: IContentLincolnCenterPlacement;
  placeholder: IContentLincolnCenterPlacement;
  hotspot: IContentLincolnCenterPlacement;
  loader?: IContentLincolnCenterPlacement;
}

interface IMapConfig {
  mapId: number;
  position: IVec3;
  rotation: IVec3;
  scale: IVec3;
}

export interface IContentLincolnCenterSceneDefinition {
  content: IContentLincolnCenterModelDefinition[];
  waypoints: IWayfindingPoint[];
  occlusion?: IContentLincolnCenterPlacement;
  maps?: IMapConfig[];
}

function getContentItems(configFile: IContentLincolnCenterSceneDefinition): IContentItem[] {
    
  const contentItems: IContentItem[] = [];

  configFile.content.forEach((item) => {

    const model = item.model;
    const hotspot = item.hotspot;
    const placeholder = item.placeholder;
    const loader = item.loader;

    if (hotspot.position && model.position && model.rotation && model.scale && hotspot.scale) {
      const mainContent : IContentItem = {
        name: item.name,
        position: new Vector3(hotspot.position.x, hotspot.position.y, hotspot.position.z),
        target: new Vector3(model.position.x, model.position.y, model.position.z),
        rotation: new Vector3(model.rotation.x, model.rotation.y, model.rotation.z),
        scale: new Vector3(model.scale.x, model.scale.y, model.scale.z),
        radius: hotspot.scale.x * 0.5,
        
      };

      if (placeholder && placeholder.position && placeholder.rotation && placeholder.scale) {
        mainContent.placeholderTransform = {
          position: new Vector3(placeholder.position.x, placeholder.position.y, placeholder.position.z),
          rotation: new Vector3(placeholder.rotation.x, placeholder.rotation.y, placeholder.rotation.z),
          scale: new Vector3(placeholder.scale.x, placeholder.scale.y, placeholder.scale.z),
        };
      }

      if (loader && loader.position && loader.rotation && loader.scale) {
        mainContent.loaderTransform = {
          position: new Vector3(loader.position.x, loader.position.y, loader.position.z),
          rotation: new Vector3(loader.rotation.x, loader.rotation.y, loader.rotation.z),
          scale: new Vector3(loader.scale.x, loader.scale.y, loader.scale.z),
        };
      }

      contentItems.push(mainContent);
    }
  });

  return contentItems;
}

function getWayfinding(configFile: IContentLincolnCenterSceneDefinition): IWayfindingPath | undefined {
  if (configFile.waypoints) {
    const waypoints: IWayfindingPoint[] = [];
    configFile.waypoints.forEach((waypoint) => {
      waypoints.push({
        position: new Vector3(waypoint.position.x, waypoint.position.y, waypoint.position.z)
      });
    });

    return {
      waypoints: waypoints
    };
  }
  return undefined;
}

function getOcculsion(configFile: IContentLincolnCenterSceneDefinition): IOcclusionLincolnCenterConfig | undefined {
  if (configFile.occlusion) {
    if (!configFile.occlusion.position || !configFile.occlusion.rotation || !configFile.occlusion.scale) {
      console.error("Occlusion config missing required properties");
      return undefined
    }

    return {
      position: new Vector3(configFile.occlusion.position.x, configFile.occlusion.position.y, configFile.occlusion.position.z),
      rotation: new Vector3(configFile.occlusion.rotation.x, configFile.occlusion.rotation.y, configFile.occlusion.rotation.z),
      scale: new Vector3(configFile.occlusion.scale.x, configFile.occlusion.scale.y, configFile.occlusion.scale.z),
    };
  }
  return undefined;
}

function getMaps (configFile: IContentLincolnCenterSceneDefinition): IAppImmersalMapConfig[] {
  const maps: IAppImmersalMapConfig[] = [];
  if (configFile.maps) {
    configFile.maps.forEach((map) => {
      maps.push({
        mapId: map.mapId,
        position: [map.position.x, map.position.y, map.position.z],
        rotation: [map.rotation.x, map.rotation.y, map.rotation.z],
      });
    });
  }

  return maps;
}

export function LincolnCenterOnsiteConfig(): ILincolnCenterConfig {

  const configSim3d = new AppSim3dConfig();
  configSim3d.cameraLockedToHeight = 1.2;
  
  const camInit: { [key:string]: ()=>void } = {
    [GovIsland]: () => {
      configSim3d.cameraInitialPosition = new Vector3(18, 1.2, 15);
      configSim3d.cameraInitialRotationDegrees = 180;
    },
    [RoofTop]: () => {
      configSim3d.cameraInitialPosition = new Vector3(-34, 1.2, 60);
      configSim3d.cameraInitialRotationDegrees = -90;
    },
    [Annabelle]: () => {
      configSim3d.cameraInitialPosition = new Vector3(19, 1.2, -38);
      configSim3d.cameraInitialRotationDegrees = 90;
    },
    [Idrani]: () => {
      configSim3d.cameraInitialPosition = new Vector3(2, 1.2, -54);
      configSim3d.cameraInitialRotationDegrees = 90;
    },
    [CatDance]: () => {
      configSim3d.cameraInitialPosition = new Vector3(-54, 1.2, 14);
      configSim3d.cameraInitialRotationDegrees = -70;
    }
  }
  camInit[CatDance]();

  const configContent = new AppContentConfig();
  configContent.loadBatchSize = 10; // max number of concurrent loads at one time.

  const contentItems = getContentItems(OnSiteConfigFile);
  const wayfinding : IWayfindingPath | undefined = getWayfinding(OnSiteConfigFile);
  const occulsion : IOcclusionLincolnCenterConfig | undefined = getOcculsion(OnSiteConfigFile);
  const sceneMaps : IAppImmersalMapConfig[] = getMaps(OnSiteConfigFile);

  const config: ILincolnCenterConfig = {
    configSim3d: configSim3d,
    configContent: configContent,
    
    configContentLincolnCenter: {
      showCalibrationVisuals: true,
      geogate: true,
      loadPlaza: true,
      loadPointClouds: import.meta.env.DEV ? true : false,
      loadWayfinding: true,
      items: contentItems,
      wayfinding: wayfinding,
      loadGrid: false,
      hotspots: false,
      maps: sceneMaps,
      occlusion: occulsion,
    }
  }
  return config;
}

export function LincolnCenterOffsiteConfig(sceneDef: IContentLincolnCenterSceneDefinition): ILincolnCenterConfig {

  const configSim3d = new AppSim3dConfig();
  configSim3d.cameraLockedToHeight = 1.2;
  configSim3d.cameraInitialPosition = new Vector3(0, 0, 0);
  configSim3d.cameraInitialRotationDegrees = 0;

  const configContent = new AppContentConfig();
  configContent.loadBatchSize = 10; // max number of concurrent loads at one time.

  const contentItems = getContentItems(sceneDef);
  const wayfinding : IWayfindingPath | undefined = getWayfinding(sceneDef);

  const config: ILincolnCenterConfig = {
    configSim3d: configSim3d,
    configContent: configContent,
    configContentLincolnCenter: {
      geogate: false,
      loadPlaza: true,
      loadPointClouds: false,
      loadWayfinding: false,
      loadGrid: true,
      hotspots: false,
      showCalibrationVisuals: true,
      items: contentItems,
      wayfinding: wayfinding,
      maps: [],
    }
  }
  return config;
}
