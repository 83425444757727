import { detectIncognito } from "detectincognitojs";

// @ts-expect-error; Types required
import type { Navigator } from "webxr";
import { Ref, useEffect, useMemo, useState } from "preact/hooks";
import useUserDevice from "./useUserDevice";

const useDeviceSupportedSessions = (
  sessions: XRSessionMode[]
): [XRSessionMode[], boolean] => {
  
  const [loading, setLoading] = useState(true);
  const [supportedSessions, setSupportedSessions] = useState<XRSessionMode[]>(
    []
  );
  useEffect(() => {
    if (navigator.xr && navigator.xr.isSessionSupported) {
      const sessionCheck = sessions.map((session) =>
        navigator.xr?.isSessionSupported(session).then((supported) => {
          if (!supported) return;
          setSupportedSessions((prev) => [...prev, session]);
        })
      );
      Promise.all(sessionCheck).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);
  return [supportedSessions, loading];
};

function iOS() {
  return (
    /^iP/.test(navigator.platform) ||
    (/^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4)
  );
}

export enum XRCheckStatus {
  NO_TARGET_URL = "NO_TARGET_URL",
  IOS_NOT_SAFARI = "IOS_NOT_SAFARI",
  IOS_NO_APP_CLIP = "IOS_NO_APP_CLIP",
  IOS_INCOGNITO = "IOS_INCOGNITO",
  SHOW_APP_CLIP = "SHOW_APP_CLIP",
  ANDROID_NOT_CHROME = "ANDROID_NOT_CHROME",
  OK = "OK",
}

type useXRCheckOpts = {
  targetUrl: Ref<string> | any;
  onSuccess?: Ref<() => void> | any;
};

const useXRCheck = (opts: useXRCheckOpts) => {
  const [supportedSessions, xrChecking] = useDeviceSupportedSessions([
    "immersive-ar",
  ]);
  const { isMobile } = useUserDevice();

  // Incognito detection
  const [hasCheckedIncognito, setHasCheckedIncognito] = useState(false);
  const [hasAppClipSupport, setHasAppClipSupport] = useState(false);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (iOS()) {
      const extract = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (extract && extract.length >= 4) {
        const [_, major, minor] = extract;
        const version = parseFloat(`${major}.${minor}`)
        setHasAppClipSupport(version >= 15.6);
      }
    }
    setMounted(true);
  }, []);
  const [isIncognito, setIsIncognito] = useState(false);
  const [browserName, setBrowserName] = useState<string | undefined>();
  useEffect(() => {
    detectIncognito().then(({ isPrivate, browserName: browser }) => {
      setHasCheckedIncognito(true);
      setIsIncognito(isPrivate);
      setBrowserName(browser);
      if (navigator.userAgent.includes("CriOS")) {
        setBrowserName("Chrome iOS");
      }
      if (navigator.userAgent.includes("FxiOS")) {
        setBrowserName("Firefox iOS");
      }
    });
  }, []);

  const hasXR = useMemo(
    () => supportedSessions.length > 0,
    [supportedSessions]
  );

  const status = useMemo(() => {
    if (!opts.targetUrl) return XRCheckStatus.NO_TARGET_URL;
    if (hasXR) return XRCheckStatus.OK;
    if (iOS()) {
      if (browserName !== "Safari") return XRCheckStatus.IOS_NOT_SAFARI;
      if (isIncognito) return XRCheckStatus.IOS_INCOGNITO;
      if (!hasAppClipSupport) return XRCheckStatus.IOS_NO_APP_CLIP;
      if (!hasXR) return XRCheckStatus.SHOW_APP_CLIP;
    }

    if (!iOS() && isMobile) {
      if (browserName !== "Chrome") {
        return XRCheckStatus.ANDROID_NOT_CHROME;
      }
    }
    return XRCheckStatus.OK;
  }, [opts, hasXR, browserName, isIncognito, hasAppClipSupport, isMobile]);

  useEffect(() => {
    const onSuccessHandler = opts && opts.onSuccess;
    if (
      status === XRCheckStatus.OK &&
      onSuccessHandler &&
      mounted &&
      hasCheckedIncognito &&
      !xrChecking
    ) {
      onSuccessHandler();
    }
  }, [opts, status, mounted, hasCheckedIncognito, xrChecking]);

  return status;
};

export default useXRCheck;
