// src/components/Menu.tsx

import { useEffect, useState } from 'preact/hooks';

interface MenuButtonProps {
  size?: number;
  onPulse?: boolean;
  onClick?: () => void;
  isMenuClosed?: boolean;
}

const MAX_PULSE = 6

const MenuButton = ({ size = 85, onClick, onPulse, isMenuClosed }: MenuButtonProps) => {
  const [pulses, setPulses] = useState<number[]>([]);
  const [bgColor, setBgColor] = useState('black');

  const startPulsing = () => {
    let pulseCount = 0;

    const triggerPulse = () => {
      const pulseId = Date.now();
      setPulses((prev) => [...prev, pulseId]);

      setTimeout(() => {
        setPulses((prev) => prev.filter((id) => id !== pulseId));
      }, 1000); // Match pulse animation duration
    };

    triggerPulse();
    pulseCount++;

    const interval = setInterval(() => {
      if (pulseCount < MAX_PULSE) {
        triggerPulse();
        pulseCount++;
      } else {
        clearInterval(interval);
        setBgColor('#00FFFF');
      }
    }, 1500);
  };

  useEffect(() => {
    if (onPulse) {
      startPulsing();
    }
  }, [onPulse]);

  useEffect(() => {
    if (isMenuClosed) {
      setBgColor('black'); 
    }
  }, [isMenuClosed]);


  const handleClick = () => {
    setBgColor('black');
    if (onClick) onClick();
  };

  const toggleBgColor = () => {
    setBgColor((prevColor) => (prevColor === 'black' ? '#00FFFF' : 'black'));
  };

  return (
    <button
      onClick={handleClick}
      className="relative focus:outline-none"
      style={{ width: size, height: size }}
      aria-label="MenuButton"
    >
      {pulses.map((pulse) => (
        <div
          key={pulse}
          className="absolute inset-0 flex items-center justify-center"
        >
          {[...Array(3)].map((_, index) => (
            <span
              key={index}
              className="absolute rounded-full border border-cyan animate-pulseRing"
              style={{
                width: '100%',
                height: '100%',
                borderWidth: '3px',
                animationDelay: `${index * 0.2}s`,
              }}
            ></span>
          ))}
        </div>
      ))}

      <div className="relative z-10">
        <svg
          width={size}
          height={size}
          viewBox="0 0 85 85"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="42.5"
            cy="42.5"
            r="41"
            fill={bgColor}
            fillOpacity="0.4"
            stroke="#00FFFF"
            strokeWidth="3"
            style={{ transition: 'fill 1s ease' }}
          />
          <circle
            cx="42.5"
            cy="42.5"
            r="41.5"
            fill="#00FFFF"
            fillOpacity="0.1"
          />
          <line
            x1="58.6666"
            y1="53.5"
            x2="27"
            y2="53.5"
            stroke="#00FFFF"
            strokeWidth="3"
          />
          <line
            x1="58.6666"
            y1="43.5"
            x2="27"
            y2="43.5"
            stroke="#00FFFF"
            strokeWidth="3"
          />
          <line
            x1="58.6666"
            y1="33.5"
            x2="27"
            y2="33.5"
            stroke="#00FFFF"
            strokeWidth="3"
          />
        </svg>
      </div>
    </button>
  );
};

export default MenuButton;
