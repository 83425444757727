import clsx from "clsx";
import SplashBgDesktop from "@/projects/lincolncenter/assets/desktop-bg.webp";
import LCLogo from "@/projects/lincolncenter/assets/lc-logo.svg";

export function ComingSoon() {
  return (
    <div
      className={clsx(
        "relative z-[2000] bg-cover bg-bottom pt-[calc(var(--safe-area-top)+60px)] pb-[calc(var(--safe-area-top)+60px)] h-full w-full text-white pointer-events-auto items-center xpx-5 xpb-12 flex flex-col"
      )}
      style={{
        backgroundImage: `url(${SplashBgDesktop})`,
        backgroundColor: "#27213a",
      }}
    >
      <div
        className={clsx(
          "flex-grow items-center px-4 flex flex-col gap-6 text-center basis-1/3 justify-start"
        )}
      >
        <img
          src={LCLogo}
          alt="Lincoln Center Logo"
          className="max-w-[200px] w-full"
        />
        <h2
          className={clsx(
            "uppercase text-[78px] sm:text-[90px] 3xl:text-[120px] font-secondary-sans font-bold leading-[0.85] pb-3"
          )}
        >
          Archive <br />
          of Dance
        </h2>
        </div>
        <p
          className={clsx(
            "basis-2/3 uppercase pt-32 text-[35px] sm:text-[48px] 3xl:text-[36px] font-secondary-sans font-light"
          )}
        >
            Coming Soon
        </p>
    </div>
  );
}
