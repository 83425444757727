const TermsAndConditions = () => {
    return (
        <>
            <h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">EyeJack Services Terms of Use</h2>
<p className="font-inter-light leading-[22px]">These Terms of Use (“Terms“) constitute a binding legal agreement between the licensees and (to the extent stipulated herein, the end users) (collectively referred to in these Terms as “you”, “your”, “yours”, and their derivatives), and EYEJACK PTY LTD ABN 80 615 617 135 (referred to in these Terms as “Company“, “we”, “us”, “our”, “ours”, and their derivatives). These Terms govern your right to download, obtain, access, and make use of the EyeJack Creator desktop app and/or EyeJack mobile app, EyeJack WebAR and/or EyeJack WebAR Services and any Company content, code, algorithms and other proprietary material and software contained in or made available via the EyeJack Creator desktop app and/or EyeJack mobile app and/or EyeJack WebAR Services (collectively, “EyeJack Services”), providing any content, including information, text, images, audio, video, and other materials and data for the purposes of setting up an Account (defined below) and/or Subscription Plan (defined below), or for use or display by or to you or other parties, and/or accessing, using, or displaying any content or information provided by or via the EyeJack Services, and your use of this website (“Website”).</p>
<p className="font-inter-light leading-[22px]">EyeJack Services. (“EyeJack Services,” “we” or “our”) offers different types of accounts, ranging from free (“Free Account”) to paid accounts. Within the paid accounts (“Paid Accounts”), EyeJack Services offers individual accounts (“Individual Accounts”), multi-user accounts for small teams (“Team Accounts”), and multi-user accounts for organizations and companies (“Enterprise Account”)(collectively, “Our Services”).</p>
<p className="font-inter-light leading-[22px]">We reserve the right to change these Terms from time to time with or without notice to you. You acknowledge and agree that it is your responsibility to periodically review this Website and these Terms. Your continued use of this Website and Services after such modifications will constitute acknowledgement and acceptance of the modified Terms.</p>
<p className="font-inter-light leading-[22px]">As used in these Terms, references to our “Affiliates” include our owners, licensees, assigns, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, advertisers, and includes (without limitation) all parties involved in creating, producing, and/or delivering this Website and/or contents and Services available on this Site.</p>
<p className="font-inter-light leading-[22px]">BY USING THIS WEBSITE AND ANY AND ALL OF THE EYEJACK SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS, PLEASE EXIT THE WEBSITE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THIS WEBSITE, PRODUCTS, SERVICES OR SERVICES AVAILABLE ON THIS WEBSITE, OR THESE TERMS IS TO CEASE USING THE WEBSITE AND/OR THOSE PARTICULAR PRODUCTS, OR SERVICES. THESE TERMS ARE EFFECTIVE ONCE YOU CLICK THE ‘I AGREE’ BUTTON BELOW.</p>
<p className="font-inter-light leading-[22px]">By downloading and/or using the EyeJack Services and/or other Services, you agree to be bound by these Terms. If you do not agree to these Terms, do not download or use the EyeJack Services and/or other Services, do not create an Account and do not sign up to a Subscription Plan. If the application or account has already been downloaded/created, you should permanently remove and delete the mobile application from your mobile device, deactivate any account you might have created and do not access or attempt to access the EyeJack Services and/or other Services.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">General Terms Applicable to Use of EyeJack Services</h2>
<p className="font-inter-light leading-[22px]">As used in these Terms, “EyeJack Services” refers to our proprietary products, software, and service that allow users to transform compatible artworks into augmented reality pieces and view and download other user’s content (where they elect to publish it), and include the EyeJack Creator desktop app, EyeJack mobile app, EyeJack WebAR and EyeJack WebAR Services.</p>
<p className="font-inter-light leading-[22px]">The Services and its contents are intended solely for the personal, non-commercial use of Services by users and may only be used in accordance with the terms of this Agreement.</p>
<p className="font-inter-light leading-[22px]">As per the above, you will be able to view selected augmented reality content of other users, where they elect to publish it on the EyeJack Services. Any such content of other users made available for viewing or download can only be used for private purposes and cannot be altered, used or otherwise reproduced for commercial purposes (e.g. to sell or license), unless otherwise indicated by the relevant copyright owner.</p>
<p className="font-inter-light leading-[22px]">By your access and use of the EyeJack Services, you affirm that you are either 18 years of age or older, or an emancipated minor, or possess parental or guardian consent. You also affirm that you are fully able and competent to enter into these terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms, or that your parent or guardian is and does on your behalf.</p>
<p className="font-inter-light leading-[22px]">For the sake of clarity, in certain instances these Terms may be entered into by a single licensee on behalf of a group of end users, specifically in the case of educational institutions who procure the use of the EyeJack Services on behalf of students, some or all of whom may be under the age of 18+. In these instances, unless expressly agreed otherwise by us, the licensee enters into these Terms on behalf of the students in each case and is responsible for the conduct of the students using the EyeJack Services.</p>
<p className="font-inter-light leading-[22px]">We reserve the right to modify or discontinue all or any portion of the software, applications, functionalities, design, makeup, content, and delivery systems that are included as part of or through the EyeJack Services, and/or to update or revise the EyeJack Services, at any time, in our sole discretion and without prior notice to you. We reserve the right to temporarily or permanently cease providing or supporting the EyeJack Services in their entirety or in part, or any of the content contained therein, to you or to end users generally, or to place limits on access to the EyeJack Services or any of its features or functionalities, with or without notice, at our discretion.</p>
<p className="font-inter-light leading-[22px]">EyeJack Services may only be used for lawful purposes and in accordance with these Terms. You agree to comply with the applicable local, state, national and foreign laws, treaties and regulations in connection with your use of the EyeJack Services.</p>
<p className="font-inter-light leading-[22px]">In addition and without limitation, you agree that you will not do any of the following in downloading, using or accessing EyeJack Services:</p>
<ul className="list-disc">
  <li className="font-inter-light leading-[22px]">attempt to gain access to or search any of our systems or functionalities, or attempt to download content within the EyeJack Services, through the use of any search or download engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software, search agents, and interface(s) we provide and include for use within EyeJack Services;</li>
  <li className="font-inter-light leading-[22px]">attempt to access, tamper with, or use non-public or restricted area information, areas, servers, equipment, or facilities, of the EyeJack Services;</li>
  <li className="font-inter-light leading-[22px]">gather the information of other users, such as their usernames, login information, real names, email addresses, and the like, or transmit any unsolicited advertising, junk mail, spam or other form of solicitation;</li>
  <li className="font-inter-light leading-[22px]">use EyeJack Services for the benefit of any third party or in any manner not indicated as approved by these Terms;</li>
  <li className="font-inter-light leading-[22px]">violate any applicable law or regulation, including the laws applicable in Australia; or</li>
  <li className="font-inter-light leading-[22px]">encourage or enable any other individual to do any of the foregoing.</li>
</ul>
<p className="font-inter-light leading-[22px]">We reserve the right to investigate and prosecute breaches of any of the above terms and/or involve and cooperate with the applicable law enforcement authorities in prosecuting users who violate these Terms and/or the applicable laws. You acknowledge that we have full right and authority, but not the obligation, to monitor your access to or use of the EyeJack Services, for operation and maintenance, to ensure your compliance with these Terms, to comply with applicable law, rule, regulation, or for any other legitimate purpose.</p>
<p className="font-inter-light leading-[22px]">You agree that we may place, or may authorise third parties to place, advertising, marketing and promotional materials within the EyeJack Services.</p>
<p className="font-inter-light leading-[22px]">You expressly consent to the provision and collection of location-based services and information in connection with the EyeJack Services.</p>
<p className="font-inter-light leading-[22px]">Access to EyeJack Services requires a connection to the Internet in order to function. Your device will need to be connected either via Wi-Fi or a 3G/4G data connection. You are responsible for all charges by your Internet service provider or carrier for Internet access.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Limited License to Use the EyeJack Services</h2>
<p className="font-inter-light leading-[22px]">You are only permitted to use the EyeJack Services in line with the functionalities offered by the EyeJack Services, including to upload and integrate your content into an augmented reality piece.</p>
<p className="font-inter-light leading-[22px]">You may not:</p>
<ul>
  <li className="font-inter-light leading-[22px]">copy, modify or distribute the EyeJack Services for any purpose;</li>
  <li className="font-inter-light leading-[22px]">transfer, sublicense, lease, lend, rent or otherwise distribute the EyeJack Services to any third party;</li>
  <li className="font-inter-light leading-[22px]">decompile, reverse-engineer, disassemble, or create derivative works of the EyeJack Services; or</li>
  <li className="font-inter-light leading-[22px]">use the EyeJack Services in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms. We and/or our affiliates and licensors are exclusive owners of all right, title and interest, including all associated intellectual property rights in the EyeJack Services, and we reserve any rights not explicitly granted in these Terms.</li>
</ul>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">User Accounts</h2>
<p className="font-inter-light leading-[22px]">We may, in our discretion, require you to register and create an account (“Account”) in order to make use of or access EyeJack Services and/or sign up to a Subscription Plan. In creating an Account, you represent that you are aged 18 years or over and able to form a legally binding contract. When creating an Account, you may be required to provide certain personal information about yourself and establish a username and a password. You agree to provide accurate, current and complete information when creating your Account and to update such information as needed. We may suspend or terminate your Account (including any associated Subscription Plan) if any information provided during the registration process or thereafter proves to be incomplete, inaccurate, false or misleading. You are responsible for safeguarding your password and for any activities or actions under your Account, whether or not you have authorised such activities or actions. To the maximum extent permitted by law, we are not liable for any loss or damages you might suffer due to a third party accessing your Account and/or obtaining access to any information provided by you in connection with your Account, or for any other loss or damage arising from your failure to comply with these requirements.</p>
<p className="font-inter-light leading-[22px]">EyeJack reserves the right to introduce new subscription fees or plans or amend subscription fees or plans in the future. The timing, cost and terms applicable to such a plans will be made available prior to any such plan coming into effect. The current plans are as outlined below, although they are subject to change.</p>
<p className="font-inter-light leading-[22px]">In order to access some of the Services on this Website, or to post User Content (defined below), you may be asked to set up an account and password. Our account registration page requests certain personal information from you (“Registration Info”). You will have the ability to maintain and periodically update your Registration Info as you see fit. By registering, you agree that all information provided by you as Registration Info is true and accurate and that you will maintain and update this information as required in order to keep it current, complete and accurate.</p>
<p className="font-inter-light leading-[22px]">If you register for an account on the Website, you agree that you are responsible for maintaining the security and confidentiality of your password, and that you are fully responsible for all activities or charges that are incurred under your account. Therefore, you must take reasonable steps to ensure that others do not gain access to your password and account. Our employees will never ask you for your password.</p>
<p className="font-inter-light leading-[22px]">You hereby grant us the right to disclose to third parties certain Registration Info about you. The information we obtain through your use of this site, including your Registration Info, is subject to our Privacy Policy, which is specifically incorporated by reference into these Terms of Use.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Subscription Plans</h2>
<p className="font-inter-light leading-[22px]">There are currently three (3) Subscription Plan options:</p>
<p className="font-inter-light leading-[22px]">(i) ‘Free Subscription Plan’ - Up to 100 free views per month. Once Views run out, artworks are disabled until next monthly period.</p>
<p className="font-inter-light leading-[22px]">(ii) ‘Artist Subscription Plan’ - Up to 10,000 views per month, at USD$10 per month, and USD$0.01 per view once limit is reached.</p>
<p className="font-inter-light leading-[22px]">(iii) ‘Business Subscription Plan’ - Up to 100,000 views per month, at USD$100 per month, and USD$0.01 per view once limit is reached.</p>
<p className="font-inter-light leading-[22px]">Each referred to as “Subscription Plan”. Further details on each Subscription Plan can be found <a className="underline" target="_blank" href="https://creator.eyejackapp.com/#price">https://creator.eyejackapp.com/#price</a>, and should be read together with these Terms. When signing-up to a Subscription Plan, individuals can manage their subscription, including payment details, upgrade of a Subscription Plan, cancellation of a Subscription Plan, and other such factors.</p>
<p className="font-inter-light leading-[22px]">For clarity, these Terms relate to all Subscription Plans now and, in the future, unless we expressly specify otherwise.</p>
<p className="font-inter-light leading-[22px]">EyeJack Education plans are based on the artist subscription plan but offers a rolling discount percentage based on volume. This information can be found on <a className="underline" target="_blank" href="http://edu.eyejackapp.com">edu.eyejackapp.com</a></p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Charges and Fees for Subscription Plans</h2>
<p className="font-inter-light leading-[22px]">As a subscriber to one of the Subscription Plans, you agree as follows:</p>
<p className="font-inter-light leading-[22px]">(i) You agree to pay, using a valid credit card (or other form of payment which we may accept from time to time), the fixed and periodic charges and fees set forth by Company, applicable taxes, and other charges and fees incurred in order to use or access the EyeJack Services under a Subscription Plan.</p>
<p className="font-inter-light leading-[22px]">(ii) We reserve the right to increase charges and fees, or to institute new charges or fees at any time, upon advance notice communicated to you through a posting on the EyeJack Creator desktop app and/or EyeJack mobile app and/or EyeJack WebAR and/or EyeJack WebAR Services or such other means as we may deem appropriate from time to time (including electronic mail or conventional mail).</p>
<p className="font-inter-light leading-[22px]">(iii) After initial payment of a Subscription Plan, your Account will be updated to a recurring monthly subscription for the Subscription Plan you have purchased. We will automatically charge your credit card (or other account) the applicable monthly fee at the start of each monthly renewal period (corresponding to the date of initial payment), unless you cancel your subscription before you are charged for the relevant renewal period. In the event we cannot charge your Account, we reserve the right to discontinue or terminate your Subscription Plan and use of or access to the EyeJack Services.</p>
<p className="font-inter-light leading-[22px]">(iv) All fixed and periodic charges and fees for our Subscription Plans are quoted in US dollars and are inclusive of any applicable VAT or GST, except where otherwise stated.</p>
<p className="font-inter-light leading-[22px]">(v) In addition to the Subscription Plan fees and charges, you are responsible for all charges and fees associated with signing up for, using and in connection to the Subscription Plan and connecting to the EyeJack Services, including without limitation internet service provider fees, telephone, computer and printer equipment, sales taxes and any other fees and charges necessary to use or access the EyeJack Services. In addition, you may incur an additional processing and/or merchant charge from your financial institution on each payment you make for our Subscription Plan.</p>
<p className="font-inter-light leading-[22px]">(vi) For the purposes of your access to and use of the EyeJack Services, including identification and billing you agree to provide us with true, accurate and complete information as required by the subscription or sign up process to the Subscription Plan ("Subscription Data"), including your legal name, address, telephone number, email address and applicable billing information (e.g. credit card number and expiration date), and to allow us to share your Subscription Data with third parties for the purpose of verifying the information you provide and billing your credit card or otherwise charging your Account. You agree to maintain and promptly update the Subscription Data and any other information you provide to us, to keep it accurate at all times. Without limiting any other provision of these Terms, if you provide any information that is untrue, inaccurate, or incomplete, or Company has reasonable grounds to suspect that such is the case, Company reserves the right to suspend or terminate your Account or Subscription Plan and refuse any and all current or future use and/or access by you of any of the EyeJack Services. You are obligated to check your Account details to determine whether your Subscription Data is current and accurate, and, if not, to correct or update your Subscription Data. Other than where expressly allowed by us, you agree not to register or subscribe for more than one account, create an account on behalf of someone else, or create a false or misleading identity on the EyeJack Services.</p>
<p className="font-inter-light leading-[22px]">(vii) If your Subscription Plan is revoked for any reason, you agree not to sign-up or subscribe again using another Account or through any other means unless approved by Company. If Company has reason to suspect, in our sole discretion, that your Subscription Plan or Account has previously been terminated, Company reserves the right to terminate any new Subscription Plans signed up to or Accounts you have registered without any notice to you, or to exercise any other remedies available to Company under these Terms or by law.</p>
<p className="font-inter-light leading-[22px]">(viii) In the event of a breach of security by you using the EyeJack Services, you will remain liable for any unauthorised use of your Subscription Plan until you update your Subscription Data. You are entirely responsible for any and all activities which occur under your Account. You are responsible for paying any amounts billed to your credit card by a third party which were not authorised by you.</p>
<p className="font-inter-light leading-[22px]">(ix) Except for your statutory rights which are unaffected, should you wish to cancel any Subscription Plan, refunds are not permitted (unless otherwise stated), regardless of whether you have used any EyeJack Services during that subscription period.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Privacy</h2>
<p className="font-inter-light leading-[22px]">Any personal information that you provide us (including any provided by you in connection with setting up your Account) shall be governed by and subject to our Privacy Policy, which governs our collection and use of your personal information. Our Privacy Policy is available at <a href="https://eyejackapp.com/pages/privacy">https://eyejackapp.com/pages/privacy</a></p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Warranties and Conditions</h2>
<p className="font-inter-light leading-[22px]">You agree and warrant the following:</p>
<p className="font-inter-light leading-[22px]">(i) You must not and will not upload, post, email, reproduce or otherwise transmit:</p>
<p className="font-inter-light leading-[22px]">(a) any content which you do not have the lawful right to upload, copy, transmit, publish, reproduce, display or otherwise exploit (including but not limited to any content that would violate any confidentiality or fiduciary obligations that you might have with respect to the content); or</p>
<p className="font-inter-light leading-[22px]">(b) any content that infringes the intellectual property rights of any third party (including without limitation copyright, trademark, patent, trade secret, or other intellectual property right, or moral right or right of publicity); or</p>
<p className="font-inter-light leading-[22px]">(c) any content that violates the privacy rights of any third party.</p>
<p className="font-inter-light leading-[22px]">(ii) You must not and will not publish any content that could damage the Company, our business, any of our affiliates, partners, end users, or any third party;</p>
<p className="font-inter-light leading-[22px]">(iii) You must not and will not submit material that is false, untruthful, unlawful, obscene, defamatory, libellous, threatening, harassing, hateful, racially or ethnically offensive, or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or is otherwise inappropriate;</p>
<p className="font-inter-light leading-[22px]">(iv) You will not impersonate another person or entity; and</p>
<p className="font-inter-light leading-[22px]">(v) You must not and will not copy, publish, adapt, modify or otherwise reproduce content of other users or any content that is the property of the Company or its licensees, that is made available via the EyeJack Services, other than as made available by the users and the Company via the standard functionality of the EyeJack Services and in accordance with these Terms.</p>
<p className="font-inter-light leading-[22px]">The Company does not endorse any content, and we express no opinion, recommendation, or advice regarding the content housed within the EyeJack Services.</p>
<p className="font-inter-light leading-[22px]">We have absolute discretion to determine whether your content is appropriate for use in EyeJack Services and any user guidelines published by us and these Terms of Use. We may remove any content and/or terminate your access for uploading objectionable content, without prior notice and at our sole discretion. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting, publishing or otherwise making available content or other materials that are believed to violate these Terms.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Ownership of Your Content and License to Use Your Content</h2>
<p className="font-inter-light leading-[22px]">You retain copyright in any content you submit to the EyeJack Services (“User Content”), and your use of the EyeJack Services will not transfer any ownership in your content to the Company. By using the EyeJack Services and uploading content you agree that we may, and we expressly reserve the rights to, use content you upload or otherwise provide via the EyeJack Services, in its original form and/or in the resulting integration (with animation and other effects, in its augmented reality form) for marketing and branding purposes of the Company and the EyeJack Services, in any and all media, worldwide, without remuneration or royalties, for an indefinite period, and for internal administrative and business purposes. In this respect, you agree that any such use will not be subject to your prior approval and that we have the right to edit, modify and adapt your content, as we deem necessary for these purposes. We will not replicate content uploaded or generated by you for direct commercial / selling purposes.</p>
<p className="font-inter-light leading-[22px]">By uploading content, you agree to that content undergoing the transformations as available within the EyeJack Services and as selected by you, and to being displayed via the EyeJack Services (in its original and integrated form), including that the content may be viewed by other users, where you elect to publish it and/or make it available for download. Content submitted by you cannot be lawfully downloaded via the EyeJack Services by other users without your consent (e.g. unless you elect to publish it and make it available for download). However, if you elect to publish your content via the EyeJack Services (and make it available for public viewing or download), you license and grant each user to view and download such content for their own private, non-commercial use, for an indefinite period and without any compensation.</p>
<p className="font-inter-light leading-[22px]">If you have any moral rights (or similar rights) in respect of the content that you upload or otherwise submit and the Company elects to reproduce or modify as contemplated in these Terms, you consent to the Company doing or omitting to do anything that would otherwise be an infringement of your moral rights, in perpetuity, to the maximum extent permitted by law. If any other person has moral rights in respect of your content, you must ensure that they agree to the above before you submit the content.</p>
<p className="font-inter-light leading-[22px]">While you retain all rights to the User Content, you hereby grant to us (including our employees and Affiliates), a non-exclusive, paid-up, perpetual, and universal right to sell, license, assign, copy, distribute, display, publish, translate, adapt, modify, and otherwise use the User Content for any purpose whatsoever, regardless of the form or medium in which it is used.</p>
<p className="font-inter-light leading-[22px]">We respect the intellectual property rights of others, and we ask you to do the same. In instances where we are notified of alleged infringing Company or User Content through our designated agent, a decision may be made to remove access or disable access to such materials, in compliance with the safe harbor provisions of the United States Digital Millennium Copyright Act, 17 U.S.C. § 512(c) and/or similar laws. We may also make a good faith attempt to contact the person who submitted the affected material so that they may make a counter notification.</p>
<p className="font-inter-light leading-[22px]">If you believe that you or someone else’s copyright has been infringed by Company or User Content provided on this Website, you (or the owner or rights holder, collectively, “Rights Holder”) should send notification to our designated agent immediately. Prior to sending us notice, the Rights Holder may wish to consult a lawyer to determine their rights and legal obligations under the DMCA and any other applicable laws. Nothing here or anywhere on this Website is intended as a substitute for qualified legal advice. To file a Notice of Infringing Material, we ask that the Rights Holder provide the following information:</p>
<ul>
  <li className="font-inter-light leading-[22px]">Reasonably sufficient details about the nature of the copyrighted work in question, or, in the case of multiple alleged infringements, a representative list of such works. This should include, title(s), author(s), any U.S. Copyright Registration number(s), URL(s) etc.;</li>
  <li className="font-inter-light leading-[22px]">Reasonably sufficient details to enable us to identify and locate the material that is allegedly infringing the Rights Holders’ work(s) (for example, file name or URL of the page(s) that contain(s) the material);</li>
  <li className="font-inter-light leading-[22px]">The Rights Holder’s contact information so that we can contact them (including for example, the Rights Holder’s address, telephone number, and email address);</li>
  <li className="font-inter-light leading-[22px]">A statement that the Rights Holder has a good faith belief that the use of the material identified above in 2 is not authorized by the copyright owner, its agent, or the law;</li>
  <li className="font-inter-light leading-[22px]">A statement, under penalty of perjury, that the information in the notification is accurate and that the Rights Holder is authorized to act on behalf of the copyright owner; and</li>
  <li className="font-inter-light leading-[22px]">The Rights Holder’s electronic signature.</li>
</ul>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Notice may be sent to</h2>
<p className="font-inter-light leading-[22px]">Mail: Level 2, Merewether Building H04, The University of Sydney, NSW 2006</p>
<p className="font-inter-light leading-[22px]">Email: <a className="underline" target="_blank" href="mailto:info@eyejackapp.com">info@eyejackapp.com</a></p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Counter-Notification</h2>
<p className="font-inter-light leading-[22px]">If material that you have posted to our Website has been taken down, you may file a counter notification that contains the following details:</p>
<ul>
  <li className="font-inter-light leading-[22px]">Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;</li>
  <li className="font-inter-light leading-[22px]">A statement, under penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material in question;</li>
  <li className="font-inter-light leading-[22px]">Your name, address and telephone number;</li>
  <li className="font-inter-light leading-[22px]">A statement that you consent to the jurisdiction of the federal district courts in Los Angeles, California, and that you will accept service of process from the person who provided notification in compliance with section 512 (c)(1)(C) of the DMCA, or an agent of such person.</li>
  <li className="font-inter-light leading-[22px]">Your physical or electronic signature.</li>
</ul>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Notice may be sent to</h2>
<p className="font-inter-light leading-[22px]">Mail: Level 2, Merewether Building H04, The University of Sydney, NSW 2006</p>
<p className="font-inter-light leading-[22px]">Email: <a className="underline" target="_blank" href="mailto:info@eyejackapp.com">info@eyejackapp.com</a></p>
<p className="font-inter-light leading-[22px]">You also acknowledge and agree that upon receipt of a notice of a claim of copyright infringement, we may temporarily or permanently remove the identified materials from our site without liability to you or any other party.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Confidential Information</h2>
<p className="font-inter-light leading-[22px]">As stated above, all communications sent by you to us will be treated as non-confidential and non-proprietary (subject to our privacy policy). Please do not submit confidential or proprietary information to us (including patentable ideas, new content suggestions or business proposals) unless we have mutually agreed in writing otherwise. Ideas that we receive unsolicited will be treated as property owned by the Company and will not be returned to you.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Our Content</h2>
<p className="font-inter-light leading-[22px]">You may not copy, modify, publish or otherwise reproduce, remove, republish, reproduce, post, transmit, distribute, upload, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying EyeJack Services, or any content on the Website, not uploaded or provided by you, or downloadable in any form or by any means whatsoever without prior written permission from us.</p>
<p className="font-inter-light leading-[22px]">Excluding any content uploaded by users, all content on the EyeJack Services, including without limitation:</p>
<p className="font-inter-light leading-[22px]">1. the registered and unregistered trademarks, service marks and logos contained therein; 2. software, source code, scripts, imagery, graphics, photos, sounds, music, videos, and any other intellectual property;</p>
<p className="font-inter-light leading-[22px]">(collectively, the "Company Content"), are owned by or licensed to the Company and/or our Affiliates, and are subject to copyright and other intellectual property rights under the law. Company Content is provided to you ‘as is’ for your information and personal use only and may not be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any purposes whatsoever without the prior written consent of the respective owners or as permitted in these Terms. The Company reserves all rights not expressly granted in and to the EyeJack Services and the Company Content.</p>
<p className="font-inter-light leading-[22px]">Nothing in these Terms of Use grants you any right to use any trademark, service mark, logo, and/or the name of EyeJack Pty Ltd and/or its Affiliates.</p>
<p className="font-inter-light leading-[22px]">Certain of the ideas, software and processes incorporated into the Services available on this Website are protected by patent applications pending in various jurisdictions, and we intend to prepare and file additional patent applications in selected foreign jurisdictions. Any blog posts or articles published on the EyeJack Services are of a general nature and do not consider your personal circumstances or objectives. Such content should not be regarded as advice and should not be used in place of professional advice.</p>
<p className="font-inter-light leading-[22px]">Company neither endorses nor assumes any liability for any material uploaded or submitted by users on any part of the Website. Although we do not pre-screen, police or monitor comments posted on the Website, if any, we and our agents reserve the right to remove any and all postings that we feel do not comply with these Terms and any other rules of user conduct for our Website, or are otherwise harmful, objectionable, or inaccurate. We are not responsible for any failure or delay in removing such postings.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Infringements</h2>
<p className="font-inter-light leading-[22px]">We may terminate your access to EyeJack Services and/or remove any of your content if we have reason to believe your actions in using EyeJack Services, including any content you upload is deemed inappropriate by EyeJack or infringes the copyright, trademark, or other intellectual property rights of any third party.</p>
<p className="font-inter-light leading-[22px]">In instances separate and apart from the copyright infringement protocols as set forth in the Ownership of Your Content and License of Your Content section, above, if you believe that works in which you retain intellectual property rights have been copied, uploaded to, and made available via the EyeJack Services in a way that constitutes infringement, please notify us immediately, and provide the following details:</p>
<p className="font-inter-light leading-[22px]">(i) the details of the copyright owner;</p>
<p className="font-inter-light leading-[22px]">(ii) a description of the copyrighted work that you claim has been infringed;</p>
<p className="font-inter-light leading-[22px]">(iii) a description of what material you believe to infringe your rights and where that material is located within EyeJack Services;</p>
<p className="font-inter-light leading-[22px]">(iv) your address, telephone number and email address;</p>
<p className="font-inter-light leading-[22px]">(v) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
<p className="font-inter-light leading-[22px]">(vi) a statement by you, in the form of a statutory declaration, that the above information in your notice is accurate and that you are the copyright owner or authorised to act on the copyright owner’s behalf.</p>
<p className="font-inter-light leading-[22px]">We will investigate any complaints received and take actions that we deem appropriate.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Third Party Links</h2>
<p className="font-inter-light leading-[22px]">EyeJack Services may display links to third party websites, resources, products, offerings and services not housed within the EyeJack Services and/or managed or controlled by us (“Third Party Links”). We assume no responsibility for Third Party Links, and you are solely responsible for and assume all risk arising from your use, access, or reliance of any Third Party Links.</p>
<p className="font-inter-light leading-[22px]">Specifically but without limitation, we shall not be responsible or liable for, to the maximum extent permitted by law:</p>
<p className="font-inter-light leading-[22px]">(i) the availability, accuracy, quality or reliability of such Third Party Links; or (ii) the information, products, or services available on or through such Third Party Links.</p>
<p className="font-inter-light leading-[22px]">Existence of any Third Party Link, including third party websites you could use to offer your content for sale or license, should not be interpreted as, and does not constitute, an endorsement by us of that Third Party Links, its publisher, or anything relating thereto.</p>
<p className="font-inter-light leading-[22px]">While we make every effort to work with trusted, reputable providers, from time to time such sites may contain information, material or policies that some may find inappropriate or personally objectionable. You understand that we are not responsible for the accuracy, completeness, decency or legality of content hosted by third party websites, the information we receive from third party websites, data providers, and other informational resources, nor are we responsible for errors or omissions in any references made on those websites, data providers, and/or other informational resources. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with the Website or party by us, or any warranty of any kind, either express or implied. From time to time, this Site may include advertisements offered by third parties. You may enter into correspondence with or participate in promotions of the advertisers showing their products on this site. Any such correspondence or promotions, including the delivery of and the payment for goods and services by those third parties, and any other terms, conditions, warranties or representations associated therewith, are solely between you and the advertiser. We assume no liability, obligation or responsibility for any part of any such correspondence or promotion.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Termination</h2>
<p className="font-inter-light leading-[22px]">We may suspend, disable or terminate your access to your Account and/or Subscription Plan and the EyeJack Services or any part of the same, at our sole discretion and without prior notice to you, including where you violate these Terms. To the extent permitted by law, we will not be liable to you or any third party for termination of your access or use. Suspension or termination will not affect any of your obligations under these Terms (including, without limitation, ownership, confidentiality, indemnification and limitation of liability), which by their context are intended to survive such suspension or termination.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">No Warranty</h2>
<p className="font-inter-light leading-[22px]">Your access and use of the EyeJack Services is at your sole discretion and risk. To the maximum extent permitted by law, we will not be responsible for any damage or harm to any computer or mobile device, loss of data or other harm that results from your use of EyeJack Services. EyeJack Services are provided “as available” and “as is”. We do not warrant that any portion of the EyeJack Services will meet your needs or requirements, will be secure, will operate without interruption or will be error free. No advice or information, whether oral or written, obtained by you from us or any of our affiliates, agents, partners, employees, successors, or assigns, will create any warranty not expressly stated in these Terms.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Disclaimer</h2>
<p className="font-inter-light leading-[22px]">ALL CONTENT AND SERVICES ON THIS WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE ARE MADE OR GIVEN, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, MERCHANTABLE QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT, PARTICULAR, SPECIFIC, OR OTHERWISE, OR WARRANTIES FOR INFORMATION ACCURACY, INTEGRATION, OR ENJOYMENT, PARTICULAR, SPECIFIC OR OTHERWISE. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (A) THE CONTENT OR SERVICES WILL MEET YOUR REQUIREMENTS, (B) THE CONTENT, SERVICES OR WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE CONTENT OR SERVICES OFFERED WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY CONTENT OR SERVICES PURCHASED OR OBTAINED BY YOU FROM THE SITE FROM US OR OUR AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.</p>
<p className="font-inter-light leading-[22px]">THIS WEBSITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE CONTENT AND SERVICES AT THIS WEBSITE, INCLUDING THE PRICES AND DESCRIPTIONS OF ANY PRODUCTS OR SERVICES LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE CONTENT OR SERVICES AVAILABLE AT THIS SITE MAY BE OUT OF DATE, AND WE MAKE NO COMMITMENT TO UPDATE SUCH CONTENT OR SERVICES.</p>
<p className="font-inter-light leading-[22px]">THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY SERVICES OR CONTENT THROUGH THIS WEBSITE IS DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.</p>
<p className="font-inter-light leading-[22px]">Through your use of the Website, you may have the opportunities to engage in commercial transactions with other users and vendors. You acknowledge that all transactions relating to any products or services provided by any third party, including, but not limited to the purchase terms, payment terms, warranties, guarantees relating to such transactions, are agreed to solely between the seller of such merchandise and you.</p>
<p className="font-inter-light leading-[22px]">WE MAKE NO WARRANTY REGARDING ANY TRANSACTIONS EXECUTED THROUGH A THIRD PARTY, OR IN CONNECTION WITH THIS SITE, AND YOU UNDERSTAND AND AGREE THAT SUCH TRANSACTIONS ARE CONDUCTED ENTIRELY AT YOUR OWN RISK. ANY WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY OFFERINGS OR CONTENT AVAILABLE ON OR THROUGH THIS WEBSITE FROM A THIRD PARTY IS PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR ANY OTHER OF OUR AFFILIATES.</p>
<p className="font-inter-light leading-[22px]">WE RESERVE THE SOLE RIGHT TO EITHER MODIFY OR DISCONTINUE THE WEBSITE, INCLUDING ANY OFFERINGS OR FEATURES THEREIN, AT ANY TIME WITH OR WITHOUT NOTICE TO YOU. WE SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY SHOULD WE EXERCISE SUCH RIGHT. MODIFICATIONS MAY INCLUDE, BUT ARE NOT LIMITED TO, CHANGES IN THE PRICING STRUCTURE, THE ADDITION OF FREE OR FEE-BASED SERVICES, OR CHANGES TO LIMITATIONS ON ALLOWABLE CONTENT, FILE SIZES OR FILE TYPES. ANY NEW FEATURES THAT AUGMENT OR ENHANCE THE THEN-CURRENT OFFERINGS ON THIS WEBSITE SHALL ALSO BE SUBJECT TO THESE TERMS OF USE. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. PLEASE CONSULT THE LAWS IN YOUR JURISDICTION.</p>
<p className="font-inter-light leading-[22px]">IN NO EVENT SHALL WE OR ANY AND ALL OF OUR PARENTS, SUBSIDIARIES, AFFILIATES, PARTNERS, MEMBERS, SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, INDEPENDENT CONTRACTORS, LICENSEES, ASSIGNEES, BENEFICIARIES, AND HEIRS (“RELEASEES”) BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR LOSSES OF ANY KIND WHATSOEVER WITH RESPECT TO THE SERVICE, THE WEBSITE, LOSS OF USE, DATA OR PROFIT LOSS, WHETHER OR NOT THE PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, AND WHETHER DUE TO INACCURACY, ERROR, OMISSION, OR ANY AND ALL OTHER REASONS WHATSOEVER, WHETHER FORESEEABLE OR UNFORESEEABLE. UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE AND ANY AND ALL OTHER LEGAL BASES CAPABLE OF BEING LIMITED, SHALL THE RELEASEES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES OR LOSSES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE WEBSITE. THE RIGHT WITH RESPECT TO ANY DISSATISFACTION WITH THE WEBSITE SHALL BE FOR YOU AND ANYONE ACTING ON YOUR BEHALF TO TERMINATE THE USE OF THE WEBSITE.</p>
<p className="font-inter-light leading-[22px]">WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE RELEASEES ARE NOT RESPONSIBLE FOR AND EXPRESSLY DISCLAIM ALL LIABILITY FOR DAMAGES OF ANY KIND ARISING OUT OF USE, REFERENCE TO, OR RELIANCE ON ANY INFORMATION APPEARING ON THIS WEBSITE AND/OR IN THE SERVICES.</p>
<p className="font-inter-light leading-[22px]">WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, UNDER NO CIRCUMSTANCES WILL THE RELEASEES BE LIABLE FOR ANY AND ALL BREACHES OF ANY AND ALL FEDERAL, STATE, LOCAL AND/OR COUNTRY STATUES, REGULATIONS, AND/OR LAWS THAT MAY OCCUR AS A RESULT OF AN INDIVIDUAL'S USAGE OF THIS WEBSITE, OR OF ANY OTHER THIRD-PARTY WEBSITE. IT IS YOUR RESPONSIBILITY TO COMPLY WITH ANY AND ALL FEDERAL, STATE, LOCAL AND/OR COUNTRY STATUES, REGULATIONS, AND/OR LAWS THAT MAY GOVERN YOUR USAGE OF THIS WEBSITE.</p>
<p className="font-inter-light leading-[22px]">Any litigation arising out of using the Website shall be held exclusively in an appropriate court in Australia, and under Australian law, without regard to choice of law provisions, and, by your usage of this Website you expressly consent to such jurisdiction and venue without objection, including, without limitation to forum non convenient, and any and all other grounds, and are hereby barred from raising such objections to jurisdiction and/or venue.</p>
<p className="font-inter-light leading-[22px]">THE INFORMATION PROVIDED ON THIS WEBSITE AND IN THE APPS IS BEING PROVIDED FOR PERSONAL USE AND ENJOYMENT, AND THE USE OF THE WEBSITE AND/OR APPLICATIONS IS AT THE USER’S OWN RISK. WE CANNOT RULE OUT THE POSSIBILITY OF ERROR, AND WE WILL NOT BE HELD LIABLE IN THE UNFORTUNATE CIRCUMSTANCE THAT SUCH AN ERROR ARISES.</p>
<p className="font-inter-light leading-[22px]">By using this Website, you explicitly confirm and agree that you fully accept all the terms and conditions of this disclaimer. You agree to defend, indemnify, and hold us and the Releasees harmless from all liabilities, claims, and expenses, including attorneys’ fees that may arise from your use or misuse of this Website. We, and any and all other Releasees, expressly reserve the right, at your sole expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.</p>
<p className="font-inter-light leading-[22px]">If any part of these terms are held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Limitation on Liability and Indemnification</h2>
<p className="font-inter-light leading-[22px]">Your exclusive remedy and our entire liability, if any, for any claims arising out of these Terms and your use of this Website shall be limited to the amount you paid us for Services purchased on the Website during the one (1) month period before the act giving rise to the liability.</p>
<p className="font-inter-light leading-[22px]">IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA OR PROFIT LOSS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS SITE OR OF ANY WEBSITE REFERENCED OR LINKED TO FROM THIS SITE. FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY PROMISES REGARDING OUR SERVICES OR CONTENT OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS WITH THE THIRD PARTY THROUGH THIS SITE, INCLUDING WITHOUT LIMITATION THE PROCESSING OF ORDERS. SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. NOTWITHSTANDING THE FOREGOING, ALL LIABILITY LIMITATIONS AVAILABLE TO GOALDENVIEW SHALL BE TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION, AND IN THE EVENT THAT GOALDENVIEW’S LIABILITY LIMITATIONS ARE NOT PERMISSIBLE IN SUCH APPLICABLE JURISDICTION, SUCH LIABILITY LIMITATION SHALL, TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT BE IN A SUM GREATER THAN THE SUBSCRIPTION FEES PAID BY YOU TO GOALDENVIEW. PLEASE CONSULT THE LAWS IN YOUR JURISDICTION.</p>
<p className="font-inter-light leading-[22px]">You agree to defend, indemnify, and hold us and our Affiliates harmless from all liabilities, claims, and expenses, including attorneys’ fees that may arise from your use or misuse of this Website. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.</p>
<p className="font-inter-light leading-[22px]">To the extent permitted by law and subject to the rights you may have under the Australian Consumer Law (as enshrined within Schedule 2 of the Competition and Consumer Act 2010 (Cth) (“Australian Consumer Law”) and any other applicable laws that cannot be excluded, in no event shall the Company, its officers, directors, employees, or agents, be liable to you for any direct, indirect, incidental, special, punitive, or consequential damages or loss whatsoever resulting from:</p>
<p className="font-inter-light leading-[22px]">1. Any errors, mistakes, omissions or inaccuracies of any content;</p>
<p className="font-inter-light leading-[22px]">2. personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the EyeJack Services, the EyeJack Services or products of the Company or advertisers on the EyeJackServices;</p>
<p className="font-inter-light leading-[22px]">3. any unauthorised access to or use of our secure servers and/or any and all personal information and/or financial information stored therein;</p>
<p className="font-inter-light leading-[22px]">4. user submissions or the defamatory, offensive, or illegal conduct of any third party, including users;</p>
<p className="font-inter-light leading-[22px]">5. any interruption or cessation of transmission to or from the EyeJack Services, and/or</p>
<p className="font-inter-light leading-[22px]">6. any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the EyeJack Services, whether based on warranty, contract, tort, or any other legal theory, and whether or not the Company is advised of the possibility of such damages.</p>
<p className="font-inter-light leading-[22px]">While the foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction, where conditions, warranties or other rights are implied into terms or otherwise conferred by law, and it is not lawful or possible to exclude them, then those conditions, warranties or other rights will (but only to the extent required by law) apply to these Terms. Subject to the rights you may have under the Australian Consumer Law and any other applicable laws that cannot be excluded, at the option of the Company, our liability for breach of implied conditions or warranties is limited, to the extent permitted by law to, if the breach relates to goods, (i) the replacement of the goods or the supply of equivalent goods, (ii) the repair of the goods, (iii) the payment of the cost of replacing the goods or of acquiring equivalent goods, or (iv) the payment of having the goods repaired and, if the breach relates to services, (i) the supply of the services again; or (ii) the payment of the cost of having the services supplied again. For clarity, nothing in these Terms purports to limit the application of any statutory consumer warranties or rights.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Indemnity</h2>
<p className="font-inter-light leading-[22px]">To the maximum extent permitted by law, you agree to defend, indemnify and hold harmless the Company, its officers, directors, employees and agents, successors and assigns from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from or connected with: (i) your use of and access to the EyeJack Services, including the functionalities and services provided therein; (ii) your violation of these Terms; (iii) your violation of any third party right, including without limitation any copyright, intellectual property, real property or privacy right; or (iv) any claim that one of your submissions to the EyeJack Services or content uploaded or otherwise supplied by you caused damage to a third party. This defense and indemnification obligation will survive these Terms and your use of the EyeJack Services.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Confidentiality</h2>
<p className="font-inter-light leading-[22px]">Except as expressly and unambiguously permitted hereunder, you agree to hold in confidence and not use or disclose any materials or information disclosed by us, our affiliates, or our licensors, that are confidential or proprietary or which may be reasonably regarded as the confidential information or notified to you to be confidential information (“Confidential Information“). You shall treat as confidential all Confidential Information provided to you and must not disclose it to any other person or in any way not authorised by us. Confidential Information will not include any materials or information that:</p>
<p className="font-inter-light leading-[22px]">1. is now, or later becomes, through no act or failure to act on your part, generally known or available to the public;</p>
<p className="font-inter-light leading-[22px]">2. is known by you at the time of disclosure as evidenced by written record, without obligation of confidentiality;</p>
<p className="font-inter-light leading-[22px]">3. is furnished to you by a third party, as a matter of right and without restriction on disclosure;</p>
<p className="font-inter-light leading-[22px]">4. is independently developed by you without any breach of these Terms;</p>
<p className="font-inter-light leading-[22px]">5. is the subject of an express written permission to disclose provided by us; or</p>
<p className="font-inter-light leading-[22px]">6. is disclosed in response to a valid order of a court or other governmental body or as required by law.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Mobile Application</h2>
<p className="font-inter-light leading-[22px]">The EyeJack mobile application (“Application”) will be available via the Apple App Store and the Google Play Store, for download to compatible devices. When you download the Application, you are granted a non-transferable licence to use the Application on your device, in the way intended and provided for within the normal functionality of the Application only.</p>
<p className="font-inter-light leading-[22px]">You acknowledge that we may from time to time issue upgraded versions of the Application, and may automatically electronically upgrade the version of the Application that you are using on your compatible device. You consent to such automatic upgrading on your mobile device, and agree that these Terms will apply to all such upgrades.</p>
<p className="font-inter-light leading-[22px]">The following additional terms and conditions apply to any Application that is available and downloaded via an Apple iOS-powered mobile device (an “iOS App”):</p>
<ul>
  <li className="font-inter-light leading-[22px]">Your use of the iOS App must comply with Apple’s App Store Terms of Service.</li>
  <li className="font-inter-light leading-[22px]">You acknowledge that these Terms are between you and the Company only, and not with Apple, Inc. (“Apple”). The Company, and not Apple, is responsible for the iOS App. You acknowledge that Apple has no obligation to provide maintenance and support services with respect to the iOS App. To the maximum extent permitted by law, Apple will have no warranty obligation whatsoever with respect to the iOS App.</li>
  <li className="font-inter-light leading-[22px]">You agree that the Company, and not Apple, is responsible for addressing any claims by you or any third party relating to the iOS App or your possession and/or use of the iOS App, including, but not limited to: (i) product liability claims; (ii) any claim that the iOS App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection laws or similar legislation, and all such claims are governed solely by these Terms and any law applicable to the Company as the provider of the iOS App.</li>
  <li className="font-inter-light leading-[22px]">You agree that the Company, and not Apple, shall be responsible, to the extent required by these Terms, for the investigation, defense, settlement and discharge of any third-party intellectual property infringement claim related to the iOS App or your download and use of the iOS App.</li>
  <li className="font-inter-light leading-[22px]">You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</li>
  <li className="font-inter-light leading-[22px]">You agree to comply with all applicable third-party terms of agreement when using the iOS App).</li>
  <li className="font-inter-light leading-[22px]">You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</li>
  <li className="font-inter-light leading-[22px]">The parties agree that Apple and Apple’s subsidiaries are third-party beneficiaries to these Terms and Conditions as they relate to your license and use of the iOS App. Upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as they relate to your license of the iOS App as a third-party beneficiary thereof.</li>
</ul>
<p className="font-inter-light leading-[22px]">The following additional terms and conditions apply with respect to any Application that the Company may provide to you designed for use on an Android-powered mobile device (an “Android App”):</p>
<ul>
  <li className="font-inter-light leading-[22px]">You acknowledge that these Terms and Conditions are between you and the Company only, and not with Google, Inc. (“Google”).</li>
  <li className="font-inter-light leading-[22px]">Your use of the Android App must comply with Google’s then-current Google Play Terms of Service.</li>
</ul>
<p className="font-inter-light leading-[22px]">Google is only a provider of the Android Market where you obtained the Android App. The Company, and not Google, is solely responsible for the Android App and the Services. Google has no obligation or liability to you with respect to the Android App or these Terms.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Download Packs</h2>
<ul>
  <li className="font-inter-light leading-[22px]">If you hold an Account and use the EyeJack Services via the EyeJack Creator iOS or Android App, you have the option to allow other users to view and download content that you create by purchasing a download pack which allows your content to be shared to a set number of downloads (“Download Pack”).</li>
  <li className="font-inter-light leading-[22px]">The following additional terms and conditions apply to users who purchase a Download Pack</li>
  <li className="font-inter-light leading-[22px]">you must have an Apple iTunes account or a Google account and the EyeJack Creator iOS or Android App;</li>
  <li className="font-inter-light leading-[22px]">payment is made solely via an Apple iTunes account or a Google account as applicable and in accordance with and governed by the Apple iTunes terms and conditions and the Google Play terms of service. The Company is not responsible for any purchases made via an Apple iTunes account or Google account and is not liable for any loss or damage you may suffer as a result of using the Apple iTunes or Google payment gateway;</li>
  <li className="font-inter-light leading-[22px]">fees and charges for each Download Pack will be billed to you automatically via the set payment method specified in your iTunes or Google account;</li>
  <li className="font-inter-light leading-[22px]">the full fees and charges for each Download Pack will apply regardless of whether or not users download your content and there is no reimbursement, credit or refund for any unused or remaining downloads;</li>
  <li className="font-inter-light leading-[22px]">the fees and charges for each Download Pack are as set out in the EyeJack Creator App in Australian Dollars (AUD) and the Company reserves the right to vary the fees and charges for the Download Packs from time to time; and</li>
  <li className="font-inter-light leading-[22px]">a Download Pack will expire when the number of downloads in the Download Pack has been exhausted. A subsequent Download Pack will need to be purchased to allow users to continue viewing your content.</li>
</ul>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Governing Law</h2>
<p className="font-inter-light leading-[22px]">These Terms and Conditions shall be governed by and construed in accordance with the laws of New South Wales, Australia. You irrevocably and unconditionally agree to submit to the exclusive jurisdiction of the courts of New South Wales, Australia, and the courts that may hear appeals from them.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">International Use</h2>
<p className="font-inter-light leading-[22px]">Although this Website may be accessible worldwide, we make no representation that materials on this Website are appropriate or available for use in locations outside the United States, European Union, Australia or Great Britain. Those who choose to access this Website from other locations do so on their own initiative and at their own risk. If you choose to access this Website from outside the United States, European Union, Australia or Great Britain, you are responsible for compliance with local laws in your jurisdiction, including but not limited to, the taxation of products purchased over the Internet. Any offer for any product, Service, and/or information made in connection with this Website is void where prohibited.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">No Resale Right</h2>
<p className="font-inter-light leading-[22px]">You agree not to sell, resell, reproduce, duplicate, distribute, copy or use for any commercial purposes any portion of this Website, or use of or access to this Website or Services provided through this Website, beyond the limited rights granted to you under these Terms.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Force Majeure</h2>
<p className="font-inter-light leading-[22px]">In addition to any excuse provided by applicable law, we shall be excused from liability for non-delivery or delay in delivery of products and Services available through our Website arising from any event beyond our reasonable control, whether or not foreseeable by either party, including but not limited to: labor disturbance, war, act of public enemy, terrorist attack, fire, epidemic or pandemic, accident, adverse weather, inability to secure transportation, governmental act or regulation, and any and all other causes or events beyond our reasonable control, whether or not similar to those which are enumerated above.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Entire Agreement</h2>
<p className="font-inter-light leading-[22px]">These Terms and the Privacy Policy constitute the entire agreement and understanding between the parties concerning the subject matter hereof and supersedes all prior agreements and understandings of the parties with respect thereto. These Terms may NOT be altered, supplemented, or amended by the use of any other document(s) not executed or otherwise modified, changed, updated, deleted, or added to by Company. To the extent that anything in or associated with this Website is in conflict or inconsistent with these Terms, these Terms shall take precedence.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">General</h2>
<p className="font-inter-light leading-[22px]">These Terms, together with the Privacy Policy and any other terms and conditions and legal notices published by the Company on the EyeJack Services, shall constitute the entire agreement between you and the Company concerning the EyeJack Services. If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and the Company's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision. The Company reserves the right to amend these Terms at any time and without notice, and it is your responsibility to review these Terms for any changes. Your use of the EyeJack Services following any amendment of these Terms will signify your assent to and acceptance of its revised terms. The Company reserves the right to take appropriate legal action, where it deems necessary, against any person that breaches these Terms. The term “EyeJack Services” shall be inclusive of the term “Services” in all instances in which it appears in these Terms.</p>

<h2 className="font-secondary-sans font-bold uppercase text-[33px] leading-8 pb-[20px]">Contact</h2>
<p className="font-inter-light leading-[22px]">If you have any questions or complaints, you can contact us via email at <a className="underline" target="_blank" href="mailto:info@eyejackapp.com">info@eyejackapp.com</a></p>

        </>
    );
}

export {TermsAndConditions};