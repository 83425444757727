import { clsx } from 'clsx';
import { ModalOverlay } from './ModalOverlay';
import { ReactNode } from 'preact/compat';

type ModalProps = {
    width?: string;
    height?: string;
    className?: string;
    children: ReactNode;
};

export const Modal = ({
    className,
    children,
}: ModalProps) => {
    
    return (
        <ModalOverlay>
        <div
            className={clsx(
                'absolute bg-cover z-50 overflow-hidden w-[90%] h-[90%] rounded-xl centered',
                className,
            )}
            data-cmp="Modal"
        >
         {children}
        </div>
        </ModalOverlay>
    );
};
