import { EJApp } from "../utils/utils";
import GeolocationBase from "./geolocation.base";
import GeolocationApp from "./geolocation.app";
import GeolocationWeb from "./geolocation.web";

export default class Geolocation extends GeolocationBase {
  private impl: GeolocationBase;
  
  constructor() {
    super();

    if( EJApp ) {
      this.impl = new GeolocationApp();
    } else {
      this.impl = new GeolocationWeb();
    }    
  }

  public override async getCurrentPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<void> {
    return this.impl.getCurrentPosition(successCallback, errorCallback, options);
  }

  public override async watchPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<number> {
    return this.impl.watchPosition(successCallback, errorCallback, options);
  }

  public override async clearWatch(watchId: number): Promise<void> {
    return this.impl.clearWatch(watchId);
  }
}