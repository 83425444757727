import DyingSwan from '@/projects/lincolncenter/assets/artworks/dying-swan.webp'
import NimuraCatDance from '@/projects/lincolncenter/assets/artworks/nimura-cat-dance.webp'
import TapHappening from '@/projects/lincolncenter/assets/artworks/tap-happening.webp'
import Herstory from '@/projects/lincolncenter/assets/artworks/herstory.webp'
import Annabelle from '@/projects/lincolncenter/assets/artworks/annabelle.webp'
import RobbinsRoofTop from '@/projects/lincolncenter/assets/artworks/robbins-roof-top.webp'
import Indrani from '@/projects/lincolncenter/assets/artworks/indrani.webp'
import Breakers from '@/projects/lincolncenter/assets/artworks/breakers.webp'

import DyingSwanLocked from '@/projects/lincolncenter/assets/artworks/dying-swan-locked.webp'
import NimuraCatDanceLocked from '@/projects/lincolncenter/assets/artworks/nimura-cat-dance-locked.webp'
import TapHappeningLocked from '@/projects/lincolncenter/assets/artworks/tap-happening-locked.webp'
import AnnabelleLocked from '@/projects/lincolncenter/assets/artworks/annabelle-locked.webp'
import HerstoryLocked from '@/projects/lincolncenter/assets/artworks/herstory-locked.webp'
import RobbinsRoofTopLocked from '@/projects/lincolncenter/assets/artworks/robbins-roof-top-locked.webp'
import IndraniLocked from '@/projects/lincolncenter/assets/artworks/indrani-locked.webp'
import BreakersLocked from '@/projects/lincolncenter/assets/artworks/breakers-locked.webp'

import DyingSwanUnlocked from '@/projects/lincolncenter/assets/artworks/dying-swan-unlocked.webp'
import NimuraCatDanceUnlocked from '@/projects/lincolncenter/assets/artworks/nimura-cat-dance-unlocked.webp'
import TapHappeningUnlocked from '@/projects/lincolncenter/assets/artworks/tap-happening-unlocked.webp'
import AnnabelleUnlocked from '@/projects/lincolncenter/assets/artworks/annabelle-unlocked.webp'
import HerstoryUnlocked from '@/projects/lincolncenter/assets/artworks/herstory-unlocked.webp'
import RobbinsRoofTopUnlocked from '@/projects/lincolncenter/assets/artworks/robbins-roof-top-unlocked.webp'
import IndraniUnlocked from '@/projects/lincolncenter/assets/artworks/indrani-unlocked.webp'
import BreakersUnlocked from '@/projects/lincolncenter/assets/artworks/breakers-unlocked.webp'

export interface Artwork {
  id: string;
  title: string;
  location: string;
  sound: string;
  artistName: string;
  artistNames: string;
  danceTitle: string;
  producer?: string;
  choreo?: string;
  music?: string;
  musicians?: string;
  musicComposed?: string;
  date?: string;
  summary: string;
  link: string;
  found: boolean;
  thumbnail: string;
  thumbnailLocked: string;
  thumbnailUnlocked: string;
}

export const artworks = [
    {
        id: 'ballet',
        name: 'The Dying Swan',
        title: 'Pavlova Dying Swan',
        location: 'MET or Alt Location: could be at the Milstein pool,emphasizing her relationship with the swans.',
        sound: 'video includes sound',
        artistName: 'Anna Pavlova',
        danceTitle: 'The Dying Swan',
        choreo: 'Mikhail Fokine',
        music: 'Camille Saint-Saëns',
        date: '1924',
        summary: 'Anna Pavlova (1881-1931), perhaps the world’s most famous ballerina, performed this short work thousands of times. Indeed, legend has it that her last words were “bring me my swan costume.” Although most ballet artists were skeptical about the then burgeoning silent film industry, Pavlova was an early adapter and luckily, her most famous role was filmed in Hollywood in the 1920s. Jerome Robbins Dance Division, Call Number: *MGZHB 12-1029.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: DyingSwan,
        thumbnailLocked: DyingSwanLocked,
        thumbnailUnlocked: DyingSwanUnlocked,
        found: false,
      },
      {
        id: 'catdance',
        name: 'Nimura Cat Dance',
        title: 'Nimura Cat Dance',
        location: 'David Koch Theatre (Right side, vertical middle)',
        sound: 'Silent',
        artistName: 'Yeichi Nimura',
        danceTitle: 'Nimura dances with his cat',
        summary: 'Yeichi Nimura (1897-1979) was born in Japan and came to America in 1918 where he trained at the Denishawn School. A charismatic performer, Nimura’s own choreography combined elements of ballet, modern dance and martial arts resulting in a distinctively unique style. He was a revered teacher, running the Ballet Arts School at Carnegie Hall from 1940 until his death and he counted many famous dancers including Gemze De Lappe and Diana Adams among his students. He performs here with his cat (who was not harmed in the making of this picture). Jerome Robbins Dance Division, Call Number *MGZIDVD 5-4751.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: NimuraCatDance,
        thumbnailLocked: NimuraCatDanceLocked,
        thumbnailUnlocked: NimuraCatDanceUnlocked,
        found: false,
      },
      {
        id: 'taphappenings',
        name: 'Tap Happening',
        title: 'Tap Happening',
        location: 'Geffen Hall',
        sound: 'video includes sound',
        artistName: 'Lon Chaney, Howard “Sandman” Sims, Rhythm Red, Chuck Green, Jerry Ames, Raymond Kaalund, Jimmy Slyde, and Bert “Gip” Gibson.',
        danceTitle: 'Tap Happening',
        date: '1969',
        summary: 'In 1969, producer and tap dance preservationist Leticia Jay took over the Bert Wheeler Theatre in New York City on Monday nights to present near forgotten hoofers from tap’s golden age, ushering in a whole new audience for percussive dance. A performance was filmed by the Jerome Robbins Dance Division on May 12, 1969, now the only remaining trace of these legendary jam sessions. Filmed by Eugene Marner. Jerome Robbins Dance Division Original Documentation. Call Number:  *MGZHB 16-151.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: TapHappening,
        thumbnailLocked: TapHappeningLocked,
        thumbnailUnlocked: TapHappeningUnlocked,
        found: false,
      },
      {
        id: 'govisland',
        name: "Herstory",
        title: 'Herstory of the Universe',
        location: 'Grassy knoll as an island, or floating above the Milstein.',
        sound: 'video includes sound',
        artistName: 'Robyn Cascio',
        danceTitle: 'Herstory of the Universe@Governers Island: Ascent - Scramble',
        choreo: 'Richard Move/MoveOpolis!',
        summary: 'This excerpt from a series of site-specific performances on Governors Island was filmed in 2021, at the height of the COVID epidemic. The immersive audience experience was designed to honor the resiliency of the earth and highlight the island’s nascent, thriving ecosystems. Recorded by Nel Shelby Productions. Jerome Robbins Dance Division Original Documentation, Call Number *MGZIDF 10715.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: Herstory,
        thumbnailLocked: HerstoryLocked,
        thumbnailUnlocked: HerstoryUnlocked,
        found: false,
      },
      {
        id: 'annabelle',
        name: "Annabelle",
        title: 'Annabelle',
        location: 'Grassy hill.',
        sound: 'Music composed and performed by Ben Model. Music and recording copyright @2025 by Undercrank Productions LLC ',
        artistName: 'Annabelle Whitford',
        producer: 'Thomas Edison',
        summary: 'Annabella is the oldest film in the Dance Division. The dancer, Annabelle Whitford, specialized in the serpentine dance, created and made famous by Loïe Fuller. Fuller, also known as “the goddess of light,” invented phosphorescent fabric and colored lighting gels which were very innovative theatrical effects in the late 1800s. The film was hand-painted by the filmmaker Thomas Edison to evoke Fuller’s presence on stage by suggesting the effect light would have on the flowing fabric of the dancer’s costume. Jerome Robbins Dance Division, Call Number *MGZHB 2-1323.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: Annabelle,
        thumbnailLocked: AnnabelleLocked,
        thumbnailUnlocked: AnnabelleUnlocked,
        found: false,
      },
      {
        id: 'rooftop',
        name: 'Robbins Roof Top',
        title: 'Robbins Roof Top',
        location: 'David Koch Theatre (top, middle)',
        sound: 'Dance Oral History Project clip',
        artistName: 'Jerome Robbins',
        danceTitle: 'Jerome Robbins’ Home Movies',
        musicComposed: 'Music composed and performed by Ben Model. Music and recording copyright @2025 by Undercrank Productions LLC',
        summary: 'The Dance Division owes much to Jerome Robbins (1919-1998) who, throughout his lifetime supported the mission to save dance both through financial contributions and advocacy. Due to his decades of unwavering support, the Division was named in his honor in 1999. Robbins is shown here improvising with his parents in the 1940s, on the roof of the family home in Weehawken, New Jersey. After a career as a dancer with Ballet Theatre (now ABT) Robbins went on to choreograph for stage and screen, notably for New York City Ballet and on Broadway. His hit shows include Fiddler on the Roof and West Side Story. Jerome Robbins Dance Division, Call Number *MGZHB 16-2740.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: RobbinsRoofTop,
        thumbnailLocked: RobbinsRoofTopLocked,
        thumbnailUnlocked: RobbinsRoofTopUnlocked,
        found: false,
      },
      {
        id: 'idrani',
        name: 'Indrani',
        title: 'Indrani',
        location: 'Towards the bridge that connects the LC plaza with Julliard.',
        sound: 'video includes sound',
        artistName: 'Indrani',
        danceTitle: 'Pallavi, from Orissi Suite',
        musicians: 'Visalam Venkatachalam (vocal) and Shekharan (percussion).',
        summary: 'Indrani Rahman (1930-1999) was an Indian classical dancer of Bharata Natyam, Kuchipudi, Kathakali, and Odissi, the style you see here. She was the first professional dancer to revive this long neglected style of dance, which was virtually unknown outside of Odisha until Indrani performed it in Delhi in 1957. She subsequently performed it all over the world. In the latter decades of her life Indrani served on the faculty of Juilliard’s Dance Division. This video was recorded in 1975 and produced by The Dance Workshop, WGBH/Boston for the Jerome Robbins Dance Division, Call Number *MGZIC 9-612.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: Indrani,
        thumbnailLocked: IndraniLocked,
        thumbnailUnlocked: IndraniUnlocked,
        found: false,
      },
      {
        id: 'breakers',
        name: 'Breakers',
        title: 'Breakers',
        location: 'David Koch Theatre',
        sound: 'Music produced by Good Grooves in 2024/Dance Oral History Project clip',
        artistName: 'Rock Steady Crew & The Dynamic Rockers',
        danceTitle: 'Lincoln Center Battle',
        summary: 'Battle between the Rock Steady Crew and the Dynamic Rockers at the Lincoln Center Out-of-Doors Festival, 1981. Dubbed “the Battle of Boroughs,” break dance rivals Rock Steady Crew from the Bronx and the Dynamic Rockers of Queens came together at Lincoln Center’s Out-of-Doors Festival in 1981. Michael Holman, who shot the film, recalls, “I remember the atmosphere. There was a lot of excitement in the air. This was unusual in that this was a public, daytime, out in the open battle that never happened before, and probably has never happened like that since.” Michael Holman collection, Jerome Robbins Dance Division, Call Number *MGZMD 423.',
        link: 'https://digitalcollections.nypl.org',
        thumbnail: Breakers,
        thumbnailLocked: BreakersLocked,
        thumbnailUnlocked: BreakersUnlocked,
        found: false,
      },
]