import { useApp } from "@/ui/hooks/useApp";
import emitter from "@/core/events/eventemitter";
import { RecordingState } from "@/ui/types/RecordingState";
import { AppState } from "@/ui/types/AppState";
import { useEffect, useState } from "preact/hooks";

const RecordingButton = () => {
  const { recordingState, setRecordingState, setAppState, appState } = useApp();

  const [progress, setProgress] = useState(0);
  const handleClick = () => {
    if (recordingState === RecordingState.NONE) {
      setAppState(AppState.RECORDING);
      setTimeout(() => {
        emitter.emit("record-start")
      }, 600);
    } else if (recordingState === RecordingState.RECORDING) {
      emitter.emit("record-stop")

    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (recordingState === RecordingState.RECORDING) {
      const startTime = Date.now();
      interval = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const percentage = (elapsed / 30000) * 100;
        if (percentage >= 100) {
          setProgress(100);
          emitter.emit("record-stop")
          clearInterval(interval);
        } else {
          setProgress(percentage);
        }
      }, 100);
    } else {
      setProgress(0);
    }

    return () => clearInterval(interval);
  }, [recordingState, setRecordingState]);


  return (
    <div className="flex items-center justify-center xh-screen">
      <div className="relative flex items-center justify-center w-16 h-16">
        <button
          onClick={handleClick}
          className={`relative flex items-center justify-center bg-black bg-opacity-50 rounded-full border-2 border-white
          ${
            appState === AppState.RECORDING
              ? "w-8 h-8 -bottom-12"
              : "w-16 h-16"
          } 
          transition-all duration-200 ease-in-out`}
        >
          <div
            className={`${
              appState === AppState.RECORDING
                ? "w-3 h-3 bg-red-500"
                : "w-6 h-6 bg-red-500 rounded-full"
            } transition-all duration-200 ease-in-out`}
          ></div>

          {/* Progress Circle */}
          {recordingState === RecordingState.RECORDING && (
            <svg
              className="scale-[1.325] absolute top-0 left-0 w-full h-full"
              viewBox="0 0 100 100"
            >
              <circle
                className="text-red-500"
                strokeWidth="8"
                stroke="currentColor"
                fill="transparent"
                r="47"
                cx="50"
                cy="50"
                strokeDasharray="295.31" // Circumference = 2 * π * r
                strokeDashoffset={`${295.31 - (295.31 * progress) / 100}`}
                transform="rotate(-90 50 50)"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default RecordingButton;
