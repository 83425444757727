/* eslint-disable @typescript-eslint/no-unused-vars */
import GeolocationBase from "./geolocation.base";

export default class GeolocationWeb extends GeolocationBase {
  
  constructor() {
    super();
  }

  public override async getCurrentPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<void> {
    return new Promise<void>( (resolve, reject) => {
      navigator.geolocation.getCurrentPosition( 
        successCallback,
        errorCallback,
        options
      );
      resolve();
    });
  }

  public override async watchPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, options?: PositionOptions): Promise<number> {
    return new Promise<number>( (resolve, reject) => {
      const watchId = navigator.geolocation.watchPosition( 
        successCallback,
        errorCallback,
        options
      );
      resolve(watchId);
    });
  }

  public override async clearWatch(watchId: number): Promise<void> {
    return new Promise<void>( (resolve, reject) => {
      navigator.geolocation.clearWatch(watchId);
      resolve();
    });
  }
}