/* eslint-disable @typescript-eslint/no-unused-vars */
import _114026_sparse from "../../../assets/projects/lincolncenter/map/114026-LC_middle_BLK2GO_scan2-sparse.ply";
import _114028_sparse from "../../../assets/projects/lincolncenter/map/114028-LC_topleft_BLK2GO_scan13-sparse.ply";
import _114031_sparse from "../../../assets/projects/lincolncenter/map/114031-LC_topright_BLK2GO_scan5-sparse.ply";
// import _114033_sparse from '../../../assets/projects/lincolncenter/map/114033-LC_middle_front1_BLK2GO_scan8-sparse.ply';
// import _114034_sparse from '../../../assets/projects/lincolncenter/map/114034-LC_middle_front2_BLK2GO_scan10-sparse.ply';
// import _114036_sparse from '../../../assets/projects/lincolncenter/map/114036-LC_middle_front3_BLK2GO_scan12-sparse.ply';
//
import PlazaMeshPath from "@lincolncenter/building/LC_LP_cut_no_textures.glb";
// import PlazaWirePath from '@lincolncenter/building/LC_boxy_cut_Wire.glb';
import AnnabellePlaceholderPath from "@lincolncenter/placeholders/textures/annabelle.png";
import BalletPlaceholderPath from "@lincolncenter/placeholders/textures/ballet.png";
import GovIslandPlaceholderPath from "@lincolncenter/placeholders/textures/govisland.png";
import IdraniPlaceholderPath from "@lincolncenter/placeholders/textures/idrani.png";
import RooftopPlaceholderPath from "@lincolncenter/placeholders/textures/rooftop.png";
import TapHappeningsPlaceholderPath from "@lincolncenter/placeholders/textures/taphappenings.png";
import BreakersPlaceholderPath from "@lincolncenter/placeholders/textures/breakers.png";
import CatDancePlaceholderPath from "@lincolncenter/placeholders/textures/catdance.png";

import PlanePlaceholder from "@lincolncenter/placeholders/plane.glb";

import {
  Angle,
  Camera,
  Color3,
  Engine,
  Mesh,
  Scene,
  StandardMaterial,
  Texture,
  Vector3,
} from "babylonjs";
import {
  IAssetBase,
  IAssetModel,
  IAssetPointCloud,
  IAssetTexture,
} from "../../apps/app.content";
import ContentLincolnCenterBase from "./content.lincolncenter.base";
import { IContentLincolnCenterConfig } from "./content.lincolncenter.config";

const MapMiddle = 114026;
const MapTopLeft = 114028;
const MapTopRight = 114031;
const MapMiddleFront1 = 114033;
const MapMiddleFront2 = 114034;
const MapMiddleFront3 = 114036;

//----------------------------------------------------------------
export default class ContentLincolnCenterPlaza extends ContentLincolnCenterBase {
  protected assetsLoaded: IAssetBase[] = [];
  private placeholderMeshes: { [key: string]: Mesh } = {};
  private loadedTextures: { [key: string]: IAssetTexture } = {};

  protected config: IContentLincolnCenterConfig | undefined;

  constructor(config: IContentLincolnCenterConfig) {
    super();
    this.config = config;
  }

  public override get assets(): IAssetBase[] {
    return [
      ...( (this.config && this.config.loadPointClouds) ? this.pointCloudAssets : []),
      {
        id: "building_mesh",
        type: "model",
        path: PlazaMeshPath,
      },
      // {
      //   id: "building_wire",
      //   type: "model",
      //   path: PlazaWirePath,
      // },
      {
        id: `placeholder`,
        type: "model",
        path: PlanePlaceholder,
      },
      {
        id: `annabelle`,
        type: "texture",
        path: AnnabellePlaceholderPath,
      },
      {
        id: `ballet`,
        type: "texture",
        path: BalletPlaceholderPath,
      },
      {
        id: `govisland`,
        type: "texture",
        path: GovIslandPlaceholderPath,
      },
      {
        id: `idrani`,
        type: "texture",
        path: IdraniPlaceholderPath,
      },
      {
        id: `rooftop`,
        type: "texture",
        path: RooftopPlaceholderPath,
      },
      {
        id: `taphappenings`,
        type: "texture",
        path: TapHappeningsPlaceholderPath,
      },
      {
        id: `breakers`,
        type: "texture",
        path: BreakersPlaceholderPath,
      },
      {
        id: `catdance`,
        type: "texture",
        path: CatDancePlaceholderPath,
      },
    ];
  }

  private get pointCloudAssets(): IAssetBase[] {
    return [
      {
        id: `immersal_sparse_${MapMiddle.toString()}`,
        type: "pointcloud",
        path: _114026_sparse,
        size: 2,
        color: Color3.Magenta().asArray(),
      } as IAssetPointCloud,
      {
        id: `immersal_sparse_${MapTopLeft.toString()}`,
        type: "pointcloud",
        path: _114028_sparse,
        size: 2,
        color: Color3.Yellow().asArray(),
      } as IAssetPointCloud,
      {
        id: `immersal_sparse_${MapTopRight.toString()}`,
        type: "pointcloud",
        path: _114031_sparse,
        size: 2,
        color: Color3.Teal().asArray(),
      } as IAssetPointCloud,
      // {
      //   id: MapMiddleFront1.toString(),
      //   type: "pointcloud",
      //   path: _114033_sparse,
      //   size: 2,
      //   color: Color3.Red().asArray(),
      // } as IAssetPointCloud,
      // {
      //   id: MapMiddleFront2.toString(),
      //   type: "pointcloud",
      //   path: _114034_sparse,
      //   size: 2,
      //   color: Color3.Green().asArray(),
      // } as IAssetPointCloud,
      // {
      //   id: MapMiddleFront3.toString(),
      //   type: "pointcloud",
      //   path: _114036_sparse,
      //   size: 2,
      //   color: Color3.Blue().asArray(),
      // } as IAssetPointCloud,
    ];
  }

  public override init(engine: Engine, scene: Scene, camera: Camera): void {
    super.init(engine, scene, camera);
  }

  private setTexture(meshRoot: Mesh, texture: Texture) {
    meshRoot.getChildMeshes().forEach((child) => {
      if (child instanceof Mesh) {
        const material = new StandardMaterial("placeholder", this.scene);

        material.diffuseTexture = texture;
        material.emissiveTexture = texture;
        material.diffuseTexture.hasAlpha = true;
        material.useAlphaFromDiffuseTexture = true;
        material.alpha = 0;
        child.material = material;
      }
    });
  }

  private fadeInOut(
    meshRoot: Mesh,
    startAlpha: number,
    targetAlpha: number,
    duration: number
  ): void {
    const step = (targetAlpha - startAlpha) / (duration * 60); // Compute the step size for smooth transitions

    meshRoot.getChildMeshes().forEach((child) => {
      if (child instanceof Mesh && child.material instanceof StandardMaterial) {
        const material = child.material as StandardMaterial;

        // Set the initial alpha
        material.alpha = startAlpha;

        const fadeInterval = setInterval(() => {
          material.alpha = material.alpha + step;

          // Check if the alpha has reached or exceeded the target
          if (
            (step > 0 && material.alpha >= targetAlpha) ||
            (step < 0 && material.alpha <= targetAlpha)
          ) {
            material.alpha = targetAlpha; // Ensure exact target value
            clearInterval(fadeInterval); // Stop the interval
          }
        }, 1000 / 60); // Interval to match 60 FPS
      }
    });
  }

  public loadAssetComplete(assetLoaded: IAssetBase): void {
    const assetIndex = this.assets.findIndex(
      (asset) => asset.id === assetLoaded.id
    );
    if (assetIndex >= 0) {
      this.assetsLoaded.push(assetLoaded);
    }

    if (assetLoaded.id === "building_mesh") {
      const assetModel = assetLoaded as IAssetModel;
      if (assetModel.meshes) {
        assetModel.meshes.forEach((mesh) => {
          mesh.isPickable = false;
        });
      }
    }

    if (assetLoaded.id === "building_wire") {
      const assetModel = assetLoaded as IAssetModel;
      if (assetModel.meshes) {
        assetModel.meshes.forEach((mesh) => {
          mesh.isPickable = false;
          const material = mesh.material as StandardMaterial;
          if (material) {
            material.needDepthPrePass = true;
          }
        });
      }
    }
    if (assetLoaded.type === "pointcloud") {
      this.pointCloudAlignment(assetLoaded);
    }

    if (assetLoaded.id === "placeholder") {
      if (this.config) { 
        const baseModel = assetLoaded as IAssetModel;
        baseModel.meshRoot!.isVisible = false;

        // loop through children of base model and visible false them too
        baseModel.meshRoot!.getChildMeshes().forEach((child) => {
          if (child instanceof Mesh) {
            child.isVisible = false;
          }
        });

        // clone base model
        this.config.items.forEach((item) => {
          const model = baseModel.meshRoot!.clone(item.name, null);

          if (model && model instanceof Mesh && item.placeholderTransform) {
            model.isVisible = true;
            model.name = item.name + "_placeholder";
            model.getChildMeshes().forEach((child) => {
              if (child instanceof Mesh) {
                child.isVisible = true;
              }
              child.name = child.name + "_placeholder_" + item.name;
            });

            model.name = item.name;
            model.position.copyFrom(item.placeholderTransform.position);
            model.rotation.copyFrom(item.placeholderTransform.rotation);
            model.scaling.copyFrom(item.placeholderTransform.scale);
            this.placeholderMeshes[item.name] = model;

            if (
              this.loadedTextures[item.name] &&
              this.loadedTextures[item.name].texture
            ) {
              this.setTexture(model, this.loadedTextures[item.name].texture!);
            }
            this.fadeInOut(model, 0, 1, 1);
          }
        });
      } else {
        console.error(
          "ContentLincolnCenterPlaza: loadAssetComplete: config is not set"
        );
      }
    }

    if (assetLoaded.type === "texture") {
      const assetTexture = assetLoaded as IAssetTexture;
      if (assetTexture.texture) {
        if (assetLoaded.id) {
          this.loadedTextures[assetLoaded.id] = assetTexture;
          const mesh = this.placeholderMeshes[assetLoaded.id];
          if (mesh) {
            this.setTexture(mesh, assetTexture.texture);
            this.fadeInOut(mesh, 0, 1, 1);
          }
        }
      }
    }
  }

  private pointCloudAlignment(assetLoaded: IAssetBase) {
    const transformPointCloud = (
      asset: IAssetPointCloud,
      pos: Vector3,
      rot: Vector3
    ) => {
      if (asset.mesh) {
        asset.mesh.position.copyFrom(pos);
        asset.mesh.rotation.copyFrom(rot);
      }
    };

    this.config?.maps.forEach((map) => {  
      if (assetLoaded.id?.endsWith(map.mapId.toString())) {
        if (map && map.position && map.rotation)
          transformPointCloud(
            assetLoaded as IAssetPointCloud,
            new Vector3(map.position![0], map.position![1], map.position![2]),
            new Vector3(map.rotation![0], map.rotation![1], map.rotation![2])
          );
      }
    });

    return;



    if (assetLoaded.id?.endsWith(MapMiddle.toString())) {
      transformPointCloud(
        assetLoaded,
        new Vector3(-33.486, 1.62, 76.9),
        new Vector3(0, Angle.FromDegrees(81.9).radians(), 0)
      );
    } else if (assetLoaded.id?.endsWith(MapTopLeft.toString())) {
      transformPointCloud(
        assetLoaded,
        new Vector3(-28, 1.0, 8.5),
        new Vector3(0, Angle.FromDegrees(272.3).radians(), 0)
      );
    } else if (assetLoaded.id?.endsWith(MapTopRight.toString())) {
      transformPointCloud(
        assetLoaded,
        new Vector3(-7.1, 2, -56.6),
        new Vector3(0, Angle.FromDegrees(113).radians(), 0)
      );
    } else if (assetLoaded.id?.endsWith(MapMiddleFront1.toString())) {
      transformPointCloud(
        assetLoaded,
        new Vector3(-72.2, 1.6, 80),
        new Vector3(0, Angle.FromDegrees(102).radians(), 0)
      );
    } else if (assetLoaded.id?.endsWith(MapMiddleFront2.toString())) {
      transformPointCloud(
        assetLoaded,
        new Vector3(-79, 2, -1),
        new Vector3(0, Angle.FromDegrees(209.9).radians(), 0)
      );
    } else if (assetLoaded.id?.endsWith(MapMiddleFront3.toString())) {
      transformPointCloud(
        assetLoaded,
        new Vector3(-32.5, 1.6, 9.1),
        new Vector3(0, Angle.FromDegrees(103).radians(), 0)
      );
    }
  }

  public stopAllAnimations(assetId: string) {
    if (this.placeholderMeshes[assetId]) {
      const mesh = this.placeholderMeshes[assetId];
      if (mesh) {
        this.scene!.stopAnimation(mesh);
      }
    }
  }

  public hideAllPlaceholders() {
    for (const key in this.placeholderMeshes) {
      if (this.placeholderMeshes[key]) {
        this.placeholderMeshes[key].isVisible = false;
      }
    }
  }

  public showAllPlaceholders() {
    for (const key in this.placeholderMeshes) {
      if (this.placeholderMeshes[key]) {
        this.placeholderMeshes[key].isVisible = true;
      }
    }
  }

  public assetVisible(assetId: string, visible: boolean): void {
    if (this.placeholderMeshes[assetId]) {
      const mesh = this.placeholderMeshes[assetId];
      if (mesh) {
        this.stopAllAnimations(assetId);
        if (visible) this.fadeInOut(mesh, 0, 1, 0.2);
        else this.fadeInOut(mesh, 1, 0, 1);
      }
    } else {
      const asset = this.assetsLoaded.find((asset) => asset.id === assetId);
      if (asset) {
        if (asset.type === "model") {
          const assetModel = asset as IAssetModel;
          if (assetModel.meshes) {
            assetModel.meshes.forEach((mesh) => {
              mesh.isVisible = visible;
            });
          }
        } else if (asset.type === "pointcloud") {
          const assetModel = asset as IAssetPointCloud;
          if (assetModel.mesh) {
            assetModel.mesh.isVisible = visible;
          }
        }
      }
    }
  }
}
