import WayfindingPath from "@lincolncenter/wayfinding/wayfinding_path_wide.png";
import {
  Curve3,
  Effect,
  Mesh,
  ShaderMaterial,
  Texture,
  Vector3,
  VertexData,
} from "babylonjs";
import ContentLincolnCenterBase from "./content.lincolncenter.base";
import { Wayfinding } from "./content.lincolncenter.types";

import extrude from "extrude-polyline";
import { IWayfindingPath } from "./content.lincolncenter.config";

export default class ContentWayfindingPath extends ContentLincolnCenterBase {
  private material?: ShaderMaterial;
  private time = 0;

  constructor() {
    super();
    this.id = Wayfinding;
  }

  private generateEvenlyDistributedPointsByDistance(
    path: Vector3[],
    spacing: number
  ): Vector3[] {
    // Calculate total path length
    const segmentLengths: number[] = [];
    for (let i = 1; i < path.length; i++) {
      segmentLengths.push(Vector3.Distance(path[i - 1], path[i]));
    }

    const evenlyDistributedPoints: Vector3[] = [];
    let currentSegmentIndex = 0;
    let currentDistanceInSegment = 0;

    // Add the starting point
    evenlyDistributedPoints.push(path[0]);

    while (currentSegmentIndex < path.length - 1) {
      // Move along the segments, adding points based on the spacing
      const segmentLength = segmentLengths[currentSegmentIndex];

      // Check if we can fit another point in the current segment
      if (currentDistanceInSegment + spacing <= segmentLength) {
        currentDistanceInSegment += spacing;
        const start = path[currentSegmentIndex];
        const end = path[currentSegmentIndex + 1];

        // Interpolate between the points of the current segment
        const t = currentDistanceInSegment / segmentLength;
        const newPoint = Vector3.Lerp(start, end, t);
        evenlyDistributedPoints.push(newPoint);
      } else {
        // Move to the next segment
        currentDistanceInSegment -= segmentLength;
        currentSegmentIndex++;
      }
    }

    // Add the ending point if not already added
    if (!evenlyDistributedPoints.includes(path[path.length - 1])) {
      evenlyDistributedPoints.push(path[path.length - 1]);
    }

    return evenlyDistributedPoints;
  }

  public setPath (wayfindingPath: IWayfindingPath) {
    if (wayfindingPath.waypoints)
      this.setPoints(wayfindingPath.waypoints.map((waypoint) => waypoint.position));
    else 
      console.warn("No waypoints found");
  }

  private setPoints(_path: Vector3[]): void {
    const curve = Curve3.CreateCatmullRomSpline(_path, 20, false);
    let path = curve.getPoints();
    path = this.generateEvenlyDistributedPointsByDistance(path, 1);

    // Override in subclass
    const polyline: any[] = [];

    for (const point of path) {
      polyline.push([point.x, point.z]);
    }

    const stroke: any = (extrude as any)({
      thickness: 1.5,
      cap: "butt",
      join: "miter",
      miterLimit: 10000,
    }) as any;

    //builds a triangulated mesh from a polyline
    let mesh = stroke.build(polyline);
    console.log(mesh);
    this.makeMesh2(mesh);
  }

  public makeMesh2(meshData: any) {
    const positions = [];
    const uvs = [];
    const indices = [];

    // trim to first two cells
    meshData.cells.forEach(([a, b, c], index) => {
      // Retrieve positions for vertices a, b, c
      const _posA = meshData.positions[a];
      const _posB = meshData.positions[b];
      const _posC = meshData.positions[c];

      const postA = [_posA[0], 0, _posA[1]];
      const postB = [_posB[0], 0, _posB[1]];
      const postC = [_posC[0], 0, _posC[1]];

      // Append positions to the positions array
      positions.push(...postA, ...postB, ...postC);

      // Calculate the starting index for this quad
      const startIndex = index * 3;

      // Define indices for the current triangle
      indices.push(startIndex, startIndex + 1, startIndex + 2);

      // Assign UVs for the triangle vertices
      // Assuming a standard mapping; adjust as needed
      if (index % 2 === 0) {
        uvs.push(0, 0);
        uvs.push(1, 0);
        uvs.push(0, 1);
      } else {
        uvs.push(0, 1);
        uvs.push(1, 0);
        uvs.push(1, 1);
      }
    });

    // Create a new VertexData object
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.uvs = uvs;

    // Compute normals for proper lighting
    const normals = [];
    for (let i = 0; i < positions.length / 3; i++) {
      normals.push(0, 1, 0);
    }

    vertexData.normals = normals;

    // Apply the vertex data to a mesh
    const customMesh = new Mesh("custom", this.scene!);
    customMesh.isPickable = false;
    
    vertexData.applyToMesh(customMesh);
    this.setMaterial(customMesh);
    
    customMesh.parent = this.rootNode!;
    customMesh.renderingGroupId = 2;
  }

  public setMaterial(mesh: Mesh) {
    Effect.ShadersStore["simpleVertexShader"] = `
    precision highp float;

    attribute vec3 position;
    attribute vec2 uv;
    attribute vec4 color;
    varying vec4 vColor;

    uniform mat4 worldViewProjection;
    uniform mat4 world;

    // Varyings
    varying vec2 vUV;

    varying vec3 vWorldPosition;

    void main() {
        vUV = uv; 
        vColor = color; 
        vWorldPosition =  (world * vec4(position, 1.0)).xyz;
        gl_Position = worldViewProjection * vec4(position, 1.0);
    }
`;

    BABYLON.Effect.ShadersStore["wayfindingFragmentShader"] = `
    precision highp float;
    varying vec3 vWorldPosition;

    varying vec2 vUV;
    varying vec4 vColor; 

    // Uniforms
    uniform float time;
    uniform sampler2D textureSampler;
    uniform vec3 cameraPosition;

    void main() {
        vec2 uv = vUV + vec2(0.0, time); 
        vec4 color = texture2D(textureSampler, vec2(uv.x, fract(uv.y)));  //USE THIS

          // vec4 color = texture2D(textureSampler, vec2(uv.x,fract(uv.y * 1359.0 * 0.25)));

          // vec4 color = texture2D(textureSampler, uv);
        if (color.a < 0.5 ) {
            discard;
        }

        // // Blend out the arrow as it enters near a portal (this is done in Blender on vertex colors)
        // color.a *= 1.0 - vColor.r;

        // // Blend out the arrow as it gets further away
        float dist = distance(vec3(cameraPosition.x, 0, cameraPosition.z), vec3(vWorldPosition.x, 0, vWorldPosition.z));
        
        // // Blend out only as it gets further than 10 units away
        dist = max(0.0, dist - 7.0);

        color.a *= 1.0 - dist / 7.0;

        // gl_FragColor = color;
        // gl_FragColor = vec4(vUV.x,0,  0.0, 1.0);
        // gl_FragColor = vec4(fract(vUV.y), 0.0, 0.0, 1.0);//  * 0.5* 1359.0
        // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
        gl_FragColor = color;
    }
`;
    const texture = new Texture(WayfindingPath, this.scene);

    // Do not repeat the texture
    texture.wrapU = Texture.CLAMP_ADDRESSMODE;
    texture.wrapV = Texture.CLAMP_ADDRESSMODE;

    // Replace the material with a ShaderMaterial
    const shaderMaterial = new ShaderMaterial("wayfinding", this.scene!, {
      vertex: "simple",
      fragment: "wayfinding",
    });
    // shaderMaterial.wireframe = true;
    shaderMaterial.backFaceCulling = false;

    shaderMaterial.needAlphaBlending = () => true;
    shaderMaterial.setTexture("textureSampler", texture);
    shaderMaterial.setMatrix("world", mesh.getWorldMatrix());
    mesh.material = shaderMaterial;
    this.material = shaderMaterial;
    shaderMaterial.zOffset = 100;
    this.material = shaderMaterial;
  }

  public hide() {
    this.rootNode?.setEnabled(false);
  }

  public show() {
    this.rootNode?.setEnabled(true);
  }

  public render(): void {
    if (this.scene) {
      this.time -= 0.01;
      this.material?.setFloat("time", this.time);
      this.material?.setVector3(
        "cameraPosition",
        this.scene.activeCamera?.position ?? Vector3.Zero()
      );
    }
  }
}
